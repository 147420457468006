<template>
  <v-container>
    <v-expansion-panels flat accordion multiple v-model="moisturePanels">
      <v-expansion-panel>
        <v-expansion-panel-header
          style="min-height: unset;"            
          class="subtitle-1 font-weight-bold py-1 px-2"
          expand-icon="mdi-menu-down"
          :color="lightGrey"
          >{{ $vuetify.lang.t(`$vuetify.bulletin.moisture.overview`) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2">
          <v-simple-table dense class="">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $vuetify.lang.t(`$vuetify.bulletin.publisher.title`) }}</td>
                <td>
                  <a target="_blank" :href="dangerArea.properties.site">{{ '© ' + this.dangerArea.properties.name }}</a>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $vuetify.lang.t(`$vuetify.bulletin.table.edition`) }}</td>
                <td> {{ formatDateTime(new Date(dangerArea.properties.start), false) }} </td>
              </tr>
              <tr>
                <td class="text-left">{{ $vuetify.lang.t(`$vuetify.bulletin.table.validTill`) }}</td>
                <td> {{ formatDateTime(new Date(dangerArea.properties.stop), false) }} </td>
              </tr>
            </tbody>
          </v-simple-table>
          <danger-and-core-zone v-if="overviewCoreZone != null"
            :coreZone="overviewCoreZone"
            @displayHelp="displayHelp"  
            class="pt-3"  
          >
          </danger-and-core-zone>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="dryCoreZones != null">
        <v-expansion-panel-header
          style="min-height: unset;"            
          class="subtitle-1 font-weight-bold py-1 px-2"
          expand-icon="mdi-menu-down"
          :color="lightGrey"
          >{{ $vuetify.lang.t(`$vuetify.bulletin.moisture.dry`) }}</v-expansion-panel-header
        >
        <v-expansion-panel-content class="pt-2">
            <moisture-panel
              :dangerArea="dangerArea"
              :isDryOrWet="isDry"
              @displayHelp="displayHelp"
              class="pa-0"
            ></moisture-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="wetCoreZones != null">
        <v-expansion-panel-header
          style="min-height: unset;"            
          class="subtitle-1 font-weight-bold py-1 px-2"
          expand-icon="mdi-menu-down"
          :color="lightGrey"
          >{{ $vuetify.lang.t(`$vuetify.bulletin.moisture.wet`) }}</v-expansion-panel-header
        >
        <v-expansion-panel-content class="pt-2">
            <moisture-panel
              :dangerArea="dangerArea"
              :isDryOrWet="isWet"
              @displayHelp="displayHelp"
              class="pa-0"
            ></moisture-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import MoisturePanel from './MoisturePanel.vue'
import DangerAndCoreZone from './DangerAndCoreZone.vue'
import { isObjectValid } from '../utilities'

export default {
  name: 'danger-area-panel',
  mixins: [utilityMixin],
  components: {
    MoisturePanel,
    DangerAndCoreZone
  },
  props: {
    dangerArea: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    lightGrey: 'rgb(227, 227, 227)',
    moisturePanels: [0, 1, 2],
    dryCoreZones: null,
    wetCoreZones: null,
    isDry: true,
    isWet: false,
    overviewCoreZone: null
  }),
  watch: {
    dangerArea: function () {
      this.updateData()
    }
  },  
  methods: {
    updateData() {
      this.dryCoreZones = this.selectDryCoreZones(this.dangerArea)
      this.wetCoreZones = this.selectWetCoreZones(this.dangerArea)

      // Make sure all panels are open
      var numberPanels = 1
      if (this.dryCoreZones != null) numberPanels+=1
      if (this.wetCoreZones != null) numberPanels+=1      
      this.moisturePanels = Array.from(Array(numberPanels).keys())

      if (this.dangerArea.properties.name != 'SLF') {

        // You must clone the object
        var selectedCoreZone = this.dangerArea.properties.corezns.find((coreZone) => coreZone.avprob == 'GENERIC_AVALANCHE_PROBLEM') 
        this.overviewCoreZone = {...selectedCoreZone}
        if (isObjectValid(this.overviewCoreZone.inside)) {
          this.overviewCoreZone.inside.drdl = 'UNKNOWN'
        }

        if (this.dangerArea.properties.name != 'Météo France') {
          this.overviewCoreZone.aspects = null
        }
      }

    },
    displayHelp(tag) {
      this.$emit('displayHelp', tag)
    },  
  },
  created() {
    this.updateData()
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-expansion-panels {
    border: 1.2px solid lightgray;
  }

  .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding-left: 8px;
    padding-right: 8px;
  }

  /* Lines between the expansion apanels */
  .v-expansion-panel::after {
    border-top: solid;
    border-top-width: 1.2px;
    border-top-color: rgba(0, 0, 0, 0.12);
    opacity: 1;
  }

</style>
