<template>
  <v-container>
    <v-row>
      <v-col cols="3" class="pa-0" :style="{opacity: opacities[0]}">
        <v-row class="mt-3 mx-1">
          <a :href="$vuetify.lang.t(`$vuetify.bulletin.problemLink`)" target="_blank" class="mx-2">
            <v-img v-if="coreZone.avprob == 'NEW_SNOW'" width="55px" src="../assets/AP_NewSnow.svg"></v-img>
            <v-img v-if="coreZone.avprob == 'WIND_SLAB'" width="55px" src="../assets/AP_WindSlab.svg"></v-img>
            <v-img v-if="coreZone.avprob == 'PERSISTENT_WEAK_LAYERS'" width="55px" src="../assets/AP_PersistentWeakLayer.svg"></v-img>
            <v-img v-if="coreZone.avprob == 'WET_SNOW'" width="55px" src="../assets/AP_WetSnow.svg"></v-img>
            <v-img v-if="coreZone.avprob == 'GLIDING_SNOW'" width="55px" src="../assets/AP_GlidingSnow.svg"></v-img>
            <v-img v-if="coreZone.avprob == 'NO_DISTICT_AVALANCHE_PROBLEM'" width="55px" src="../assets/AP_NoDistinctAvalancheProblem.svg"></v-img>
            <v-img v-if="coreZone.avprob == 'FAVOURABLE_SITUATION'" width="55px" src="../assets/AP_FavorableSituation.svg"></v-img>
          </a>
        </v-row>
        <v-row  class="mx-0 mt-3">
          <a :href="$vuetify.lang.t(`$vuetify.bulletin.problemLink`)" class="text-caption font-weight-bold text textDense text-no-wrap">
          {{ $vuetify.lang.t(`$vuetify.bulletin.problems.${coreZone.avprob}`) }}
          </a>
        </v-row> 
      </v-col>
      <v-col cols="3" class="pa-0" :style="{opacity: opacities[1]}">
        <v-row v-if="coreZone.loele >= 0 && coreZone.hiele >= 0" class="mt-0 ml-0">
          <a @click="displayHelp('coreZone')" target="_blank" class="mx-0">
            <critical-elevations
              :loele="coreZone.loele"
              :hiele="coreZone.hiele"
              :minloele="0"
              width="80px"
              height="80px"
            >
            </critical-elevations>
          </a> 
        </v-row>
      </v-col>
      <v-col cols="3" class="pa-0" :style="{opacity: opacities[2]}">
        <v-row v-if="critAspects != null" class="mt-0">
          <a @click="displayHelp('coreZone')" target="_blank" class="mx-3">
            <critical-aspects
              :aspects="critAspects"
              :isDynamic="false"
              width="80px"
              height="80px"
            >
            </critical-aspects>
          </a>  
        </v-row>
      </v-col>       
      <v-col cols="3" class="pa-0" :style="{opacity: opacities[3]}">
        <v-row class="mt-4 mx-0">
          <a @click="displayHelp('dangerLevel')" target="_blank" class="mx-2">
            <v-img v-if="danger == 1" width="55px" src="../assets/Danger1d.svg"></v-img>
            <v-img v-if="danger == 2" width="55px" src="../assets/Danger2d.svg"></v-img>
            <v-img v-if="danger == 3" width="55px" src="../assets/Danger3d.svg"></v-img>
            <v-img v-if="danger == 4" width="55px" src="../assets/Danger4d.svg"></v-img>
          </a>
        </v-row>
        <v-row v-if="danger > 0" class="mx-0 mt-3">
          <a @click="displayHelp('dangerLevel')" class="text-caption font-weight-bold text-no-wrap overflow-hidden">
          {{ getDangerLevelText(danger, detail) }}
          </a>
        </v-row> 
      </v-col>         
    </v-row>
  </v-container>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import CriticalAspects from '../components/CriticalAspects.vue'
import CriticalElevations from '../components/CriticalElevations.vue'
import { isObjectValid } from '../utilities'

export default {
  name: 'avalanche-problem',
  mixins: [utilityMixin],
  components: {
    CriticalAspects,
    CriticalElevations
  },  
  props: {
    coreZone: {
      type: Object,
      required: true,
    },
    opacities: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    critAspects: Array(16).fill(false),
    danger: 0,
    detail: 'UNKNOWN'
  }),
  watch: {
    coreZone: function () {
      this.updateData()
    }
  },  
  methods: {
    updateData() {
      if (isObjectValid(this.coreZone.aspects)) {
        // You must reset the array before you set is: Otherwise watch of CriticalAspect.vue is not triggered
        this.critAspects = Array(16).fill(false)
        for(var i=0; i < this.coreZone.aspects.length; i++ ) {
          this.critAspects[i] = (this.coreZone.aspects[i] == '1')
        }
      } else {
        this.critAspects = null
      }

      this.danger = (isObjectValid(this.coreZone.inside) && isObjectValid(this.coreZone.inside.rdl))? this.coreZone.inside.rdl: 0
      this.detail = (isObjectValid(this.coreZone.inside) && isObjectValid(this.coreZone.inside.drdl))? this.coreZone.inside.drdl: 0
    },
    displayHelp(tag) {
      this.$emit('displayHelp', tag)
    },         
  },
  created() {
    this.updateData()
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
