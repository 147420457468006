import DataService from '@/services/DataService.js'

export const namespaced = true

export const state = {
  regions: {},
}

export const mutations = {
  SET_REGIONS(state, data) {
    state.regions = data
  },

  SET_REGION_VALIDITY(state, validity) {
    var regionFeature = state.regions.features.find((feature) => feature.properties.coll === validity.collection)
    regionFeature.properties.edition = validity.edition
    regionFeature.properties.validity = validity.validity

    var nowTime = Date.now()

    // The following line can be used to fake status ON/TEST for some regions
    // nowTime -= 5 * 365 * 24 * 3600 * 1000
    var expirationTime = Date.parse(validity.validity)
    var demoTime = expirationTime + 3600 * 1000

    var status = regionFeature.properties.config
    if (regionFeature.properties.config == 1 || regionFeature.properties.config == 2) {
      if (nowTime > demoTime) {
        // The bulletin is expired since more then one hour: Switch to DEMO
        status = 4 // DEMO
      } else if (nowTime > expirationTime) {
        // The bulletin is expired, but less then one hour: Switch to WAIT state
        status = 3 // WAIT
      }
    }

    regionFeature.properties.status = status
    regionFeature.properties.expirationTime = expirationTime
    regionFeature.properties.valid = nowTime < expirationTime
  },
}

export const actions = {
  fetchRegions({ commit }) {
    if (Object.keys(state.regions).length) {
      return Promise.resolve()
    }
    return DataService.getRegions()
      .then((response) => {
        commit('SET_REGIONS', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },
}

export const getters = {
  getRegions: (state) => {
    return state.regions
  },
}
