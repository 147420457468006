export const collections = {
  ch: 'Switzerland',
  au: 'Austria',
  fr: 'France',
  iw: 'ItalyWest',
  ie: 'ItalyEast',
}

export const defaultHomes = {
  ch: 'Zürich',
  au: 'Innsbruck',
  fr: 'Grenoble',
  iw: 'Torino',
  ie: 'Verona',
}

export const defaultExtents_4326 = {
  ch: [5.7, 45.7, 10.7, 47.9],
  au: [9.1, 46,   16.6, 48.5],
  fr: [5.1, 43.6,  8,   46.4],
  iw: [6.4, 43.8,  9.4, 46.7],
  ie: [8.5, 45.3, 13.8, 47.2],
  ap: [5.1, 43.6, 16.6, 48.5]
}
export const regionExtent = [5.1, 43.6, 16.6, 48.5]

export const defaultExtents_3857 = {
  ch: [ 634521, 5732403, 1191118, 6090234],
  au: [1013007, 5780349, 1847903, 6190443],
  fr: [ 567729, 5403748,  890555, 5844682],
  iw: [ 712444, 5434543, 1046403, 5893243],
  ie: [ 946215, 5668874, 1536208, 5974780],
}
