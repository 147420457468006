<template>
  <v-card tile v-resize="onResize" class="ma-0 overflow-y-auto" :style="{ height: contentHeight + 'px' }">
    <rating-input 
      :userRoute="userRoute"
      :timestamp="timestamp"
      @routeRated="onRouteRated"
      @displayMessage="displayMessage"
      @displayHelp="displayHelp"
      @fileLoaded="fileLoaded"
    >
    </rating-input>
    <rating-output 
      :userRoute="userRoute"
      :routeInfo="routeInfo"
      :gpx="gpx" 
      @displayHelp="displayHelp"  
      @displayMessage="displayMessage"  
      >
    </rating-output>
    <partners></partners>
  </v-card>
</template>

<script>
// import TrackTable from './TrackTable.vue'
// import AvalancheBulletin from './AvalancheBulletin.vue'
import { resizeMixin } from '../mixins/resizeMixin'
import Partners from './Partners.vue'
import RatingInput from './RatingInput.vue'
import RatingOutput from './RatingOutput.vue'
import { log } from '../utilities'

export default {
  name: 'rating-info',
  mixins: [resizeMixin],
  watch: {
    userRoute: function () {
      this.userRouteChanged()
    }
  },
  components: {
    // TrackTable,
    // AvalancheBulletin,
    Partners,
    RatingInput,
    RatingOutput
  },
  props: {
    userRoute: {
      type: Object,
      default: null
    },
    timestamp: {
      type: Number,
      default: 0,
    },    
    gpx: {
      type: String,
      default: null
    }    
  },  
  data: () => ({
    routeInfo: null,
    isNew: true,
  }),   
  methods: {
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      log('TrackInfo: Display help')
      this.$emit('displayHelp', tag)
    },
    displayMessage(type, message) {
      this.$emit('displayMessage', type, message)
    },  
    fileLoaded(file, cutGpx) {
      this.$emit('fileLoaded', file, cutGpx)
    },   
    onRouteRated(response, isNew) {
      log("onRouteRated()")
      this.isNew = isNew
      this.$emit('routeRated', response, isNew)
      if (response == null) {
        this.routeInfo = null
        return
      }
      this.routeInfo = response.correctedRouteInfo   
    },
    userRouteChanged() {
      if (this.isNew) this.routeInfo = null
      this.isNew = true
    }
  },

  created() {

  },  
}
</script>

<style scoped></style>
