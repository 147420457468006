<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1980 330" :width="width" :height="height">
    <title>Skitourenguru Logo</title>
    <rect :fill="backgroundColor" height="100%" width="100%" />
    <path :fill="foregroundColor" d="m 125.34465,163.55667 0,0 0,0 z" />
    <path
      :fill="foregroundColor"
      d="m 234.76565,214.34767 c 62.53,-19.5 27.365,-97.695 27.365,-97.695 0,0 0,50.804 -41.21,65.468 -60.151,21.424 -94.198,-16.9 -95.55,-18.512 1.118,2.132 36.348,69.979 109.395,50.739 z"
    />
    <path
      :fill="foregroundColor"
      d="m 211.30065,237.82567 c -97.708,0 -156.312001,-148.512009 -156.312001,-148.512009 0,0 27.3,187.564009 156.312001,187.564009 89.869,-3.9 66.43,-74.243 66.43,-74.243 0,0 -14.313,35.191 -66.43,35.191 z"
    />
    <path
      :fill="foregroundColor"
      d="m 181.30965,167.54767 a 45.071,45.071 0 0 0 39.195,-67.32701 c -2.249,-6.421986 -8.164,-15.859986 -23.491,-26.077993 -26.325,-17.511 -44.811,-6.227 -44.811,-6.227 a 40.56,40.56 0 0 1 14.586,11.882007 45.071,45.071 0 0 0 14.495,87.749996 z"
    />
    <text
      :fill="foregroundColor"
      style="
        font-style: normal;
        font-weight: 700;
        font-size: 160px;
        font-family: Roboto;
        letter-spacing: 22px;
        text-anchor: middle;
      "
      x="1110"
      y="230"
    >
      SKITOURENGURU
    </text>
  </svg>
</template>

<script>
import { getPrimaryCssColor } from '../colors'

export default {
  name: 'LogoLabeled',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    foregroundColor: {
      type: String,
      default: '#FFFFFF',
    },
    backgroundColor: {
      type: String,
      default: getPrimaryCssColor(),
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
