<template>
  <v-container fluid class="py-0">
    <div class="text-h6 ml-1 mr-1 mt-3 mb-0 justify-left">{{ this.$vuetify.lang.t(`$vuetify.bulletin.title`) }}</div>
    <div class="ml-1 mr-1 mb-2 textDense text--secondary">
      {{ $vuetify.lang.t(`$vuetify.bulletin.originalWarning`) }}
    </div>
    <div v-if="isDangerLevelRising(dangerArea1, dangerArea2)" class="ml-1 mr-1 mb-2 textDense " :style="{ color: getWarningColor() }">
      {{ $vuetify.lang.t(`$vuetify.bulletin.dayCourseWarning`) }}
    </div>
    <div v-if="dangerArea1.properties.rdlin == 3" class="ml-1 mr-1 mb-2 textDense">
      {{ $vuetify.lang.t(`$vuetify.bulletin.dangerLevelWarning3`)}}
    </div>  
    <div v-if="dangerArea1.properties.rdlin == 4" class="ml-1 mr-1 mb-2 textDense " :style="{ color: getWarningColor() }">
      {{ $vuetify.lang.t(`$vuetify.bulletin.dangerLevelWarning4`) }}
    </div>      
    <v-row class="px-4 pb-4 pt-3">
      <v-expansion-panels flat accordion multiple v-model="panels">
        <v-expansion-panel>
          <v-expansion-panel-header
            style="min-height: unset;"            
            class="subtitle-1 font-weight-bold py-1 px-2"
            expand-icon="mdi-menu-down"
            :color="dangerColor1"
            >{{ getPanelTitle(dangerArea1.properties)}}</v-expansion-panel-header
          >
          <v-expansion-panel-content eager class="pt-2">
            <danger-area-panel
              :dangerArea="dangerArea1"
              @displayHelp="displayHelp"
              class="pa-0"
            ></danger-area-panel>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="dangerArea2 != null">
          <v-expansion-panel-header
            style="min-height: unset;"            
            class="subtitle-1 font-weight-bold py-1 px-2"
            expand-icon="mdi-menu-down"
            :color="dangerColor2"
            >{{ getPanelTitle(dangerArea2.properties)}}</v-expansion-panel-header
          >
          <v-expansion-panel-content eager class="pt-2">
            <danger-area-panel
              :dangerArea="dangerArea2"
              @displayHelp="displayHelp"
              class="pa-0"
            ></danger-area-panel>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            style="min-height: unset;"            
            class="subtitle-1 font-weight-bold py-1 px-2"
            expand-icon="mdi-menu-down"
            :color="darkGrey"
            >{{ $vuetify.lang.t(`$vuetify.bulletin.panelHeaders.texts`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content eager class="pt-2 px-0">
            <div v-for="text in texts" :key="text.title" class="text mx-0">
              <div v-if ="!isAvalancheProblemText(text)">
                <p id="avalancheTextTitle" class="font-weight-normal mb-0">{{ text.title + '\n' }}</p>
                <p id="avalancheTextContent" class="text--secondary textDense">{{ text.content }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>   

        <v-expansion-panel v-if="area=='ch'">
          <v-expansion-panel-header
            style="min-height: unset;"            
            class="subtitle-1 font-weight-bold py-1 px-2"
            expand-icon="mdi-menu-down"
            :color="darkGrey"
            >{{ $vuetify.lang.t(`$vuetify.bulletin.panelHeaders.feedback`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content eager class="pt-2 px-0">
            <div class="font-weight-normal">{{ $vuetify.lang.t(`$vuetify.bulletin.feedback.text`) }}</div>
            <div class="font-weight-medium">
              <a target="_blank" :href="$vuetify.lang.t(`$vuetify.bulletin.feedback.link2`)">{{ $vuetify.lang.t(`$vuetify.bulletin.feedback.link_text`) }}</a>
            </div>
          </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              style="min-height: unset;"            
              class="subtitle-1 font-weight-bold py-1 px-2"
              expand-icon="mdi-menu-down"
              :color="darkGrey"
              >{{ $vuetify.lang.t(`$vuetify.bulletin.panelHeaders.algorithm`) }}</v-expansion-panel-header
            >
            <v-expansion-panel-content eager class="pt-2 px-0">
              <div class="font-weight-normal">{{ $vuetify.lang.t(`$vuetify.bulletin.skitourenguruWarning`) }}</div>
              <div class="font-weight-normal">{{ $vuetify.lang.t(`$vuetify.bulletin.coreZoneInfo`) }}</div>
              <danger-and-core-zone
                :coreZone="genericCoreZone"
                :minloele="1400"
                @displayHelp="displayHelp"  
                class="pt-3"  
              >
              </danger-and-core-zone>              
            </v-expansion-panel-content>
          </v-expansion-panel>                        
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
//import { isObjectValid } from '../utilities'
import { getDangerCssColor } from '../colors.js'
import DangerAreaPanel from './DangerAreaPanel.vue'
import DangerAndCoreZone from './DangerAndCoreZone.vue'

export default {
  name: 'avalanche-bulletin',
  mixins: [utilityMixin],
  components: {
    DangerAreaPanel,
    DangerAndCoreZone
  },
  props: {
    dangerArea1: {
      type: Object,
      required: true,
    },
    dangerArea2: {
      type: Object,
      required: false,
    },    
    area: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dangerColor1: 'white',
    dangerColor2: 'white',
    darkGrey: 'rgb(200, 200, 200)',
    texts: null,
    panels: [/*0*/],
    genericCoreZone: null,
  }),
  watch: {
    dangerArea1: function () {
      this.updateData()
    },
    dangerArea2: function () {
      this.updateData()
    },    
  },  
  methods: {    
    updateData() {
      this.texts = this.getTexts(this.dangerArea1)
      this.dangerColor1 = getDangerCssColor(this.dangerArea1.properties.rdlin)
      if (this.dangerArea2 != null) {
        this.dangerColor2 = getDangerCssColor(this.dangerArea2.properties.rdlin)
      }    

      // Display the sources, the backend calculates with: There is a problme with updating, You could try by setting to null
      // this.genericCoreZone = {...this.dangerArea1.properties.corezns.find((item) => item.avprob == 'GENERIC_AVALANCHE_PROBLEM')}

      var isLow = (this.dangerArea1.properties.rdlin == 1)
      this.genericCoreZone = {
        aspects: isLow? Array(16).fill(true):[...this.dangerArea1.properties.aspects],
        loele: isLow? 0:this.dangerArea1.properties.loele,
        hiele: isLow? 10000:this.dangerArea1.properties.hiele,
        inside: {
          rdl: this.dangerArea1.properties.rdlin,
          drdl: this.dangerArea1.properties.drdlin
        }
      }
    },
    displayHelp(tag) {
      this.$emit('displayHelp', tag)
    },
    getPanelTitle(properties) {
      var dayper = properties.dayper.toLowerCase()
      var panelHeader = this.$vuetify.lang.t(`$vuetify.bulletin.periode.${dayper}`)
      // Don't display drdl if not official
      var drdl = properties.name == 'SLF'? properties.drdlin:'UNKNOWN'
      return panelHeader + ': ' + this.getDangerLevelText(properties.rdlin, drdl)
    },
  },
  created() {
    this.updateData()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .table {
    width: 100%;
  }

  .v-data-table >>> td {
    padding-left: 4px !important;
  }

  v-row > div {
    page-break-after: always !important;
  }

  .v-expansion-panels {
    border: 1.2px solid lightgray;
  }

  .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding-left: 8px;
    padding-right: 8px;
  }

  /* Lines between the expansion apanels */
  .v-expansion-panel::after {
    border-top: solid;
    border-top-width: 1.2px;
    border-top-color: rgba(0, 0, 0, 0.12);
    opacity: 1;
  }
</style>
