<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" persistent @keydown.esc="cancel" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title>{{ $vuetify.lang.t(`$vuetify.disclaimer.title`) }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="py-2 px-3 text--secondary textDense">
        <p class="mb-1" v-if="status != null && status > 1" :style="{ color: getWarningColor() }">
          <span class="font-weight-bold pb-0"> {{ $vuetify.lang.t(`$vuetify.attention`) }}</span>
          <span>: </span>
          <span> {{ $vuetify.lang.t(`$vuetify.regionStatus.${status}.long`) }}</span>
        </p>

        <p class="mb-1" v-if="rudr" :style="{ color: getWarningColor() }">
          <span class="font-weight-bold pb-0"> {{ $vuetify.lang.t(`$vuetify.attention`) }}</span>
          <span>: </span>
          <span> {{ $vuetify.lang.t(`$vuetify.disclaimer.text.0`) }}</span>
        </p>

        <p class="mb-1">
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.1`) }}</span>
          <a :href="$vuetify.lang.t(`$vuetify.disclaimer.text.2.link`)" target="_blank" class="font-weight-bold">{{$vuetify.lang.t(`$vuetify.disclaimer.text.2.text`)}}</a>
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.3`) }}</span>
          <a :href="$vuetify.lang.t(`$vuetify.disclaimer.text.4.link`)" target="_blank" class="font-weight-bold">{{$vuetify.lang.t(`$vuetify.disclaimer.text.4.text`)}}</a>
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.5`) }}</span>
        </p>
        <p class="mb-1">
          <span class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.disclaimer.text.6`) }}</span>
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.7`) }}</span>
          <a :href="$vuetify.lang.t(`$vuetify.disclaimer.text.8.link`)" target="_blank" class="font-weight-bold">{{$vuetify.lang.t(`$vuetify.disclaimer.text.8.text`)}}</a>
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.9`) }}</span>
        </p>
        <p class="mb-1">
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.10`) }}</span>
          <a :href="$vuetify.lang.t(`$vuetify.disclaimer.text.11.link`)" target="_blank" class="font-weight-bold">{{$vuetify.lang.t(`$vuetify.disclaimer.text.11.text`)}}</a>
          <span>{{ $vuetify.lang.t(`$vuetify.disclaimer.text.12`) }}</span>
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-0 px-2">
        <p class="py-1 px-1 text--secondary textDense font-weight-bold" id="questionId">
          {{ $vuetify.lang.t(`$vuetify.disclaimer.text.13`) }}
        </p>
        <v-spacer></v-spacer>

        <v-btn class="px-1 text-capitalize" color="primary" text @click.native="agree">{{ $vuetify.lang.t(`$vuetify.yes`) }}</v-btn>
        <v-btn class="px-1 text-capitalize" color="primary" text @click.native="cancel">{{ $vuetify.lang.t(`$vuetify.no`) }}</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store/store'
import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'disclaimer-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    rejectDisclaimer: null,
    acceptDisclaimer: null,
    status: 1,
    area: null,
    rudr: false

  }),
  methods: {
    forceOpen(rejectDisclaimer, acceptDisclaimer) {
      this.area = null
      this.status = null
      this.rejectDisclaimer = rejectDisclaimer
      this.acceptDisclaimer = acceptDisclaimer
      this.rudr = false
      this.dialog = true    
    },
    open(area, status, rudr, rejectDisclaimer, acceptDisclaimer) {
      this.area = area
      this.status = status
      this.rejectDisclaimer = rejectDisclaimer
      this.acceptDisclaimer = acceptDisclaimer
      this.rudr = rudr
      if (!rudr && store.state[this.area].disclaimer.agreed) return true
      this.dialog = true
      return false
    },
    agree() {
      this.dialog = false
      if (this.area != null) {
        this.setAgreed(this.area, true)
      } else {
        this.setAgreed('ch', true)
        this.setAgreed('au', true)
        this.setAgreed('fr', true)
        this.setAgreed('ie', true)
        this.setAgreed('iw', true)
      }
      if (this.acceptDisclaimer != null) {
        this.acceptDisclaimer()
      }
    },
    cancel() {
      this.dialog = false
      if (this.area != null) {
        this.setAgreed(this.area, false)
      } else {
        this.setAgreed('ch', false)
        this.setAgreed('au', false)
        this.setAgreed('fr', false)
        this.setAgreed('ie', false)
        this.setAgreed('iw', false)
      }
      if (this.rejectDisclaimer != null) {
        this.rejectDisclaimer()
      }
    },
    setAgreed(area, agreed) {
      store.dispatch(`${area}/setDisclaimer`, {
        agreed: agreed,
        timestamp: Date.now(),
      })
    },
  },
}
</script>

<style scoped>
.v-btn {
  /* font-weight: bold; */
  min-width: unset !important;
}

.v-btn:hover {
  background-color: unset;
  color: #5e819666 !important;
}

#questionId {
  margin-bottom: 4px !important;
  font-size: 14px !important;
}
</style>
