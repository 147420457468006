////////////////////////////////////////////////////////////////////////////////
// Contains utility functions to be used from mixins AND components
////////////////////////////////////////////////////////////////////////////////

var loggerTexts = null
var loggerSpans = null
var lastTimeStamp = null
export const infoServerLink = 'info.skitourenguru.ch'

export function getInfoServerLink() {
  return location.protocol + '//' + infoServerLink
}

function loadDataHost() {
  // hostname = www.skitourenguru.ch or www.skitourenguru.com or localhost (test)
  var host = location.hostname.toLowerCase()
  var configuredHost = document.body.getAttribute('data-host')
  if (host.startsWith('localhost') && (typeof configuredHost != 'undefined' && configuredHost != null)) {
    return configuredHost 
  }
  host += '/calc_data2'
  return host
}
var _dataHost = null

export function getDataServerLink(relativeLink) {
  if (_dataHost == null) _dataHost = loadDataHost()
  var absoluteLink = location.protocol + '//' + _dataHost 
  if (relativeLink == '') return absoluteLink
  absoluteLink = absoluteLink + '/' + relativeLink
  return absoluteLink
}

export function getRudrServiceLink(isDev, isService) {
  if (isDev) {
    // Test with a local developpement server (VS2022 in Debug-Mode)
    if (isService) {
      // The link to the rest API
      return 'https://localhost:7298/RouteRating'
    } else {
      // The link to the resulting geojson files
      return 'http://localhost:3000'
    }
  } else {
    // Test with the production server
    if (isService) {
      // The link to the rest API
      return 'https://json.skitourenguru.com/rudr/RouteRating'
    } else {
      // The link to the resulting geojson files
      return 'https://json.skitourenguru.com/data'
    }
  }
}

// eslint-disable-next-line no-unused-vars
function loadMapHost() {
  // hostname = www.skitourenguru.ch or www.skitourenguru.com or localhost (test)
  var host = location.hostname.toLowerCase()
  var configuredHost = document.body.getAttribute('data-map')
  if (host.startsWith('localhost') && (typeof configuredHost != 'undefined' && configuredHost != null)) {
    return configuredHost
  }
  const hostElements = host.split('.')
  const len = hostElements.length
  host = location.protocol +'//map' + '.' +  hostElements[len-2] + '.' +  hostElements[len-1]
  return host
}


var _mapHost = null

export function getMapServerLink() {
  if (_mapHost == null) _mapHost = loadMapHost()
  return _mapHost
}  

// Important: tabHeight must be same as SCSS variable $tabs-bar-height
// @todo: May be its possible to read SCSS variable from js
export const tabHeight = 40 // the fixed height of the tab on mobile view
export const mobileBreakpoint = 960
export const infoWidth = 500
export const headerHeight = 40

export function getIsMobile() {
  return window.innerWidth < mobileBreakpoint
}

export function getIsTouch() {
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
}

// content height excluding the app header
export function calculateViewContentHeight() {
  return window.innerHeight - headerHeight
}

// content height excluding the app header and the tab header
export function calculateTabContentHeigt() {
  return window.innerHeight - headerHeight - tabHeight
}

export function calculateMapSize() {
  var width = window.innerWidth
  var height = window.innerHeight - headerHeight
  if (getIsMobile()) {
    height -= tabHeight
  } else {
    width -= infoWidth
  }
  return [width, height]
}

export function parseTime(time) {
  var currentDate = new Date()   
  var date = new Date(currentDate.getTime());
  date.setHours(time.split(":")[0]);
  date.setMinutes(time.split(":")[1]);
  date.setSeconds(time.split(":")[2]);
  return date
}

export function linearFunction(x, x1, x2, y1, y2) {
  if (x <= x1) return y1
  if (x >= x2) return y2
  const y = y1 + ((x - x1) * (y2 - y1)) / (x2 - x1)
  return y
}

export function createGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export function addExtentMargin(extent, extentMargin) {
  var extentBuffer

  extentBuffer = (Math.abs(extent[2] - extent[0]) * extentMargin) / 100
  extent[0] -= extentBuffer
  extent[2] += extentBuffer

  extentBuffer = (Math.abs(extent[3] - extent[1]) * extentMargin) / 100
  extent[1] -= extentBuffer
  extent[3] += extentBuffer

  return extent
}

export function log(text) {
  var date = new Date()
  var str = date.getFullYear() + '.'  + (date.getMonth() + 1).toString().padStart(2, 0) + '.' + date.getDate().toString().padStart(2, 0)
  str += ' ' + date.getHours().toString().padStart(2, 0) + ':' + date.getMinutes().toString().padStart(2, 0) + ':' + date.getSeconds().toString().padStart(2, 0)
  str += '.' + date.getMilliseconds().toString().padStart(3, 0)
  // eslint-disable-next-line no-console
  console.log(str + ' ' + text)
}

export const constDefaultFilters = {
  distRange: [0, 200],
  startHeightRange: [0, 2000],
  heightDiffRange: [0, 2000],
  difficultiesRange: [0, 14],
  snowRange: [0, 50],
  riskRange: [0, 3],
  routeType: [true, true, true]
}

export const constDefaultRudrInput = {
  drdl: 3,
  critElevations: [1800,  5000], 
  critAspects: [true, true, true, true, false, false, false, false, false, false, false, false, true, true, true, true],

  degreeOfFreedom: 50,
  avalancheTerrainWeight: 30,
  movementWeight: 40,
  horizontalMovingCost: 20,
  elevationGainCost: 20,
  elevationLossCost: 25, 
  
  // This a famous default value: true or false, thats the question.
  respectProtAreas: false, 
}

// https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  }
}

export function calcSvgArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle)
  var end = polarToCartesian(x, y, radius, startAngle)
  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
  var d = [
      "M", 0, 0, start.x, start.y, 
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      "Z"
      
  ].join(" ")
  return d    
}

export function loggingStart(text) {
  loggerTexts = []
  loggerTexts.push(text)
  loggerSpans = []
  loggerSpans.push(0)
  lastTimeStamp = new Date()
}

export function loggingMark(text) {
  loggerTexts.push(text)
  const nextTimeStamp = new Date()
  const span = nextTimeStamp - lastTimeStamp
  loggerSpans.push(span)
  lastTimeStamp = nextTimeStamp
}

export function loggingStop(text) {
  loggingMark(text)
  for (let i = 0; i < loggerTexts.length; i++) {
    log(`logging(): "${loggerSpans[i]}": "${loggerTexts[i]}"`)
  }
  loggerTexts = null
  loggerSpans = null
  lastTimeStamp = null      
}

export function isObjectValid(myObject) {
  return (typeof myObject != 'undefined' && myObject != null)
}

