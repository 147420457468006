export const namespaced = true

export const state = {
  lang: 'de',
  guid: null,
}

export const mutations = {
  // Language
  SET_LANG(state, data) {
    state.lang = data.lang
  },
  INIT_LANG(state) {
    state.lang = localStorage.getItem('lang')
  },

  // Guid
  SET_GUID(state, data) {
    state.guid = data.guid
  },

  INIT_GUID(state) {
    state.guid = localStorage.getItem('guid')
  },
}

export const actions = {
  // Language
  setLang({ commit }, data) {
    commit('SET_LANG', data)
  },

  // Guid
  setGuid({ commit }, data) {
    commit('SET_GUID', data)
  },
}
