<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.route.difficulty`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      :fill="foregroundColor"
      d="M27.55,4S25.84,2.68,25,3.59L6.8,24.46l1.31.66L25.85,4.53A2.31,2.31,0,0,1,27.55,4Z"
    />
    <path :fill="foregroundColor" d="M28.72,33.69A2.89,2.89,0,1,0,32.07,36v0a2.87,2.87,0,0,0-3.35-2.3Z" />
    <path
      :fill="foregroundColor"
      d="M39.54,31.46,31,29.81a53.45,53.45,0,0,0-1.16-7c-1.12-4.08-8.68-8.71-9-8.74l7.64-9.11A1.93,1.93,0,0,1,30,4.23S28.36,3.08,27.55,4L9.74,25l1.32.67,6.88-8c.37.36,2.46,1.93,4.4,3.69s1.9,3.15,2,4.72a33.82,33.82,0,0,1-.05,4.18L17.22,36a.92.92,0,0,0-.14,1.51,1.69,1.69,0,0,0,1.88.11s5.34-4.1,9-4.76c3.81-.69,10.26.51,10.26.51a1.62,1.62,0,0,0,1.87-.48.94.94,0,0,0-.53-1.42Z"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconDifficulty',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
