<template>
  <v-container class="px-4 pb-2 pt-2">
    <v-row v-if="dangerArea.properties.name == 'SLF'" class="py-4">
      <danger-and-core-zone
        :coreZone="coreZones[0]"
        @displayHelp="displayHelp"  
        class="pt-3"  
      >
      </danger-and-core-zone>
    </v-row>
    <v-row v-if="text != null">
      <div>
        <p id="avalancheTextTitle" class="font-weight-normal mb-0">{{ text.title + '\n' }}</p>
        <p id="avalancheTextContent" class="text--secondary textDense">{{ text.content }}</p>
      </div>
    </v-row>
    <v-row v-for="coreZone in coreZones" :key="coreZone.avprob" class="pt-2 pb-1">
      <avalanche-problem
        :coreZone="coreZone"
        :opacities="opacities"
        @displayHelp="displayHelp"    
      >
      </avalanche-problem>
    </v-row>
  </v-container>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import AvalancheProblem from './AvalancheProblem.vue'
import DangerAndCoreZone from './DangerAndCoreZone.vue'

export default {
  name: 'moisture-panel',
  mixins: [utilityMixin],
  components: {
    AvalancheProblem,
    DangerAndCoreZone
  },
  props: {
    dangerArea: {
      type: Object,
      required: true,
    },
     isDryOrWet: {
      type: Boolean,
      required: true,
    }   
  },
  data: () => ({
    coreZones: null,
    text: null,
    opacities: [1, 1, 1, 0.25]
  }),
  watch: {
    dangerArea: function () {
      this.updateData()
    }
  },  
  methods: {
    updateData() {
      if (this.isDryOrWet) {
        this.coreZones = this.selectDryCoreZones(this.dangerArea)
      } else {
        this.coreZones = this.selectWetCoreZones(this.dangerArea)
      } 
      var texts = this.getTexts(this.dangerArea)

      // In coreZones find all avalanche problems
      var avalancheProblems = []
      this.coreZones.forEach(coreZone => { 
        avalancheProblems.push(coreZone.avprob)
      });
      // Filter out all texts that match one of the avalanche problems
      texts = texts.filter((text) => avalancheProblems.indexOf(text.type) > -1)

      // Take content of first text
      if (texts.length > 0) {
        this.text = texts[0]
        this.text.title = this.mergeTitles(texts)
      } else {
        this.text = null
      }

      var dangerOpacity = this.dangerArea.properties.name == 'Euregio'? 0.25:1
      var coreZoneOpacity = this.dangerArea.properties.name == 'SLF'? 0.25:1
      this.opacities = [1, coreZoneOpacity, coreZoneOpacity, dangerOpacity]
    },
    mergeTitles(texts) {
      var title = '';
      for (var i=0; i< texts.length; i++) {
        title += texts[i].title
        if (i < texts.length-1) title += ', '
      }
      return title
    },
    displayHelp(tag) {
      this.$emit('displayHelp', tag)
    },     
  },
  created() {
    this.updateData()
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
