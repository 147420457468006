import DataService from '@/services/DataService'

export const namespaced = true
export const area = 'France'

export const state = {
  symbols: {},
  homes: {},
  segments: {},
  routes: {},
  bulletin: {},
  webCams: {},
  skiDepots: {},
  accidents: {},
  cruxes: {},
  overlays: {},
  filterPreferences: {},
  disclaimer: {},
}

const MILLS_ONE_DAY = 86400000

export const mutations = {
  SET_SYMBOLS(state, data) {
    state.symbols = data
  },

  SET_HOMES(state, data) {
    state.homes = data
  },

  SET_SEGMENTS(state, data) {
    state.segments = data
  },

  SET_ROUTES(state, data) {
    state.routes = data
  },

  SET_BULLETIN(state, data) {
    state.bulletin = data
  },

  SET_WEBCAMS(state, data) {
    state.webCams = data
  },

  SET_SKIDEPOTS(state, data) {
    state.skiDepots = data
  },

  SET_ACCIDENTS(state, data) {
    state.accidents = data
  },

  SET_CRUXES(state, data) {
    state.cruxes = data
  },

  INIT_OVERLAYS(state) {
    const savedOverlays = localStorage.getItem('fr_overlays')
    savedOverlays && Object.assign(state.overlays, JSON.parse(savedOverlays) || {})
  },

  SET_OVERLAYS(state, data) {
    Object.assign(state.overlays, data)
  },

  INIT_FILTER_PREFERENCES(state) {
    const savedPreference = localStorage.getItem('fr')
    savedPreference && Object.assign(state.filterPreferences, JSON.parse(savedPreference) || {})
  },

  SET_FILTER_PREFERENCES(state, data) {
    Object.assign(state.filterPreferences, data)
  },

  INIT_DISCLAIMER(state) {
    state.disclaimer.agreed = false
    state.disclaimer.timestamp = 0
    const savedDisclaimer = JSON.parse(localStorage.getItem('fr_disclaimer')) || {}
    if (savedDisclaimer && savedDisclaimer.agreed) {
      if (savedDisclaimer.timestamp > Date.now() - MILLS_ONE_DAY) {
        // in case the dislaimer timestamp is with the last 24h
        Object.assign(state.disclaimer, savedDisclaimer)
      }
    }
  },

  SET_DISCLAIMER(state, data) {
    state.disclaimer.agreed = data.agreed
    state.disclaimer.timestamp = data.timestamp
  },
}

export const actions = {
  fetchSymbols({ commit }) {
    if (Object.keys(state.symbols).length) {
      return Promise.resolve()
    }
    return DataService.getSymbols(area)
      .then((response) => {
        commit('SET_SYMBOLS', response.data)
        commit(
          'region/SET_REGION_VALIDITY',
          {
            collection: response.data.properties.area,
            edition: response.data.properties.bulletin.startValidity,
            validity: response.data.properties.bulletin.stopValidity,
          },
          { root: true }
        )
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchHomes({ commit }) {
    if (Object.keys(state.homes).length) {
      return Promise.resolve()
    }
    return DataService.getHomes(area)
      .then((response) => {
        commit('SET_HOMES', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchSegments({ commit }) {
    if (Object.keys(state.segments).length) {
      return Promise.resolve()
    }
    return DataService.getSegments(area)
      .then((response) => {
        commit('SET_SEGMENTS', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchRoutes({ commit }) {
    if (Object.keys(state.routes).length) {
      return Promise.resolve()
    }
    return DataService.getRoutes(area)
      .then((response) => {
        commit('SET_ROUTES', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchBulletin({ commit }) {
    if (Object.keys(state.bulletin).length) {
      return Promise.resolve()
    }
    return DataService.getBulletin(state.symbols.properties.bulletin.json)
      .then((response) => {
        commit('SET_BULLETIN', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchWebCams({ commit }) {
    if (Object.keys(state.webCams).length) {
      return Promise.resolve()
    }
    return DataService.getWebCams(area)
      .then((response) => {
        commit('SET_WEBCAMS', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchSkiDepots({ commit }) {
    if (Object.keys(state.skiDepots).length) {
      return Promise.resolve()
    }
    return DataService.getSkiDepots(area)
      .then((response) => {
        commit('SET_SKIDEPOTS', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchAccidents({ commit }) {
    if (Object.keys(state.accidents).length) {
      return Promise.resolve()
    }
    return DataService.getAccidents(area)
      .then((response) => {
        commit('SET_ACCIDENTS', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  fetchCruxes({ commit }) {
    if (Object.keys(state.cruxes).length) {
      return Promise.resolve()
    }
    return DataService.getCruxes(area)
      .then((response) => {
        commit('SET_CRUXES', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  setOverlays({ commit }, data) {
    commit('SET_OVERLAYS', data)
  },

  setFilterPreferences({ commit }, data) {
    commit('SET_FILTER_PREFERENCES', data)
  },

  setDisclaimer({ commit }, data) {
    commit('SET_DISCLAIMER', data)
  },
}
