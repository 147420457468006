<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 240" :width="width" :height="height">
    <title>Menu</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <line x1="85" y1="85" x2="215" y2="85" :stroke="foregroundColor" stroke-width="15" />
    <line x1="85" y1="120" x2="215" y2="120" :stroke="foregroundColor" stroke-width="15" />
    <line x1="85" y1="155" x2="215" y2="155" :stroke="foregroundColor" stroke-width="15" />
    <ellipse v-if="nrNews > 0" cx="228" cy="85" rx="46" ry="50" :fill="foregroundColor" />
    <text
      v-if="nrNews > 0"
      transform="translate(200 120)"
      :fill="digitColor"
      style="font-size: 100px; font-family: Roboto-Medium, Roboto; font-weight: 700;"
    >
      {{ digit }}
    </text>
  </svg>
</template>

<script>
import { getNavigationCssColor, getRiskCssColor } from '../colors'

export default {
  name: 'icon-menu',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 150,
    },
    height: {
      type: [Number, String],
      default: 120,
    },
    foregroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 1)',
    },
    backgroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    nrNews: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    digitColor: function () {
      // The RiskColor for 2.5 will return the correct 'red' color
      return getRiskCssColor(2.5, 'ICONS')
    },
    digit: function () {
      var nr = this.nrNews > 9 ? 9 : this.nrNews
      return nr.toString()
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
