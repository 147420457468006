<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" :width="width" :height="height">
    <title></title>
    <!-- <rect width="100%" height="100%" fill="yellow" /> -->
    <g transform="translate(100,100)" stroke-width="1" stroke="gray" fill="black" class="fingerPointer">
      <!-- Sorry, but event handling doesn't work for an array and v-for in svg: If you dont believe show me! -->
      <path @click="click0()"   :d="describeArc(0, 0, 60,  0*22.5,  1*22.5)" :fill-opacity="aspectOpacity0"/>
      <path @click="click1()"   :d="describeArc(0, 0, 60,  1*22.5,  2*22.5)" :fill-opacity="aspectOpacity1"/>
      <path @click="click2()"   :d="describeArc(0, 0, 60,  2*22.5,  3*22.5)" :fill-opacity="aspectOpacity2"/>
      <path @click="click3()"   :d="describeArc(0, 0, 60,  3*22.5,  4*22.5)" :fill-opacity="aspectOpacity3"/>   
      <path @click="click4()"   :d="describeArc(0, 0, 60,  4*22.5,  5*22.5)" :fill-opacity="aspectOpacity4"/>
      <path @click="click5()"   :d="describeArc(0, 0, 60,  5*22.5,  6*22.5)" :fill-opacity="aspectOpacity5"/>
      <path @click="click6()"   :d="describeArc(0, 0, 60,  6*22.5,  7*22.5)" :fill-opacity="aspectOpacity6"/>
      <path @click="click7()"   :d="describeArc(0, 0, 60,  7*22.5,  8*22.5)" :fill-opacity="aspectOpacity7"/>
      <path @click="click8()"   :d="describeArc(0, 0, 60,  8*22.5,  9*22.5)" :fill-opacity="aspectOpacity8"/>
      <path @click="click9()"   :d="describeArc(0, 0, 60,  9*22.5, 10*22.5)" :fill-opacity="aspectOpacity9"/>
      <path @click="click10()"  :d="describeArc(0, 0, 60, 10*22.5, 11*22.5)" :fill-opacity="aspectOpacity10"/>
      <path @click="click11()"  :d="describeArc(0, 0, 60, 11*22.5, 12*22.5)" :fill-opacity="aspectOpacity11"/>   
      <path @click="click12()"  :d="describeArc(0, 0, 60, 12*22.5, 13*22.5)" :fill-opacity="aspectOpacity12"/>
      <path @click="click13()"  :d="describeArc(0, 0, 60, 13*22.5, 14*22.5)" :fill-opacity="aspectOpacity13"/>
      <path @click="click14()"  :d="describeArc(0, 0, 60, 14*22.5, 15*22.5)" :fill-opacity="aspectOpacity14"/>
      <path @click="click15()"  :d="describeArc(0, 0, 60, 15*22.5, 16*22.5)" :fill-opacity="aspectOpacity15"/>  

      <line x1="0" y1="-60" x2="0" y2="60" stroke="gray"  stroke-width="5"  />
      <line x1="-60" y1="0" x2="60" y2="0" stroke="gray"  stroke-width="5"  />
      <line x1="-60" y1="-60" x2="60" y2="60" stroke="gray"  stroke-width="5"  />
      <line x1="60" y1="-60" x2="-60" y2="60" stroke="gray"  stroke-width="5"  />
    </g>
    <text x="100" y="190" text-anchor="middle">S</text>
    <text x="100" y="30" text-anchor="middle">N</text>
    <text x="20" y="110" text-anchor="middle">W</text>
    <text x="175" y="110" text-anchor="middle">E</text>    
  </svg>
</template>

<script>

import { utilityMixin } from '../mixins/utilityMixin'
import { calcSvgArc } from '../utilities'

export default {
  name: 'critical-aspects',
  props: {
    aspects: {
      type: Array,
    },
    isDynamic: {
      type: Boolean,
      default: true
    },
    width: {
      type: [Number, String],
      default: 120,
    },
    height: {
      type: [Number, String],
      default: 120,
    },    
  },
  mixins: [utilityMixin],
  data: () => ({
    aspectOpacity0:  0,
    aspectOpacity1:  0,
    aspectOpacity2:  0,
    aspectOpacity3:  0,
    aspectOpacity4:  0,
    aspectOpacity5:  0,
    aspectOpacity6:  0,
    aspectOpacity7:  0,
    aspectOpacity8:  0,
    aspectOpacity9:  0,
    aspectOpacity10: 0,
    aspectOpacity11: 0,
    aspectOpacity12: 0,
    aspectOpacity13: 0,
    aspectOpacity14: 0,
    aspectOpacity15: 0,      
  }),
  watch: {
    aspects: function () {
      this.updateView()     
    },
  },  
  methods: {
    click0() {
      if (!this.isDynamic) return
      this.aspectOpacity0 =  1 - this.aspectOpacity0;  
      this.aspectChanged()
    },
    click1() {
      if (!this.isDynamic) return
      this.aspectOpacity1 =  1 - this.aspectOpacity1;  
      this.aspectChanged()
    },
    click2() {
      if (!this.isDynamic) return
      this.aspectOpacity2 =  1 - this.aspectOpacity2;  
      this.aspectChanged()
    },
    click3() {
      if (!this.isDynamic) return
      this.aspectOpacity3 =  1 - this.aspectOpacity3;  
      this.aspectChanged()
    },
    click4() {
      if (!this.isDynamic) return
      this.aspectOpacity4 =  1 - this.aspectOpacity4;  
      this.aspectChanged()
    },
    click5() {
      if (!this.isDynamic) return
      this.aspectOpacity5 =  1 - this.aspectOpacity5;  
      this.aspectChanged()
    },
    click6() {
      if (!this.isDynamic) return
      this.aspectOpacity6 =  1 - this.aspectOpacity6;  
      this.aspectChanged()
    },
    click7() {
      if (!this.isDynamic) return
      this.aspectOpacity7 =  1 - this.aspectOpacity7;  
      this.aspectChanged()
    },
    click8() {
      if (!this.isDynamic) return
      this.aspectOpacity8 =  1 - this.aspectOpacity8;  
      this.aspectChanged()
    },
    click9() {
      if (!this.isDynamic) return
      this.aspectOpacity9 =  1 - this.aspectOpacity9;  
      this.aspectChanged()
    },
    click10() {
      if (!this.isDynamic) return
      this.aspectOpacity10 =  1 - this.aspectOpacity10;  
      this.aspectChanged()
    },
    click11() {
      if (!this.isDynamic) return
      this.aspectOpacity11 =  1 - this.aspectOpacity11;  
      this.aspectChanged()
    },
    click12() {
      if (!this.isDynamic) return
      this.aspectOpacity12 =  1 - this.aspectOpacity12;  
      this.aspectChanged()
    },
    click13() {
      if (!this.isDynamic) return
      this.aspectOpacity13 =  1 - this.aspectOpacity13;  
      this.aspectChanged()
    },
    click14() {
      if (!this.isDynamic) return
      this.aspectOpacity14 =  1 - this.aspectOpacity14;  
      this.aspectChanged()
    },         
    click15() {
      if (!this.isDynamic) return
      this.aspectOpacity15 =  1 - this.aspectOpacity15;  
      this.aspectChanged()
    },        
    describeArc(x, y, radius, startAngle, endAngle) {
      return calcSvgArc(x, y, radius, startAngle, endAngle)
    },
    aspectChanged() {
      var aspects = []
      aspects.push(this.aspectOpacity0 == 1)
      aspects.push(this.aspectOpacity1 == 1)
      aspects.push(this.aspectOpacity2 == 1)
      aspects.push(this.aspectOpacity3 == 1)
      aspects.push(this.aspectOpacity4 == 1)
      aspects.push(this.aspectOpacity5 == 1)
      aspects.push(this.aspectOpacity6 == 1)
      aspects.push(this.aspectOpacity7 == 1)
      aspects.push(this.aspectOpacity8 == 1)
      aspects.push(this.aspectOpacity9 == 1)
      aspects.push(this.aspectOpacity10 == 1)
      aspects.push(this.aspectOpacity11 == 1)
      aspects.push(this.aspectOpacity12 == 1)
      aspects.push(this.aspectOpacity13 == 1)
      aspects.push(this.aspectOpacity14 == 1)
      aspects.push(this.aspectOpacity15 == 1)
      this.$emit('aspectChanged', aspects)
    },
    updateView() {
      this.aspectOpacity0  = this.aspects[0] ? 1:0
      this.aspectOpacity1  = this.aspects[1] ? 1:0
      this.aspectOpacity2  = this.aspects[2] ? 1:0
      this.aspectOpacity3  = this.aspects[3] ? 1:0  
      this.aspectOpacity4  = this.aspects[4] ? 1:0
      this.aspectOpacity5  = this.aspects[5] ? 1:0
      this.aspectOpacity6  = this.aspects[6] ? 1:0
      this.aspectOpacity7  = this.aspects[7] ? 1:0    
      this.aspectOpacity8  = this.aspects[8] ? 1:0
      this.aspectOpacity9  = this.aspects[9] ? 1:0
      this.aspectOpacity10 = this.aspects[10]? 1:0
      this.aspectOpacity11 = this.aspects[11]? 1:0  
      this.aspectOpacity12 = this.aspects[12]? 1:0
      this.aspectOpacity13 = this.aspects[13]? 1:0
      this.aspectOpacity14 = this.aspects[14]? 1:0
      this.aspectOpacity15 = this.aspects[15]? 1:0  
    }
  },
  created() {
    this.updateView()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

text {
  font-size: 28px;
  font-family: Roboto-Medium, Roboto; 
  font-weight: 700;
}

</style>
