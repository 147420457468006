import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import de from '../../src/locale/de.ts'
import en from '../../src/locale/en.ts'
import fr from '../../src/locale/fr.ts'
import it from '../../src/locale/it.ts'

import LogoStandard from '@/icons/LogoStandard.vue'
import LogoLabeled from '@/icons/LogoLabeled.vue'
import IconHome from '@/icons/IconHome.vue'
import IconTravelDistance from '@/icons/IconTravelDistance.vue'
import IconStartElevation from '@/icons/IconStartElevation.vue'
import IconSnowAmount from '@/icons/IconSnowAmount.vue'
import IconElevationGain from '@/icons/IconElevationGain.vue'
import IconDifficulty from '@/icons/IconDifficulty.vue'
import IconTrafficLight from '@/icons/IconTrafficLight.vue'
import IconSummit from '@/icons/IconSummit.vue'
import IconNews from '@/icons/IconNews.vue'
import IconMenu from '@/icons/IconMenu.vue'

Vue.use(Vuetify, {
  iconfont: 'mdi'
})

export default new Vuetify({
  lang: {
    locales: { en, de, it, fr },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: '#5E8196',

        // These colors are not yet used, but depending on components they suddenly can appear
        secondary: '#ff0000',
        tertiary: '#ff0000',
        accent: '#ff0000',
        error: '#ff0000',
        info: '#ff0000',
        success: '#ff0000',
        warning: '#ff0000',
        anchor: '#6F8F90',
      },
    },
  },
  icons: {
    values: {
      logoStandard: {
        // name of our custom icon
        component: LogoStandard, // our custom component
      },
      logoLabeled: {
        component: LogoLabeled,
      },
      iconHome: {
        component: IconHome,
      },
      iconTravelDistance: {
        component: IconTravelDistance,
      },
      iconStartElevation: {
        component: IconStartElevation,
      },
      iconSnowAmount: {
        component: IconSnowAmount,
      },
      iconElevationGain: {
        component: IconElevationGain,
      },
      iconDifficulty: {
        component: IconDifficulty,
      },
      iconTrafficLight: {
        component: IconTrafficLight,
      },
      iconSummit: {
        component: IconSummit,
      },
      iconNews: {
        component: IconNews,
      },
      iconMenu: {
        component: IconMenu,
      },
    },
  },
})
