<template>
  <v-container fluid class="py-0">
    <div class="text-h6 ml-1 mr-1 mt-3 mb-2 justify-left">{{ this.$vuetify.lang.t(`$vuetify.route.title`) }}</div>
    <div v-if="(getDifficulty(true) >= 7) || (getDifficulty(false) >= 7)" class="ml-1 mr-1 mb-2 textDense" :style="{ color: getWarningColor() }">
      {{ $vuetify.lang.t(`$vuetify.route.diffWarning`) }}
    </div>
    <v-simple-table dense>
      <colgroup>
        <col width="50%" />
        <col width="50%" />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.stop') }}</td>
          <td>{{ `${trackData.properties.stop} (${trackData.properties.stopEle}\u2006m)` }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.start') }}</td>
          <td>{{ `${trackData.properties.start} (${trackData.properties.startEle}\u2006m)` }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.elevationDifference') }}</td>
          <td>{{ `${trackData.properties.ele}\u2006` + this.$vuetify.lang.t(`$vuetify.route.elevationGain`) }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.length') }}</td>
          <td>{{ `${trackData.properties.len}\u2006m` }}</td>
        </tr>
        <tr @click="displayHelp('ascentTime')" class="fingerPointer">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.time') }}</td>
          <td>
            <a>{{ getTimeString(trackData.properties.time) }}</a>
          </td>
        </tr>
        <tr v-if="trackData.properties.snow != null" @click="displayHelp('snowAmount')" class="fingerPointer">
          <td class="text-left">{{ getSnowTitle() }}</td>
          <td>
            <span>
              <a>{{ getSnowQuantityText() }}</a>
            </span>
            <!--
              <span v-if="trackData.properties.diff==0" :style="{ color: getWarningColor() }">
                {{ $vuetify.lang.t('$vuetify.route.beta') }}
              </span>        
              -->
          </td>
        </tr>
        <!-- Manual difficulty -->
        <tr v-if="getDifficulty(false) != 0" @click="displayHelp('difficulty')" class="fingerPointer">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.difficulty') }}</td>
          <td>
            <span>
              <a>{{ getDifficultyText(false) }}</a>
            </span>
          </td>
        </tr>
         <!-- Automatic difficulty -->
        <tr v-if="getDifficulty(true) != 0" @click="displayHelp('difficulty')" class="fingerPointer">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.difficulty') }}</td>
          <td>
            <span>
              <a>{{ getDifficultyText(true) }}</a>
            </span>
            <!-- <span class="pl-1" :style="{ color: getWarningColor() }">
              {{ $vuetify.lang.t('$vuetify.route.beta') }}
            </span>             -->
          </td>
        </tr>        
        <tr @click="displayHelp('riskIndicator')" class="fingerPointer">
          <td class="text-left font-weight-bold">{{ $vuetify.lang.t('$vuetify.route.riskIndicator') }}</td>
          <td>
            <v-chip class="px-1 fingerPointer font-weight-bold" small :color="getColor(trackData.properties.risk)">{{
              trackData.properties.risk.toFixed(2)
            }}</v-chip>
            <span v-if="isDangerLevelRising(dangerArea1, dangerArea2)" :style="{ color: getWarningColor() }" class="pl-3">{{ $vuetify.lang.t('$vuetify.route.rising') }}</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div v-if="trackData.properties.sri != null">
      <v-btn v-if="ratingsDisplayed" class="text-capitalize" color="primary" text @click="toggleRatingsDisplay()">{{this.$vuetify.lang.t(`$vuetify.ratings.displayLess`)}}</v-btn>
      <v-btn v-else class="text-capitalize" color="primary" text @click="toggleRatingsDisplay()">{{this.$vuetify.lang.t(`$vuetify.ratings.displayMore`)}}</v-btn>
      <div v-if="ratingsDisplayed" class="text-h6 ml-1 mr-1 mt-3 mb-2 justify-left">{{ this.$vuetify.lang.t(`$vuetify.ratings.title`) }}</div>
      <div v-if="ratingsDisplayed" class="ml-1 mr-1 mb-2 textDense">{{ $vuetify.lang.t(`$vuetify.ratings.intro`) }}</div>
      <v-simple-table v-if="ratingsDisplayed" dense>
        <colgroup>
          <col width="50%" />
          <col width="50%" />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr v-for="n in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="n" :value="n" class="fingerPointer">
            <td @click="displayHelp('ratings')" class="text-left"><a>{{ $vuetify.lang.t(`$vuetify.ratings.bulletins.${n}`) }}</a></td>
            <td @click="displayHelp('riskIndicator')">
              <v-chip class="px-1 fingerPointer caption" small :color="getColor(trackData.properties.sri[n])">{{
                trackData.properties.sri[n].toFixed(2)
              }}</v-chip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>  
      <v-btn 
        v-if="ratingsDisplayed"
        class="text-uppercase mt-2" 
        color="primary" 
        text 
        @click="onRouteExperiment()">{{ $vuetify.lang.t(`$vuetify.ratings.experimentButton`) }}
      </v-btn>
    </div>                

    <div class="text-h6 ml-1 mr-1 mt-3 mb-2 justify-left">{{ this.$vuetify.lang.t(`$vuetify.info.title`) }}</div>
    <v-simple-table dense>
      <colgroup>
        <col width="50%" />
        <col width="50%" />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.gps.title') }}</td>
          <td>
            <a :href="getGpxLink(true)" :download="getGpxLink(false)">{{
              $vuetify.lang.t('$vuetify.route.links.gps.link')
            }}</a>
          </td>
        </tr>
        <tr v-if="hasSac()">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.sac.title') }}</td>
          <td>
            <a target="_blank" :href="getSacLink()">{{ $vuetify.lang.t('$vuetify.route.links.sac.link') }}</a>
          </td>
        </tr>        
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.gipfelbuch.title') }}</td>
          <td>
            <a target="_blank" :href="getGipfelbuchLink()">{{ $vuetify.lang.t('$vuetify.route.links.gipfelbuch.link') }}</a>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.weather.title') }}</td>
          <td>
            <a target="_blank" :href="getMeteoBlueLink()">{{ $vuetify.lang.t('$vuetify.route.links.weather.link') }}</a>
          </td>
        </tr>
        <tr v-if="hasLiterature()">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.literature.title') }}</td>
          <td>
            <a target="_blank" :href="getLiteratureLink(this.trackData.properties.lit[0])">{{ getLiteratureName(this.trackData.properties.lit[0])}}</a>
          </td>
        </tr>
        <tr
          class="fingerPointer"
          v-if="trackData.properties.wild < 200 && trackData.properties.wild != 0"
          @click="displayHelp('wildlife')"
        >
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.wild.title') }}</td>
          <td>
            <a>{{ getWildlifeSactuaryHint() }}</a>
          </td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.transportSchedule.title') }}</td>
          <td>
            <a target="_blank" :href="googleMapLink">{{
              $vuetify.lang.t('$vuetify.route.links.transportSchedule.link')
            }}</a>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>

<script>
//import store from '@/store/store'
import { getRiskCssColor } from '../colors.js'
import { utilityMixin } from '../mixins/utilityMixin'
import { collections } from '../variables'
import { getDataServerLink, getIsMobile, log } from '../utilities'
import { transform } from 'ol/proj'

export default {
  name: 'track-table',
  data: () => ({
    wildLifeDisplayed: false,
    googleMapLink: "",
    ratingsDisplayed: false,
    sacLink0: {
      de: 'huetten-und-touren/sac-tourenportal',
      en: 'huts-and-tours/sac-route-portal',
      fr: 'cabanes-et-courses/portail-des-courses-du-cas',
      it: 'capanne-e-escursioni/portale-escursionistico-del-cas'
    },
    sacLink1: {
      de: 'skitouren',
      en: 'ski-touring',
      fr: 'randonnee-a-ski',
      it: 'scialpinismo'
    },
    panicoLinks: [
      'skitourenfuehrer-allgaeu', // id=1
      'skitourenfuehrer-bayerische-alpen',
      'skitouren-hohe-tauern',
      'skitourenfuehrer-karwendel-rofan-wetterstein',
      'skitourenfuehrer-kitzbueheler-alpen',
      'skitourenfuehrer-lechtaler-alpen',
      'skitourenfuhrer-salzburger-land',
      'skitourenfuehrer-silvretta',
      'skitourenfuehrer-suedtirol-band1',
      'skitouren-dolomiten-bd2',
      'skitourenfuehrer-tuxer-zillertaler-alpen',
      'skifuehrer-voralberg',
      'skitourenfuehrer-bregenzerwald',
      'skitourenfuehrer-oetztaler-alpen',
      'skitourenfuehrer-stubaier-alpen', 
      'skitouren-suedtirol-vinschgau-bd3'              
      ]
  }),
  mixins: [utilityMixin],
  props: {
    trackData: {
      type: Object,
      required: false,
    },
    area: {
      type: String,
      required: true,
    },
    snowDate: {
      type: String,
      required: true,
    },
    dangerArea1: {
      type: Object,
      required: true,
    },
    dangerArea2: {
      type: Object,
      required: false,
    },    
    destPoint: {
      type: Array,
      required: false,
    },    
  },
  watch: {
    destPoint: function () {
      log('trackData(): changed')
      this.updateGoogleMapLink()
    },
  },  
  methods: {
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('TrackTable: Display help')
      this.$emit('displayHelp', tag)
    },
    getColor(risk) {
      return getRiskCssColor(risk, 'EYES')
    },
    onWildlifeClicked() {
      var overlaySelector = this.$store.state[this.area].overlays.selector || 1
      overlaySelector = overlaySelector == 8 ? 1 : 8
      this.$emit('swisstopoOverlayChanged', overlaySelector)
      this.wildLifeDisplayed = !this.wildLifeDisplayed
      if (getIsMobile()) {
        window.location.reload(false)
      }
    },
    getTimeString(time) {
      const totalMinutes = 10 * Math.round((60 * time) / 10)
      var minutes = Math.floor(totalMinutes % 60)
      var hours = Math.floor(totalMinutes / 60)
      return hours + 'h\u2006' + (minutes == 0 ? '' : minutes + "'")
    },
    getGpxLink(fullyQualified) {
      var gpxFile = collections[this.area] + '_ID' + this.pad(this.trackData.properties.id, 6) + '.gpx'
      if (!fullyQualified) return gpxFile
      return getDataServerLink('gpx/' + gpxFile)
    },
    getSacLink() {
      const lang = this.$vuetify.lang.current
      const url = 'https://www.sac-cas.ch/' + lang + '/' + this.sacLink0[lang] + '/' + this.trackData.properties.sactid + '/' + this.sacLink1[lang]  + '/' + this.trackData.properties.sacid
      return url
    },
    getGipfelbuchLink() {
      var uri = 'https://www.gipfelbuch.ch'
      var ids = this.trackData.properties.gb
      if (typeof ids == 'undefined' || ids == null || ids.length == 0) {
        // Summit search function of Gipfelbuch
        uri += '/punkte/uebersicht/src_points/'
        uri += encodeURIComponent(this.trackData.properties.stop)
        return uri
      }
      // Take only the first id
      uri += '/tourenfuehrer/routen/id/'
      uri += ids[0]
      return uri
    },
    getMeteoBlueLink() {
      var point = this.trackData.geometry.coordinates
      point = transform(point, 'EPSG:3857', 'EPSG:4326')
      var easting = Math.round(100 * point[0]) / 100
      var northing = Math.round(100 * point[1]) / 100

      var eastingText = Math.abs(easting).toString()
      if (easting > 0) {
        eastingText += 'E'
      } else {
        eastingText += 'W'
      }

      var northingText = Math.abs(northing).toString()
      if (northing > 0) {
        northingText += 'N'
      } else {
        northingText += 'S'
      }
      return 'https://www.meteoblue.com/' + this.$vuetify.lang.current + '/weather/week/' + northingText + eastingText
    },
    getTimetableUri(destPoint) {
      // travelmode=driving/transit
      var uri = 'https://www.google.com/maps/dir/?api=1&travelmode=transit'
      var homePoint = this.$store.state[this.area].filterPreferences.homePoint
      if (typeof homePoint != 'undefined' && this.homePoint != null) {
        var homePoint4326 = transform(homePoint, 'EPSG:3857', 'EPSG:4326')
        uri += '&origin=' + homePoint4326[1] + ',' + homePoint4326[0]
      }    
      if (typeof destPoint != 'undefined' && this.destPoint != null) {
        var destPoint4326 = transform(destPoint, 'EPSG:3857', 'EPSG:4326')
        uri += '&destination=' + destPoint4326[1] + ',' + destPoint4326[0] 
      }
      return uri
    },
    updateGoogleMapLink() {
      var destPoint = this.destPoint
      if (destPoint == 'undefined' || this.destPoint == null) {
        destPoint = this.trackData.geometry.coordinates
        log('updateGoogleMapLink(): destPoint from symbol: ' + destPoint[0] + '/' + destPoint[1])   
      } else {
        log('updateGoogleMapLink(): destPoint from segment: ' + destPoint[0] + '/' + destPoint[1])   
      }
        
      this.googleMapLink = this.getTimetableUri(destPoint) 
    },
    checkUic(uic) {
      if (typeof uic == 'undefined' || uic == null) return false
      var uicString = uic.toString()
      if (uicString[0] == '0' && uicString[1] == '1') return false
      return true
    },
    getWildlifeSactuaryHint() {
      if (this.trackData.properties.wild < 0) {
        return this.$vuetify.lang.t('$vuetify.route.links.wild.crosses')
      }
      var hint = this.$vuetify.lang.t('$vuetify.route.links.wild.nearby')
      hint = hint.replace('xxx', this.trackData.properties.wild)
      return hint
    },
    getDifficulty(automatic) {
      if (automatic) {
        if (typeof this.trackData.properties.ad == 'undefined' || this.trackData.properties.ad == null) {
          return 0
        }
        return this.trackData.properties.ad
      } else {
        if (typeof this.trackData.properties.diff == 'undefined' || this.trackData.properties.diff == null) {
          return 0
        }
        return this.trackData.properties.diff
      }
    },
    hasSac() {
      if (typeof this.trackData.properties.sacid == 'undefined' || this.trackData.properties.sacid == null) return false
      if (typeof this.trackData.properties.sactid == 'undefined' || this.trackData.properties.sactid == null) return false
      return true
    },
    hasLiterature() {
      if (typeof this.trackData.properties.lit == 'undefined' || this.trackData.properties.lit == null) return false
      return (this.trackData.properties.lit.length > 0)
    },    
    getLiteratureLink(lit) {
      const id =Number(lit.substring(1)) - 1
      if (lit[0] == 'P') {
        var link = 'https://www.panico.de/' + this.panicoLinks[id] + '.html'
        // link += '?utm_source=skitourenguru&utm_medium=skitourenguru&utm_campaign=skitourenguru&utm_id=skitourenguru'
        return link;
      }
      throw 'getLiteratureLink(): Unsupported editor'
    },
    getLiteratureName(lit) {
      if (lit[0] == 'P') return this.$vuetify.lang.t('$vuetify.route.links.literature.link.panico')
      throw 'getLiteratureName(): Unsupported editor'
    },
    getDifficultyText(automatic) {
      var text = this.getDifficultyForArea(this.getDifficulty(automatic), this.area) + ' '
      if (automatic) {
        text += this.$vuetify.lang.t('$vuetify.route.diff.automatic')
      } else {
        text += this.$vuetify.lang.t('$vuetify.route.diff.manual')
      }
      return text
    },
    getSnowQuantityText() {
      var text = ''
      text += this.trackData.properties.snow[0] + '\u200B\u2013\u200B' + this.trackData.properties.snow[1] + '\u2006'
      text += ' (Ø ' + this.trackData.properties.snow[2] +')\u2006cm'
      text += ', ' + this.trackData.properties.snow[4] + '\u200B%' // + ' >10\u2006cm'
      return text
    },
    getSnowTitle() {
      return (
        this.$vuetify.lang.t('$vuetify.route.snowAmount') + ' (' + this.formatDate(new Date(this.snowDate)) + ')'
      )
    },
    toggleRatingsDisplay() {
      this.ratingsDisplayed = !this.ratingsDisplayed;
    },
    onRouteExperiment() {
      var gpxurl = this.getGpxLink(true)
      gpxurl = encodeURI(gpxurl)
      this.$router.push({ name: 'rating-view', query: { gpxurl: gpxurl } })
    }
  },
  mounted() {
    this.updateGoogleMapLink()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-chip {
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: unset;
}
.table {
  width: 100%;
}
.v-data-table >>> td {
  padding-left: 4px !important;
}
</style>
