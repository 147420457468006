export const namespaced = true

export const state = {
  title: '',
  status: 0,
  risk: 0,
}

export const mutations = {
  SET_HEADER(state, data) {
    state.title = data.title
    state.status = data.status
    state.risk = data.risk
  },
}

export const actions = {
  setHeader({ commit }, data) {
    commit('SET_HEADER', data)
  },
}
