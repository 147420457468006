import DataService from '@/services/DataService'

export const refreshMixin = {
  inheritAttrs: false,
  data: () => ({
    summary: null,
  }),
  methods: {
    startRefresher() {
      var that = this
      DataService.getSummary().then((response) => {
        that.summary = response.data
      })

      this.startPeriodicTimer()
      this.startEveningTimer()
    },
    startEveningTimer() {
      const triggerTime = new Date() 
      triggerTime.setHours(17, 0, 0, 0)
      const now = new Date()
  
      // Increase timepoint by 24 hours if in the past
      if (triggerTime.getTime() < now.getTime()) {
        triggerTime.setHours(triggerTime.getHours() + 24)
      }
  
      // Get the interval in ms from now to the timepoint when to trigger the alarm
      const firstTriggerAfterMs = triggerTime.getTime() - now.getTime()
  
      setTimeout(function(){
        console.log('startEveningTimer(): single evening refresh!')
        location.reload()
      }, firstTriggerAfterMs);
    },
    isInActiveHours() {
      var d = new Date()
      var n = d.getHours()
      if (n >= 8 && n < 9) return true
      if (n >= 17 && n < 20) return true
      // Don't issue requests out of possible updating times
      // Test use: return true
      return false
    },
    startPeriodicTimer() {
      var that = this
      setInterval(function () {
        if (!that.isInActiveHours()) {
          return
        }

        DataService.getSummary().then((response) => {
          that.processSummary(that.summary, response.data)
          that.summary = response.data
        })
      }, 6 * 60 * 1000)
    },
    regionChanged(lastSummary, newRegion) {
      const lastRegion = lastSummary.regions.find((item) => item.region === newRegion.region)
      if (lastRegion == null) return true
      return (lastRegion.hash != newRegion.hash)
    },

    // eslint-disable-next-line no-unused-vars
    processSummary(lastSummary, newSummary) {
      if (lastSummary == null) {
        return
      }

      var issueRefresh = false
      try {       
        for (var i = 0; i < newSummary.regions.length; i++) {
          if (this.regionChanged(lastSummary, newSummary.regions[i])) {
            issueRefresh = true
          }
        }
      }
      catch(err) {
        // eslint-disable-next-line no-console
        console.log('processSummary(): Exception because ' + err.message)    
        issueRefresh = true    
      }

      if (this.$route.name === 'rating-view' || this.$route.name === 'news-view') {
        issueRefresh = false 
      }

      if (issueRefresh) {
        // eslint-disable-next-line no-console
        console.log('processSummary(): Will issue a refresh now!')
        location.reload()
        // vm.$forceUpdate()
      } else {
        // eslint-disable-next-line no-console
        console.log('processSummary(): No refresh needed!')
      }
    },
  },
}
