<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 256" :width="width" :height="height">
    <title>Skitourenguru Standard Logo</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path d="m 96.418961,125.81282 0,0 0,0 z" :fill="foregroundColor" />
    <path
      d="m 180.58896,164.88282 c 48.1,-15 21.05,-75.149994 21.05,-75.149994 0,0 0,39.079994 -31.7,50.359994 -46.27,16.48 -72.459999,-13 -73.499999,-14.24 0.86,1.64 27.959999,53.83 84.149999,39.03 z"
      :fill="foregroundColor"
    />
    <path
      d="m 162.53896,182.94282 c -75.159999,0 -120.239999,-114.240004 -120.239999,-114.240004 0,0 21,144.280004 120.239999,144.280004 69.13,-3 51.1,-57.11 51.1,-57.11 0,0 -11.01,27.07 -51.1,27.07 z"
      :fill="foregroundColor"
    />
    <path
      d="m 139.46896,128.88282 a 34.67,34.67 0 0 0 30.15,-51.790004 c -1.73,-4.93999 -6.28,-12.19999 -18.07,-20.059995 -20.25,-13.47 -34.47,-4.79 -34.47,-4.79 a 31.2,31.2 0 0 1 11.22,9.140005 34.67,34.67 0 0 0 11.15,67.499994 z"
      :fill="foregroundColor"
    />
  </svg>
</template>

<script>
import { getPrimaryCssColor } from '../colors'

export default {
  name: 'LogoStandard',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    foregroundColor: {
      type: String,
      default: '#FFFFFF',
    },
    backgroundColor: {
      type: String,
      default: getPrimaryCssColor(),
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
