<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.route.startElevation`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      :fill="foregroundColor"
      d="M24.57,13.16a2.58,2.58,0,0,1,3.52-.93,2.49,2.49,0,0,1,.92.93L42.53,36.34a2.57,2.57,0,0,1-.94,3.51,2.61,2.61,0,0,1-1.28.34h-27a2.57,2.57,0,0,1-2.57-2.57,2.61,2.61,0,0,1,.34-1.28Z"
    />
    <circle
      :fill="foregroundColor"
      :stroke="backgroundColor"
      stroke-miterlimit="10"
      stroke-width="1.67px"
      cx="7.87"
      cy="34.25"
      r="6.85"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconStartElevation',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
