import { calculateViewContentHeight, calculateTabContentHeigt, getIsMobile } from '../utilities'

export const resizeMixin = {
  inheritAttrs: false,
  data: () => ({
    contentHeight: 0,
    viewContentHeight: 0,
    isMobile: false,
  }),
  methods: {
    onResize() {
      this.isMobile = getIsMobile()
      // viewContentHeight is for content with no tab swtich (news view)
      this.viewContentHeight = calculateViewContentHeight()
      // contentHeight is for content mobile tab switch
      this.contentHeight = this.isMobile ? calculateTabContentHeigt() : calculateViewContentHeight()
      if (!this.map) {
        return
      }
      var target = this.isMobile ? 'map2' : 'map1'
      // setTarget and updateSize must be called with delay because the tab component (map2) is somehow delayed.
      // Could be a vuetify bug? or Lazy loading?
      var that = this
      setTimeout(function () {
        that.map.setTarget(target)
        that.map.updateSize()
      }, 100)
    },
    updateMapTab() {
      var that = this
      setTimeout(function () {
        if (typeof that.map != 'undefined' && that.map != null) {
          that.map.updateSize()
        }
      }, 100)
    },
  },
}
