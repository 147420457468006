import Vue from 'vue'
import Vuex from 'vuex'
import * as header from '@/store/modules/header.js'
import * as localState from '@/store/modules/localState.js'
import * as region from '@/store/modules/region.js'
import * as news from '@/store/modules/news.js'
import * as ch from '@/store/modules/ch.js'
import * as au from '@/store/modules/au.js'
import * as fr from '@/store/modules/fr.js'
import * as ie from '@/store/modules/ie.js'
import * as iw from '@/store/modules/iw.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    header,
    localState,
    region,
    news,
    ch,
    au,
    fr,
    ie,
    iw,
  },
})
