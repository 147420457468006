import {Control} from 'ol/control';

export class LayerSwitcherControl extends Control {
  constructor(onLayerSwitcher, opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    //button.innerHTML = 'S';

    const element = document.createElement('div');
    element.className = 'proprietary-layer-switcher ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', onLayerSwitcher, false);
  } 
}