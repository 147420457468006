<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.route.elevationDifference`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />

    <path
      :stroke="foregroundColor"
      stroke-miterlimit="10"
      d="M11.74,12.51H4.8"
      stroke-linecap="round"
      stroke-width="1.33px"
      fill="none"
    />
    <path
      :stroke="foregroundColor"
      stroke-miterlimit="10"
      d="M11.74,39.23H4.8"
      stroke-linecap="round"
      stroke-width="1.33px"
      fill="none"
    />
    <line
      :stroke="foregroundColor"
      stroke-miterlimit="10"
      x1="8.3"
      y1="12.51"
      stroke-width="2.3px"
      fill="none"
      x2="8.3"
      y2="39.81"
    />
    <path
      :fill="foregroundColor"
      d="M27.43,13.06a2.38,2.38,0,0,1,3.34-.93,2.62,2.62,0,0,1,.88.93L44.49,36.24a2.64,2.64,0,0,1-.89,3.51,2.3,2.3,0,0,1-1.22.35H16.69a2.51,2.51,0,0,1-2.43-2.58,2.7,2.7,0,0,1,.32-1.28Z"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconElevationGain',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
