<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="cancel" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title>{{ $vuetify.lang.t(`$vuetify.langDialog.chooseLang`) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-6">
        <v-btn-toggle class="d-flex justify-center" v-model="selectedLang" mandatory>
          <v-btn class="text-lowercase" @click="onLanguageChoosen('de')" value="de">DE</v-btn>
          <v-btn class="text-lowercase" @click="onLanguageChoosen('en')" value="en">EN</v-btn>
          <v-btn class="text-lowercase" @click="onLanguageChoosen('fr')" value="fr">FR</v-btn>
          <v-btn class="text-lowercase" @click="onLanguageChoosen('it')" value="it">IT</v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store/store'
import { getRegionCssColor } from '../colors'
import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'lang-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    selectedLang: '',
  }),
  methods: {
    open() {
      this.selectedLang = this.$vuetify.lang.current
      this.dialog = true
    },
    onLanguageChoosen(lang) {
      if (lang == this.selectedLang) {
        this.dialog = false
        return
      }
      this.selectedLang = lang
      this.$vuetify.lang.current = this.selectedLang
      store.dispatch('localState/setLang', {
        lang: this.selectedLang,
      })
      location.reload()
    },
    cancel() {
      this.dialog = false
    },
    getHeaderColor() {
      return getRegionCssColor(1, 'HEADER')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-toolbar .v-btn {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
