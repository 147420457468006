<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'cruxes'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ol>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3`) }}</li>
        </ol>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.4`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
          $vuetify.lang.t(`$vuetify.info.title`)
        }}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'difficulty'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ul>
          <li class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
          <li class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3`) }}</li>
          <li class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.4`) }}</li>
          <li class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.5`) }}</li>
        </ul>
        <div class="pt-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.6`) }}</div>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.8`) }}</div>

        <ol>
          <li class="pb-1">
            <span class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.9`) }}</span>
            <span>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.10`) }}</span>
          </li>
          <li class="pb-0">
            <span class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.11`) }}</span>

            <span>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.12`) }}</span>
            
          </li>
        </ol>
        <div class="pt-2 font-weight-bold" :style="{ color: getColor(2) }">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.13`) }}</div>
        <div class="py-2 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.7`) }}</div>
        <div>
          <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
            $vuetify.lang.t(`$vuetify.info.title`)
          }}</a>
        </div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'homes'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'skiDepots'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</div>
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'travelDistance'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'startElevation'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'elevationGain'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>

           <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'routeType'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text> 

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'riskIndicator'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ol>
          <li>
            <span class="font-weight-bold" :style="{ color: getColor(0) }">{{
              $vuetify.lang.t(`$vuetify.help.${tag}.content.1`)
            }}</span>
            <span>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.4`) }}</span>
          </li>
          <li>
            <span class="font-weight-bold" :style="{ color: getColor(1) }">{{
              $vuetify.lang.t(`$vuetify.help.${tag}.content.2`)
            }}</span>
            <span>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.5`) }}</span>
          </li>
          <li>
            <span class="font-weight-bold" :style="{ color: getColor(2) }">{{
              $vuetify.lang.t(`$vuetify.help.${tag}.content.3`)
            }}</span>
            <span>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.6`) }}</span>
          </li>
        </ol>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.7`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
          $vuetify.lang.t(`$vuetify.info.title`)
        }}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'ratings'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ul>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
        </ul>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'status'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ol>
          <li v-for="n in [1, 2, 3, 4, 5]" :key="n" :value="n">
            <span class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.regionStatus.${n}.medium`) }} ({{ $vuetify.lang.t(`$vuetify.regionStatus.${n}.short`) }})</span>
            <span>: </span>
            <span>{{ $vuetify.lang.t(`$vuetify.regionStatus.${n}.long`) }}</span>
          </li>
        </ol>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'edition'" class="pa-4 text font-weight-normal textDense">
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
          $vuetify.lang.t(`$vuetify.info.title`)
        }}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'ascentTime'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ol>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
        </ol>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'dangerLevel'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ol>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.4`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.5`) }}</li>
        </ol>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.6`) }}</div>
        <a class="pt-2" target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link1`)">{{$vuetify.lang.t(`$vuetify.info.title`)}}</a>        
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.7`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link2`)">{{$vuetify.lang.t(`$vuetify.info.title`)}}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'coreZone'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ol>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
        </ol>
        <div class="pt-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3`) }}</div>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.4`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
          $vuetify.lang.t(`$vuetify.info.title`)
        }}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'snowAmount' || tag == 'snowFilter'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ul v-if="tag == 'snowAmount'">
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3`) }}</li>
        </ul>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.snowAmount.content.4`) }}</div>
        <ul>
          <li>{{ $vuetify.lang.t(`$vuetify.help.snowAmount.content.5`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.snowAmount.content.6`) }}</li>
          <li>{{ $vuetify.lang.t(`$vuetify.help.snowAmount.content.7`) }}</li>
        </ul>
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.help.snowAmount.content.8`) }}</div>
        <v-row class="center mx-0 mb-0 mt-1 font-weight-bold text-center">
          <v-col id="snowStyle1">1</v-col>
          <v-col id="snowStyle20">20</v-col>
          <v-col id="snowStyle40">40</v-col>
          <v-col id="snowStyle60">60</v-col>
          <v-col id="snowStyle80">80</v-col>
        </v-row>
        <v-row class="center mx-0 mb-0 font-weight-bold text-center">
          <v-col id="snowStyle100">100</v-col>
          <v-col id="snowStyle150">150</v-col>
          <v-col id="snowStyle200">200</v-col>
          <v-col id="snowStyle300">300</v-col>
          <v-col id="snowStyle500">500</v-col>
        </v-row>
        <div class="pt-2">
          <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.snowAmount.link`)">{{
            $vuetify.lang.t(`$vuetify.info.title`)
          }}</a>
        </div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'about'" class="pa-4 text font-weight-normal textDense">
        <v-card-title class="px-0 pt-0 pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.subTitle`) }}</v-card-title>
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <v-card id="impressumId" flat class="pa-2 text--secondary">
          <div>
            Skitourenguru GmbH<br />
            Markusstrasse 12<br />
            8006 Zürich<br />
            CHE-286.442.491<br />
            {{ $vuetify.lang.t(`$vuetify.address.jurisdiction`) }}: Zürich, Schweiz<br />
            {{ $vuetify.lang.t(`$vuetify.address.manager`) }}: Günter Schmudlach<br />
            E-Mail: skitourenguru@gmail.com
          </div>
        </v-card>
        <div :style="{ color: getWarningColor() }" class="pt-4 pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.2`) }}</div>
        <ul>
          <li v-for="n in [5, 4, 1, 0, 2, 3, 6]" :key="n" :value="n">
            <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.content.3.${n}.link`)">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.3.${n}.content`) }}</a>
          </li>
         </ul>
        <donation></donation>
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.1`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
          $vuetify.lang.t(`$vuetify.info.title`)
        }}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'wildlife'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <a target="_blank" :href="$vuetify.lang.t(`$vuetify.help.${tag}.link`)">{{
          $vuetify.lang.t(`$vuetify.info.title`)
        }}</a>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'degreeOfFreedom'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'avalancheTerrainWeight'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'movementWeight'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
        <ul>
          <li v-for="n in [1,2]" :key="n" :value="n">
            {{ $vuetify.lang.t(`$vuetify.help.${tag}.content.${n}`) }}
          </li>
        </ul>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'horizontalMovingCost'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'elevationGainCost'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>                        

      <!-- Next Dialog: Name in tag -->
      <v-card-text v-if="tag == 'elevationLossCost'" class="pa-4 text font-weight-normal textDense">
        <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.help.${tag}.content.0`) }}</div>
      </v-card-text>


    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import { getRiskCssColor } from '../colors'
import Donation from './Donation.vue'

export default {
  name: 'help-dialog',
  components: {
    Donation,
  },
  mixins: [utilityMixin],
  data: function () {
    return {
      dialog: false,
      tag: null,
    }
  },
  computed: {
    title: function () {
      if (typeof this.tag == 'undefined' || this.tag == null) {
        return ''
      }
      return this.$vuetify.lang.t(`$vuetify.help.${this.tag}.title`)
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    open(tag) {
      if (typeof tag == 'undefined' || tag == null) {
        return
      }
      this.tag = tag
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    getColor(risk) {
      return getRiskCssColor(risk, 'ICONS')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-left: -16px !important;
  margin-right: -16px !important;
}

#impressumId {
  border: 1.2px solid lightgray;
}

#snowStyle1 {
  background-color: rgba(50, 255, 255, 0.7);
}
#snowStyle20 {
  background-color: rgba(52, 202, 255, 0.7);
}
#snowStyle40 {
  background-color: rgba(51, 139, 255, 0.7);
}
#snowStyle60 {
  background-color: rgba(51, 91, 255, 0.7);
}
#snowStyle80 {
  background-color: rgba(131, 59, 255, 0.7);
}
#snowStyle100 {
  background-color: rgba(187, 123, 255, 0.7);
}
#snowStyle150 {
  background-color: rgba(210, 99, 194, 0.7);
}
#snowStyle200 {
  background-color: rgba(251, 194, 51, 0.7);
}
#snowStyle300 {
  background-color: rgba(243, 50, 50, 0.7);
}
#snowStyle500 {
  background-color: rgba(51, 51, 51, 0.7);
}
</style>
