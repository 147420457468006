import { render, staticRenderFns } from "./IconSummit.vue?vue&type=template&id=461aaa8c&scoped=true&"
import script from "./IconSummit.vue?vue&type=script&lang=js&"
export * from "./IconSummit.vue?vue&type=script&lang=js&"
import style0 from "./IconSummit.vue?vue&type=style&index=0&id=461aaa8c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461aaa8c",
  null
  
)

export default component.exports