import Vue from 'vue'
import Router from 'vue-router'
import RegionsView from './views/RegionsView.vue'
import AreaView from './views/AreaView.vue'
import TrackView from './views/TrackView.vue'
import NewsView from './views/NewsView.vue'
import RatingView from './views/RatingView.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'regions-view',
      component: RegionsView,
    },
    {
      path: '/area-view',
      name: 'area-view',
      component: AreaView,
    },
    {
      path: '/track-view',
      name: 'track-view',
      component: TrackView,
      props: true,
    },
    {
      path: '/news-view',
      name: 'news-view',
      component: NewsView,
    },
    {
      path: '/rating-view',
      name: 'rating-view',
      component: RatingView,
    },    
    {
      // All other routes will be forwarded to the component RegionView
      // https://stackoverflow.com/questions/50961082/vue-js-how-to-redirect-to-a-common-route-if-route-isnt-found
      path: '*',
      //component: RegionsView,
      redirect: '/',
    },
  ],
})
