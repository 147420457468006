<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="headerColor" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pl-4 py-5 textDense">{{ message }}</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'message-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    title: '',
    message: '',
    headerColor: null
  }),
  methods: {
    open(type, message) {
        this.title = this.$vuetify.lang.t(`$vuetify.message.type.${type}`)
        switch (type) {
          case 0:
            this.headerColor = this.getHeaderColor()
            break
           case 1:
            this.headerColor = this.getOrangeColor()
            break
           case 2:
            this.headerColor = this.getWarningColor()
            break   
        }            
      this.message = message
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-btn {
    background-color: var(--navigationColor);
    color: white !important;
    border-radius: 0%;
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
</style>
