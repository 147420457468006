import { render, staticRenderFns } from "./IconNews.vue?vue&type=template&id=7f878a80&scoped=true&"
import script from "./IconNews.vue?vue&type=script&lang=js&"
export * from "./IconNews.vue?vue&type=script&lang=js&"
import style0 from "./IconNews.vue?vue&type=style&index=0&id=7f878a80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f878a80",
  null
  
)

export default component.exports