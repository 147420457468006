import DataService from '@/services/DataService'

export const namespaced = true

export const state = {
  items: [],
  readIds: [],
}

export const mutations = {
  SET_NEWS(state, data) {
    state.items = data
  },

  SET_READ_ID(state, id) {
    if (!state.readIds.includes(id)) {
      state.readIds.push(id)
    }
  },

  INIT_READ_IDS(state) {
    state.readIds = JSON.parse(localStorage.getItem('readNewsIds')) || []
  },
}

export const actions = {
  fetchNews({ commit }) {
    return DataService.getNews()
      .then((response) => {
        commit('SET_NEWS', response.data)
        return response.data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('There was an error:', error)
      })
  },

  setNewsRead({ commit }, id) {
    commit('SET_READ_ID', id)
  },
}

export const getters = {
  getNews: (state) => {
    return state.news
  },

  getReadIds: (state) => {
    return state.readIds
  },
}
