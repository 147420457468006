<template>
  <v-card tile v-resize="onResize" class="ma-0 overflow-y-auto" :style="{ height: contentHeight + 'px' }">
    <v-container>
      <v-expansion-panels flat accordion v-model="openedSearchPanelIndex">
        <v-expansion-panel>
          <v-expansion-panel-header
            style="min-height: unset;"
            :color="panelColor"
            class="subtitle-1 py-1 px-2"
            expand-icon="mdi-menu-down"
            >{{ this.$vuetify.lang.t(`$vuetify.filterTable.search`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content :color="panelColor">
            <v-text-field
              spellcheck="false"
              prepend-inner-icon="mdi-magnify"
              :value="keyword"
              @input="updateSearchKeyword"
              clear-icon="mdi-close-circle"
              clearable
              hide-details="true"
            >
            </v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="center my-5">
        <v-divider></v-divider>
      </v-row>
      <v-expansion-panels flat accordion v-model="openedFilterPanelIndex">
        <v-expansion-panel id="filterExpansionPanel">
          <v-expansion-panel-header
            style="min-height: unset;"
            :color="panelColor"
            class="subtitle-1 py-1 px-2"
            expand-icon="mdi-menu-down"
            >{{ this.$vuetify.lang.t(`$vuetify.filterTable.filter`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content :color="panelColor">
            <v-row class="center mx-1 my-1">
              <v-col @click="displayHelp('homes')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconHome</v-icon>
              </v-col>
              <v-col class="col-10 pl-4">
                <v-row class="center">
                  <div class="body-2 font-weight-regular text--secondary">{{ homeLabel }}</div>
                </v-row>
                <v-row class="center">
                  <v-autocomplete
                    spellcheck="false"
                    :items="homesList"
                    v-model="home"
                    dense
                    hide-details="true"
                  ></v-autocomplete>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="center mx-1">
              <v-col @click="displayHelp('travelDistance')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconTravelDistance</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-4">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary">
                    {{ this.$vuetify.lang.t(`$vuetify.route.travelDistance`) }}:
                    {{ getSliderText(distRange, defaultFilters.distRange, 'km') }}
                  </div>
                </v-row>
                <v-row class="center">
                  <v-range-slider
                    :min="defaultFilters.distRange[0]"
                    :max="defaultFilters.distRange[1]"
                    step="10"
                    :ticks="tickControl"
                    :tick-size="tickSize"
                    v-model="distRange"
                    @end="filterTracks(true)"
                    @click="filterTracks(true)"
                    hide-details="true"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="center mx-1">
              <v-col @click="displayHelp('startElevation')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconStartElevation</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-4">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary">
                    {{ this.$vuetify.lang.t(`$vuetify.route.startElevation`) }}:
                    {{ getSliderText(startHeightRange, defaultFilters.startHeightRange, 'm') }}
                  </div>
                </v-row>
                <v-row class="center">
                  <v-range-slider
                    :min="defaultFilters.startHeightRange[0]"
                    :max="defaultFilters.startHeightRange[1]"
                    step="100"
                    :ticks="tickControl"
                    :tick-size="tickSize"
                    v-model="startHeightRange"
                    @end="filterTracks(true)"
                    @click="filterTracks(true)"
                    hide-details="true"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="center mx-1">
              <v-col @click="displayHelp('elevationGain')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconElevationGain</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-4">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary">
                    {{ this.$vuetify.lang.t(`$vuetify.route.elevationDifference`) }}:
                    {{
                      getSliderText(
                        heightDiffRange,
                        defaultFilters.heightDiffRange,
                        this.$vuetify.lang.t(`$vuetify.route.elevationGain`)
                      )
                    }}
                  </div>
                </v-row>
                <v-row class="center">
                  <v-range-slider
                    :min="defaultFilters.heightDiffRange[0]"
                    :max="defaultFilters.heightDiffRange[1]"
                    step="100"
                    :ticks="tickControl"
                    :tick-size="tickSize"
                    v-model="heightDiffRange"
                    @end="filterTracks(true)"
                    @click="filterTracks(true)"
                    hide-details="true"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="center mx-1">
              <v-col @click="displayHelp('difficulty')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconDifficulty</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-4">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary">
                    {{ this.$vuetify.lang.t(`$vuetify.route.difficulty`) }}:
                    {{
                      this.getDifficulty(difficultiesRange[0]) +
                      ' ' +
                      this.$vuetify.lang.t(`$vuetify.filterTable.to`) +
                      ' ' +
                      this.getDifficulty(difficultiesRange[1])
                    }}
                  </div>
                </v-row>
                <v-row class="center">
                  <v-range-slider
                    :min="defaultFilters.difficultiesRange[0]"
                    :max="defaultFilters.difficultiesRange[1]"
                    step="1"
                    :ticks="tickControl"
                    :tick-size="tickSize"
                    v-model="difficultiesRange"
                    @end="filterTracks(true)"
                    @click="filterTracks(true)"
                    hide-details="true"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="center mx-1">
              <v-col @click="displayHelp('snowFilter')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconSnowAmount</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-4">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary">
                    {{ this.$vuetify.lang.t(`$vuetify.route.snowFilter`) }}:
                    {{ getSliderText(snowRange, defaultFilters.snowRange, 'cm') }}
                  </div>
                </v-row>
                <v-row class="center">
                  <v-range-slider
                    :min="defaultFilters.snowRange[0]"
                    :max="defaultFilters.snowRange[1]"
                    step="5"
                    :ticks="tickControl"
                    :tick-size="tickSize"
                    v-model="snowRange"
                    @end="filterTracks(true)"
                    @click="filterTracks(true)"
                    hide-details="true"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="center mx-1">
              <v-col @click="displayHelp('riskIndicator')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconTrafficLight</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-4">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary">
                    {{ this.$vuetify.lang.t(`$vuetify.route.riskIndicator`) }}:
                    {{ riskRange[0] }}&#x200B;&#x2013;&#x200B;{{ riskRange[1] }}
                  </div>
                </v-row>
                <v-row id="riskSlider" class="center">
                  <v-range-slider
                    :min="defaultFilters.riskRange[0]"
                    :max="defaultFilters.riskRange[1]"
                    step="0.1"
                    :ticks="tickControl"
                    :tick-labels="riskTickLabels"
                    :tick-size="tickSize"
                    v-model="riskRange"
                    @end="filterTracks(true)"
                    @click="filterTracks(true)"
                    hide-details="true"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="center mx-1 pt-3 pb-4">
              <v-col @click="displayHelp('routeType')" class="col-2 my-auto fingerPointer">
                <v-icon class="route-icon pl-1" size="32px">mdi-routes</v-icon>
              </v-col>
              <v-col class="col-10 py-0">   
                <v-row>           
                  <v-checkbox
                    hide-details        
                    :label="this.$vuetify.lang.t(`$vuetify.route.type[0]`)"
                    v-model="routeType[0]"
                    @click="filterTracks(true)"
                  >
                  </v-checkbox>
                </v-row>   
                <v-row>           
                  <v-checkbox
                    hide-details        
                    :label="this.$vuetify.lang.t(`$vuetify.route.type[1]`)"
                    v-model="routeType[1]"
                    @click="filterTracks(true)"
                  >
                  </v-checkbox>
                </v-row>   
                <v-row>           
                  <v-checkbox
                    hide-details        
                    :label="this.$vuetify.lang.t(`$vuetify.route.type[2]`)"
                    v-model="routeType[2]"
                    @click="filterTracks(true)"
                  >
                  </v-checkbox>
                </v-row>                                   
              </v-col>  
            </v-row>
            <v-row class="align-end text-right my-0">
              <v-col class="pt-3 pb-0 pr-0">
                <v-btn class="text-capitalize" color="primary" text @click="resetFilters(true)">{{
                  this.$vuetify.lang.t(`$vuetify.route.resetFilters`)
                }}</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="center my-5">
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <div class="pl-4 py-0 justify-left subtitle-1">
          <span class="preserveWhitespace">{{ $vuetify.lang.t(`$vuetify.filterTable.results`) + ' ' }}</span>
          <span :style="{ color: getWarningColor() }" class="font-weight-bold preserveWhitespace">{{
            filteredSkiToursList.length + ' '
          }}</span>
          <span class="font-weight-bold preserveWhitespace">{{
            $vuetify.lang.t(`$vuetify.filterTable.of`) + ' '
          }}</span>
          <span class="font-weight-bold preserveWhitespace">{{ skiToursList.length + ' ' }}</span>
          <span class="preserveWhitespace">{{ $vuetify.lang.t(`$vuetify.filterTable.routes`) + ':' }}</span>
        </div>
      </v-row>
      <v-row>
        <v-data-table
          calculate-widths
          width="100%"
          hide-default-header
          :headers="headers"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :items="filteredSkiToursList"
          :mobile-breakpoint="0"
          :items-per-page="20"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
          dense
          class="mx-3"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  width="40%"
                  @click="sortByCol(headers[0].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-left sortable pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconSummit</v-icon>
                  <v-icon v-if="sortBy == headers[0].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[0].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
                <th
                  width="10%"
                  @click="sortByCol(headers[1].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-left sortable pl-0 pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconTravelDistance</v-icon>
                  <v-icon v-if="sortBy == headers[1].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[1].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
                <th
                  width="10%"
                  @click="sortByCol(headers[2].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-left sortable pl-0 pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconStartElevation</v-icon>
                  <v-icon v-if="sortBy == headers[2].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[2].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
                <th
                  width="10%"
                  @click="sortByCol(headers[3].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-left sortable pl-0 pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconElevationGain</v-icon>
                  <v-icon v-if="sortBy == headers[3].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[3].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
                <th
                  width="10%"
                  @click="sortByCol(headers[4].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-left sortable pl-0 pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconDifficulty</v-icon>
                  <v-icon v-if="sortBy == headers[4].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[4].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
                <th
                  width="10%"
                  @click="sortByCol(headers[5].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-left sortable pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconSnowAmount</v-icon>
                  <v-icon v-if="sortBy == headers[5].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[5].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
                <th
                  width="10%"
                  @click="sortByCol(headers[6].value)"
                  role="columnheader"
                  scope="col"
                  aria-sort="none"
                  class="text-right sortable risk-icon pr-2 pb-2"
                >
                  <v-icon :size="defaultIconSize">$iconTrafficLight</v-icon>
                  <v-icon v-if="sortBy == headers[6].value && sortDesc" :size="sortIconSize"
                    >mdi-arrow-down-bold</v-icon
                  >
                  <v-icon v-if="sortBy == headers[6].value && !sortDesc" :size="sortIconSize">mdi-arrow-up-bold</v-icon>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:[`item.stop`]="{ item }">
            <a
              style="display: block;"
              :href="`track-view?area=${area}&id=${item.id}`"
              @click.prevent="gotoTrack(item)"
              >{{ item.stop }}</a
            >
          </template>
          <template v-slot:[`item.distance`]="{ item }">
            <a @click="displayHelp('travelDistance')">{{ item.distance }}</a>
          </template>
          <template v-slot:[`item.startEle`]="{ item }">
            {{ item.startEle }}
          </template>
          <template v-slot:[`item.ele`]="{ item }">
            {{ item.ele }}
          </template>
          <template v-slot:[`item.diff`]="{ item }">
            <a @click="displayHelp('difficulty')">{{ getDifficulty(item.diff) }}</a>
          </template>
          <template v-slot:[`item.risk`]="{ item }">
            <v-chip @click="displayHelp('riskIndicator')" class="px-1" :color="getColor(item.risk)" light>{{
              item.risk.toFixed(2)
            }}</v-chip>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <partners></partners>
  </v-card>
</template>

<script>
import store from '@/store/store'
import { resizeMixin } from '../mixins/resizeMixin'
import { utilityMixin } from '../mixins/utilityMixin'
import DataService from '@/services/DataService'
import { collections } from '../variables'
import { getRiskCssColor } from '../colors'
import { defaultHomes } from '../variables'
import Partners from './Partners.vue'
import { getIsMobile } from '../utilities'
import _ from 'lodash'
import Fuse from 'fuse.js'
import { constDefaultFilters } from '../utilities'


export default {
  name: 'filter-table',
  mixins: [resizeMixin, utilityMixin],
  components: {
    Partners,
  },
  props: {
    homeProp: {
      type: String,
      required: true,
    },
    homes: {
      type: Object,
      required: true,
    },
    skiToursList: {
      type: Array,
      required: true,
    },
    area: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      home: '',
      homeUic: 0,
      homePoint: null,
      keyword: '',

      distRange: constDefaultFilters.distRange.slice(),
      startHeightRange: constDefaultFilters.startHeightRange.slice(),
      heightDiffRange: constDefaultFilters.heightDiffRange.slice(),
      difficultiesRange: constDefaultFilters.difficultiesRange.slice(),
      snowRange: constDefaultFilters.snowRange.slice(),
      riskRange: constDefaultFilters.riskRange.slice(),
      routeType: constDefaultFilters.routeType.slice(),

      distances: null,
      filteredSkiToursList: [],
      headers: [
        { text: 'Name', align: 'left', value: 'stop', width: '100px' },
        { text: 'Distance', align: 'left', value: 'distance' },
        { text: 'Start elevation', align: 'left', value: 'startEle' },
        { text: 'Elevation', align: 'left', value: 'ele' },
        { text: 'Difficulty', align: 'left', value: 'diff' },
        { text: 'Snow', align: 'left', value: 'snow' },
        { text: 'Rsik', align: 'right', value: 'risk' },
      ],
      sortBy: 'risk',
      sortDesc: false,
      openedSearchPanelIndex: getIsMobile() ? 1 : 0,
      openedFilterPanelIndex: getIsMobile() ? 1 : 0,
      panelColor: 'rgba(0, 0, 0, 0)',
      tickControl: true, // true, false, 'always'
      tickSize: 5,
      defaultFilters: {...constDefaultFilters},
      riskTickLabels: [
        '',
        '',
        '',
        '',
        '',
        this.$vuetify.lang.t(`$vuetify.intro.guru.table.content.riskQualifiers.0`),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        this.$vuetify.lang.t(`$vuetify.intro.guru.table.content.riskQualifiers.1`),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        this.$vuetify.lang.t(`$vuetify.intro.guru.table.content.riskQualifiers.2`),
        '',
        '',
        '',
        '',
        '',
      ],
      defaultIconSize: '36px',
      sortIconSize: '12px',
    }
  },
  watch: {
    homeProp: function () {
      this.home = this.homeProp
    },
    home: function () {
      var homeFeature = this.homes.features.find((feature) => feature.properties.Name.toLocaleUpperCase() === this.home.toLocaleUpperCase())
      this.homeUic = homeFeature.properties.UIC
      this.homePoint = homeFeature.geometry.coordinates

      let changeDistances = (distances, skiToursList) => {
        for (let i = 0; i < skiToursList.length; i++) {
          var index = distances.ids.indexOf(skiToursList[i].id)
          skiToursList[i].distance = distances.dist[index]
        }
      }
      var that = this
      DataService.getDistance(collections[this.area], this.homeUic).then((response) => {
        changeDistances(response.data, that.skiToursList)
        that.filterTracks()
      })
      this.saveFilters()
    },
    distRange: function () {
      this.saveFilters()
    },
    startHeightRange: function () {
      this.saveFilters()
    },
    heightDiffRange: function () {
      this.saveFilters()
    },
    difficultiesRange: function () {
      this.saveFilters()
    },
    snowRange: function () {
      this.saveFilters()
    },
    riskRange: function () {
      this.saveFilters()
    },
    routeType: function () {
      this.saveFilters()
    },    

  },
  computed: {
    homesList: function () {
      return this.homes.features.map((item) => item.properties.Name)
    },
    homeLabel: function () {
      return this.$vuetify.lang.t(`$vuetify.home`)
    },
  },
  created: function () {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedFilterTracks = _.debounce(this.searchTracks, 500)
  },
  mounted() {
    this.home = this.homeProp || this.$store.state[this.area].filterPreferences.home || defaultHomes[this.area]
    this.distRange = this.$store.state[this.area].filterPreferences.distRange || constDefaultFilters.distRange.slice()
    this.startHeightRange = this.$store.state[this.area].filterPreferences.startHeightRange || constDefaultFilters.startHeightRange.slice()
    this.heightDiffRange = this.$store.state[this.area].filterPreferences.heightDiffRange || constDefaultFilters.heightDiffRange.slice()
    this.difficultiesRange = this.$store.state[this.area].filterPreferences.difficultiesRange || constDefaultFilters.difficultiesRange.slice()
    this.snowRange = this.$store.state[this.area].filterPreferences.snowRange || constDefaultFilters.snowRange.slice()
    this.riskRange = this.$store.state[this.area].filterPreferences.riskRange || constDefaultFilters.riskRange.slice()
    this.routeType = this.$store.state[this.area].filterPreferences.routeType || constDefaultFilters.routeType.slice()
    this.homeUic = this.$store.state[this.area].filterPreferences.homeUic
    this.homePoint = this.$store.state[this.area].filterPreferences.homePoint
    this.keyword = this.$store.state[this.area].filterPreferences.keyword
    //this.filterTracks()
  },
  methods: {
    sortByCol(col) {
      if (col === this.sortBy) {
        this.sortDesc = !this.sortDesc
        return
      }
      this.sortBy = col
      this.sortDesc = false
    },
    getColor(risk) {
      return getRiskCssColor(risk, 'EYES')
    },
    getIconColor() {
      return '#808080'
    },
    getDifficulty(diffValue) {
      return this.getDifficultyForArea(diffValue, this.area)
    },
    gotoTrack(item) {
      this.dispose()
      this.$router.push({
        name: 'track-view',
        query: { area: this.area, id: item.id },
      })
    },

    updateSearchKeyword(keyword) {
      this.keyword = keyword
      this.saveFilters()
      this.debouncedFilterTracks()
    },

    resetFilters(isButton) {
      this.distRange = constDefaultFilters.distRange.slice()
      this.startHeightRange = constDefaultFilters.startHeightRange.slice()
      this.heightDiffRange = constDefaultFilters.heightDiffRange.slice()
      this.difficultiesRange = constDefaultFilters.difficultiesRange.slice()
      this.snowRange = constDefaultFilters.snowRange.slice()
      this.riskRange = constDefaultFilters.riskRange.slice()
      this.routeType = constDefaultFilters.routeType.slice()

      if (isButton) {
        //this.home = defaultHomes[this.area]
        this.filterTracks(false)
      }
    },

    searchTracks() {
      this.filterTracks(false)
    },

    isCurrentlyFiltered() {
      if (this.distRange[0] != this.defaultFilters.distRange[0]) return true
      if (this.distRange[1] != this.defaultFilters.distRange[1]) return true

      if (this.startHeightRange[0] != this.defaultFilters.startHeightRange[0]) return true
      if (this.startHeightRange[1] != this.defaultFilters.startHeightRange[1]) return true

      if (this.heightDiffRange[0] != this.defaultFilters.heightDiffRange[0]) return true
      if (this.heightDiffRange[1] != this.defaultFilters.heightDiffRange[1]) return true

      if (this.difficultiesRange[0] != this.defaultFilters.difficultiesRange[0]) return true
      if (this.difficultiesRange[1] != this.defaultFilters.difficultiesRange[1]) return true

      if (this.snowRange[0] != this.defaultFilters.snowRange[0]) return true
      if (this.snowRange[1] != this.defaultFilters.snowRange[1]) return true

      if (this.riskRange[0] != this.defaultFilters.riskRange[0]) return true
      if (this.riskRange[1] != this.defaultFilters.riskRange[1]) return true

      if (this.routeType[0] != this.defaultFilters.routeType[0]) return true
      if (this.routeType[1] != this.defaultFilters.routeType[1]) return true
      if (this.routeType[2] != this.defaultFilters.routeType[2]) return true

      return false
    },

    filterTracks(wantToFilter) {
      if (typeof wantToFilter != 'undefined' && wantToFilter != null) {
        // The user made changes to either keyword or filters
        if (wantToFilter) {
          // User wants to filter: Reset keyword
          if (this.keyword != '') {
            this.keyword = ''
            this.saveFilters()
          }
        } else {
          // User wants to search for keyword: Reset filters
          this.resetFilters(false)
        }
      }

      const that = this
      this.filteredSkiToursList = this.skiToursList

      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) =>
          i.distance >= that.distRange[0] &&
          (that.distRange[1] == that.defaultFilters.distRange[1] || i.distance < that.distRange[1])
      )
      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) =>
          i.startEle >= that.startHeightRange[0] &&
          (that.startHeightRange[1] == that.defaultFilters.startHeightRange[1] || i.startEle < that.startHeightRange[1])
      )
      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) =>
          i.ele >= that.heightDiffRange[0] &&
          (that.heightDiffRange[1] == that.defaultFilters.heightDiffRange[1] || i.ele < that.heightDiffRange[1])
      )
      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) =>
          i.risk >= that.riskRange[0] &&
          (that.riskRange[1] == that.defaultFilters.riskRange[1] || i.risk < that.riskRange[1])
      )
      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) =>
          i.diff >= that.difficultiesRange[0] &&
          (that.difficultiesRange[1] == that.defaultFilters.difficultiesRange[1] || i.diff < that.difficultiesRange[1])
      )
      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) =>
          i.snow >= that.snowRange[0] &&
          (that.snowRange[1] == that.defaultFilters.snowRange[1] || i.snow < that.snowRange[1])
      )
      this.filteredSkiToursList = this.filteredSkiToursList.filter(
        (i) => (that.routeType[i.rt])
      )

      if (this.keyword) {
        const options = {
          threshold: 0.3,
          minMatchCharLength: 3,
          findAllMatches: false,
          shouldSort: true,
          useExtendedSearch: true,
          // location: 4,
          keys: ['stop']
        }
        const fuse = new Fuse(this.skiToursList, options)
        const result = fuse.search(this.keyword)
        this.filteredSkiToursList = result.map((i) => i.item )

        // Check, if there are perfect matches
        var keyword = this.keyword.toLowerCase()
        var perfectMatchSkiToursList = this.filteredSkiToursList.filter((i) => i.stop.toLowerCase() == keyword)
        if (perfectMatchSkiToursList.length > 0) {
          this.filteredSkiToursList = perfectMatchSkiToursList
        }
      }
      const idList = this.filteredSkiToursList.map((item) => item.id)
      this.$emit('routesFiltered', idList, this.homeUic)
    },
    saveFilters() {
      store.dispatch(`${this.area}/setFilterPreferences`, {
        home: this.home,
        homeUic: this.homeUic,
        homePoint: this.homePoint,
        distRange: this.distRange,
        startHeightRange: this.startHeightRange,
        heightDiffRange: this.heightDiffRange,
        difficultiesRange: this.difficultiesRange,
        snowRange: this.snowRange,
        riskRange: this.riskRange,
        routeType: this.routeType,
        keyword: this.keyword,
      })
    },
    getSliderText(range, defaultRange, unit) {
      if (range[1] == defaultRange[1] && range[0] == defaultRange[0]) {
        return this.$vuetify.lang.t(`$vuetify.route.all`)
      }
      var text = `${range[0]}`
      text += '\u200B\u2013\u200B'
      text += `${range[1]}\u2006${unit}`
      return text
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('FilterTable: Display help')
      this.$emit('displayHelp', tag)
    },
    dispose() {
      this.$emit('dispose')
      this.distances = null
      this.filteredSkiToursList = []     
    }
  },
}
</script>

<style scoped>

.route-icon {
  color: var(--navigationColor);
}

.v-slider__thumb {
  width: 16px;
  height: 16px;
}

.v-slider--horizontal .v-slider__track-container {
  height: 4px;
}

.v-data-table-header th {
  white-space: nowrap;
}

.v-data-table >>> td:not(:last-child),
.v-data-table >>> th:not(:last-child) {
  /* top right bottom left*/
  padding: 0px 0px 0px 4px !important;
}

.v-data-table >>> td:last-child,
.v-data-table >>> th:last-child {
  padding: 0px 8px 0px 0px !important;
}

.v-data-table >>> .risk-icon {
  padding-right: 20px;
}

.v-expansion-panels {
  border: 1px solid lightgray;
}

.v-btn {
  /* font-weight: bold; */
  min-width: unset !important;
}

.v-btn:hover {
  background-color: unset;
  color: #5e819666 !important;
}

.v-text-field--is-booted {
  padding-top: 0px !important;
}

.v-icon:focus {
  display: none;
}

.preserveWhitespace {
  white-space: pre;
}
</style>
