<template>
  <v-app>
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>

<script>
import store from '@/store/store'
import { utilityMixin } from '@/mixins/utilityMixin'

export default {
  name: 'App',
  mixins: [utilityMixin],
  data: () => ({
    //
  }),
  beforeCreate() {
    // Loads the data from localStorage to the state
    store.commit('ch/INIT_FILTER_PREFERENCES', null, { root: true })
    store.commit('au/INIT_FILTER_PREFERENCES', null, { root: true })
    store.commit('fr/INIT_FILTER_PREFERENCES', null, { root: true })
    store.commit('ie/INIT_FILTER_PREFERENCES', null, { root: true })
    store.commit('iw/INIT_FILTER_PREFERENCES', null, { root: true })

    store.commit('ch/INIT_DISCLAIMER', null, { root: true })
    store.commit('au/INIT_DISCLAIMER', null, { root: true })
    store.commit('fr/INIT_DISCLAIMER', null, { root: true })
    store.commit('ie/INIT_DISCLAIMER', null, { root: true })
    store.commit('iw/INIT_DISCLAIMER', null, { root: true })

    store.commit('ch/INIT_OVERLAYS', null, { root: true })
    store.commit('au/INIT_OVERLAYS', null, { root: true })
    store.commit('fr/INIT_OVERLAYS', null, { root: true })
    store.commit('ie/INIT_OVERLAYS', null, { root: true })
    store.commit('iw/INIT_OVERLAYS', null, { root: true })

    store.commit('localState/INIT_LANG', null, { root: true })
    store.commit('localState/INIT_GUID', null, { root: true })
    store.commit('news/INIT_READ_IDS', null, { root: true })

    // Registers an event handler for each time somebody writes data into state
    // -> In such a case the state will always be saved to the localStorage
    store.subscribe((mutation, state) => {
      mutation.type.startsWith('ch/SET_FILTER_PREFERENCES') &&
        localStorage.setItem('ch', JSON.stringify(state.ch.filterPreferences))
      mutation.type.startsWith('au/SET_FILTER_PREFERENCES') &&
        localStorage.setItem('au', JSON.stringify(state.au.filterPreferences))
      mutation.type.startsWith('fr/SET_FILTER_PREFERENCES') &&
        localStorage.setItem('fr', JSON.stringify(state.fr.filterPreferences))
      mutation.type.startsWith('ie/SET_FILTER_PREFERENCES') &&
        localStorage.setItem('ie', JSON.stringify(state.ie.filterPreferences))
      mutation.type.startsWith('iw/SET_FILTER_PREFERENCES') &&
        localStorage.setItem('iw', JSON.stringify(state.iw.filterPreferences))

      mutation.type.startsWith('ch/SET_DISCLAIMER') &&
        localStorage.setItem('ch_disclaimer', JSON.stringify(state.ch.disclaimer))
      mutation.type.startsWith('au/SET_DISCLAIMER') &&
        localStorage.setItem('au_disclaimer', JSON.stringify(state.au.disclaimer))
      mutation.type.startsWith('fr/SET_DISCLAIMER') &&
        localStorage.setItem('fr_disclaimer', JSON.stringify(state.fr.disclaimer))
      mutation.type.startsWith('ie/SET_DISCLAIMER') &&
        localStorage.setItem('ie_disclaimer', JSON.stringify(state.ie.disclaimer))
      mutation.type.startsWith('iw/SET_DISCLAIMER') &&
        localStorage.setItem('iw_disclaimer', JSON.stringify(state.iw.disclaimer))

      mutation.type.startsWith('ch/SET_OVERLAYS') &&
        localStorage.setItem('ch_overlays', JSON.stringify(state.ch.overlays))
      mutation.type.startsWith('au/SET_OVERLAYS') &&
        localStorage.setItem('au_overlays', JSON.stringify(state.au.overlays))
      mutation.type.startsWith('fr/SET_OVERLAYS') &&
        localStorage.setItem('fr_overlays', JSON.stringify(state.fr.overlays))
      mutation.type.startsWith('ie/SET_OVERLAYS') &&
        localStorage.setItem('ie_overlays', JSON.stringify(state.ie.overlays))
      mutation.type.startsWith('iw/SET_OVERLAYS') &&
        localStorage.setItem('iw_overlays', JSON.stringify(state.iw.overlays))

      mutation.type.startsWith('localState/SET_LANG') && localStorage.setItem('lang', state.localState.lang)
      mutation.type.startsWith('localState/SET_GUID') && localStorage.setItem('guid', state.localState.guid)
      mutation.type.startsWith('news/SET_READ_ID') &&
        localStorage.setItem('readNewsIds', JSON.stringify(state.news.readIds))
    })
  },
  created() {
    this.chooseLanguage()
  },
}
</script>
