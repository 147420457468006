<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.help.travelDistance.title`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      fill="none"
      :stroke="foregroundColor"
      stroke-width="2px"
      stroke-miterlimit="10"
      d="M28.33,19.92s-8.84,1.14-9.62,5.17c-1,5.09,12,7.2,10,12.2-1.66,4.12-18,1.13-18,1.13"
    />
    <path
      :fill="foregroundColor"
      :stroke="backgroundColor"
      stroke-width="1.67px"
      stroke-miterlimit="10"
      d="M10.15,22.42A6.17,6.17,0,0,0,4,28.56c0,5.29,5.66,11.05,5.9,11.29a.33.33,0,0,0,.47,0h0c.24-.24,5.9-6,5.9-11.29a6.13,6.13,0,0,0-6.13-6.14Zm0,9.24a3.1,3.1,0,1,1,3.11-3.1h0A3.12,3.12,0,0,1,10.15,31.66Z"
    />
    <path
      :fill="foregroundColor"
      d="M10.15,22.42A6.17,6.17,0,0,0,4,28.56c0,5.29,5.66,11.05,5.9,11.29a.33.33,0,0,0,.47,0h0c.24-.24,5.9-6,5.9-11.29a6.13,6.13,0,0,0-6.13-6.14Zm0,9.24a3.1,3.1,0,1,1,3.11-3.1h0A3.11,3.11,0,0,1,10.15,31.66Z"
    />
    <path
      :fill="foregroundColor"
      :stroke="backgroundColor"
      stroke-width="0.83px"
      stroke-miterlimit="10"
      d="M34.11,7.41a1.89,1.89,0,0,1,3.3,0l6.23,11.22A1.89,1.89,0,0,1,42,21.43H29.53a1.89,1.89,0,0,1-1.89-1.88,1.83,1.83,0,0,1,.24-.92Z"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconTravelDistance',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
