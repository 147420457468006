<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img width="100%" :src="url"></v-img>
      <v-card-text class="pl-2 py-1 textDense">Webcams collected by www.windy.com/webcams</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'webcam-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    title: null,
    url: null,
  }),
  methods: {
    open(id, name, ele) {
      var shortName = name
      var index = name.indexOf(':')
      if (index > 0) {
        shortName = name.substr(index + 1, name.length - index - 1)
      }

      this.title = `${shortName} (${ele} m)`
      var idTail = id.substr(id.length - 2, 2)
      this.url = `https://images-webcams.windy.com/${idTail}/${id}/current/preview/${id}.jpg`
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
