import { getInfoServerLink } from '../utilities'

export default {
  yes: 'Oui',
  no: 'Non',
  ok: 'OK',
  cancel: 'Annuler',
  information: 'Information',
  home: 'Résidence',
  header: 'Skitourenguru',
  news: 'News',
  attention: 'Attention',
  filterTable: {
    search: 'Chercher nom du sommet',
    filter: 'Filtrer les itinéraires',
    to: "jusqu'à",
    of: 'de',
    results: 'Résultat:',
    routes: 'itinéraires',
  },
  regions: {
    ch: 'Suisse',
    au: 'Alpes orientales',
    fr: 'France',
    ie: 'Italie nord-est',
    iw: 'Italie nord-ouest',
  },
  tabs: {
    map: 'Carte',
    regionsList: 'Introduction',
    trackDetails: 'Itinéraire',
    trackSearch: 'Chercher',
  },
  menu: {
    info: 'Background',
    language: 'de en fr it',
    manual: 'Manuel',
    video: 'Vidéos de formation',
    news: 'News',
    media: 'Media',
    prevention: 'Prévention accidents (BPA)',
    about: 'About',
    drawRoute: 'Dessiner des itinéraires'
  },
  intro: {
    summer: {
      title: "Service d'été",
      content:
        "Les bulletins d'avalanche sont généralement disponibles entre novembre et mai. Pour les régions sans bulletins d'avalanche, une version de démonstration (Off) est affichée. Attention : Les résultats dans ces régions ne sont pas valables!",
    },
    guru: {
      title: "C'est quoi, Skitourenguru",
      content:
        "Skitourenguru vous aide à choisir et à planifier une randonnée à ski adaptée et présentant un faible risque d'avalanche. À cette fin, Skitourenguru attribue chaque jour un niveau de risque d'avalanche à des milliers de randonnées à ski dans la région alpine:",
      table: {
        header: {
          symbol: 'Symbole',
          values: 'Valeurs',
          risks: "Risque d'avalanches",
        },
        content: {
          values: {
            0: '0\u200B\u2013\u200B1',
            1: '1\u200B\u2013\u200B2',
            2: '2\u200B\u2013\u200B3',
          },
          risks: {
            0: "Risque d'avalanches modéré",
            1: "Risque d'avalanches accru",
            2: "Risque d'avalanches élevé",
          },
          riskQualifiers: {
            0: 'modéré',
            1: 'accru',
            2: 'élevé',
          },
        },
      },
    },
    cruxes: {
      content: 'En outre, Skitourenguru indique les passages clés par des anneaux gris:',
      table: {
        header: {
          symbol: 'Symbole',
          values: 'Classe',
          risks: 'Signification',
        },
        content: {
          values: {
            0: '1',
            1: '2',
            2: '3',
          },
          classes: {
            0: 'Terrain avalancheux',
            1: 'Terrain avalancheux typique',
            2: 'Terrain avalancheux très typique',
          },
        },
      },
    },
    limits: {
      1: 'Sur place et dans la pente individuelle, on accède généralement à des informations qui ne sont pas disponible au Skitourenguru. ',   
      2: 'Les informations présentées sur Skitourenguru sont sujettes à des incertitudes (voir ',
      3: {
        text: 'Manuel',
        link: getInfoServerLink() + '/download/intro/Intro_FR.pdf',
      },
      4: '). ',
      5: 'Par conséquent, Skitourenguru ne doit pas être le seul critère pour s’engager dans une pente.'
    },
    choose: {
      title: 'Choisissez une région',
      table: {
        header: {
          region: 'Region',
          status: 'État',
          edition: 'Édition',
          valid: 'Valide',
        },
      },
    },
    supported: {
      title: 'Partenaires',
      content: 'Skitourenguru est soutenu, entre autres, par les partenaires suivants.',
      partners: {
        bfu: {
          name: 'BPA',
          link: 'https://www.bfu.ch/fr/conseils/ski-de-randonnee',
        },
        sac: {
          name: 'SAC',
          link: 'https://www.sac-cas.ch/fr/formation-et-securite/securite-en-chemin/securite-en-randonnee-a-skis/'
        },
        colltex: {
          name: 'colltex',
          link: 'https://www.colltex.ch/'
        }  
      }
    },
  },
  difficultyScales: {
    0: '/',
    1: 'F/1.2',
    2: 'F+/1.3',
    3: 'PD\u2212/2.1',
    4: 'PD/2.2',
    5: 'PD+/2.3',
    6: 'AD\u2212/3.1',
    7: 'AD/3.2',
    8: 'AD+/3.3',
    9: 'D\u2212/4.1',
    10: 'D/4.2',
    11: 'D+/4.3',
    12: 'TD\u2212/5.1',
    13: 'TD/5.2',
    14: 'TD+/5.3',
    15: 'ED\u2212/5.3',
    16: 'ED/5.4',
    17: 'ED+/5.4',
    18: 'EX/5.5',
  },
  langDialog: {
    chooseLang: 'Choisissez une langue',
  },
  disclaimer: {
    title: "Conditions d'utilisation",
    text: {
      0: "Ce prototype ajuste un itinéraire que tu as choisi et évalue son risque d'avalanche. Note qu'il s'agit d'un prototype et que les résultats sont par principe incertains !",
      1:
        "Skitourenguru est un outil complémentaire pour choisir et planifier de manière autonome et en toute responsabilité une randonnée à ski et évaluer les risques " + 
        "d'avalanche y liés.  Chaque jour, des milliers de randonnées à ski dans la région alpine sont classées de manière automatisée en fonction du risque d'avalanche, sur la base des ",
      2: {
        text: "bulletins d'avalanche",
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      3: ' locaux actualisés et des ',
      4: {
        text: 'données officielles de terrain',
        link: getInfoServerLink() + '/index.php/athm-de',
      },
      5: " conformément aux modalités d'exécution programmées : vert (risque modéré), orange (risque accru) ou rouge (risque élevé). ",
      6: "Skitourenguru GmbH ne donne aucune garantie et par conséquent décline toute responsabilité quant à l'exactitude des informations et des évaluations présentées. ",
      7: 'Ces dernières peuvent être incorrectes (voir ',
      8: {
        text: 'Guide',
        link: getInfoServerLink() + '/download/intro/Intro_FR.pdf',
      },
      9: "). Il est donc expressément précisé que Skitourenguru ne peut être utilisé que comme source d'informations complémentaire lors de la planification d'une randonnée à ski sous sa propre responsabilité. ",
      10: "Des données anonymisées sont recueillies lors de l'utilisation de Skitourenguru. ",
      11: {
        text: 'La déclaration de confidentialité',
        link: getInfoServerLink() + '/privacy-en',   
      },
      12: ' précise quelles données nous recueillons et à quelles fins nous recueillons vos données. ',
      13: "Acceptez-vous ces conditions d'utilisation ?",      
    },
  },
  editionDescription: 'Moment idéal pour la consultation de Skitourenguru',
  regionStatus: {
    0: {
      short: '-',
      medium: '-',
      long: '',
      validity: '',
    },
    1: {
      short: 'On',
      medium: 'En service',
      long: 'Le service pour la région est actif.',
      validity: '',
    },
    2: {
      short: 'Test',
      medium: 'Mode test',
      long:
        'Le service est actif en tant que version de test. Ces versions seront activées pour les régions ayant peu de retours d’expérience. Les informations affichées sont donc moins fiables que dans les région en service.',
      validity: 'Résultats incertains',
    },
    3: {
      short: 'Wait',
      medium: 'Calcul en cours',
      long:
        "Pour l'instant, il n'y a pas de données valables disponibles pour cette région, car Skitourenguru est en train de les calculer. Essayez dans 15 minutes avec un Refresh (F5).",
      validity: 'Résultats dépassés',
    },
    4: {
      short: 'Off',
      medium: 'Mode démonstration',
      long:
        "Un bulletin d'avalanche valide n'est pas disponible. La région est donc en mode démonstration. Les résultats affichés ne sont pas valables.",
      validity: 'Résultats non valables',
    },
    5: {
      short: 'Project',
      medium: 'En project',
      long:
        'Le service pour la région est seulement projet dans une avenir plus ou moins proche. Les résultats affichés n’ont aucune valeur.',
      validity: 'Résultats non valables',
    },
  },
  vectorLayers: {
    1: 'Régions',
    2: "Risque d'avalanche",
    3: 'Itinéraires',
    4: 'Résidences',
    5: 'Profils des risques',
    6: 'Passages clés',
    7: 'Webcams',
    8: "Bulletin d'avalanches",
    9: "Accidents d'avalanches",
    10: 'Résidence actuelle',
    11: 'Dépôt de ski',
    12: 'Espaces protégés'
  },
  layerGroups: {
    title: 'Layer Switcher',
    base: 'Cartes base',
    overlay: 'Cartes overlay',
    objects: 'Objets de cartes',
  },
  rasterLayers: {
    1009: 'Test',
    1010: 'BaseMap TopoMap (Autriche)',
    1011: 'BEV/OTM (Alpes orientales)',
    1016: 'ESRI TopoMap',
    1017: 'OpenStreetMap',
    1018: 'OpenTopoMap (Monde)',
    1020: 'OpenCycleMap',
    1021: 'OSMAnd',
    1025: 'IGN TopoMap (France)',
    1026: 'IGN TopoMap (France)',
    1027: 'IGN Slope (France)',
    1028: 'Swisstopo TopoMap',
    1029: 'Classes de pente',
    1031: 'OpenTopoMap (Alpes)',
    1045: 'Couloirs (Suisse)',
    1046: 'SkiMap (Suisse)',
    1047: 'Exolabs Msque de neige',
    1048: 'Exolabs Enneigement',
    1050: 'Sites de protection',
    1051: 'Zone de tranquilité',
    1052: 'Itinéraires à ski (CAS)',
    1053: 'Arrêts transport',
    1054: 'Orthophoto (Swisstopo)',
    1055: 'Swisstopo 1:10K',
    1056: 'Swisstopo TopoMap (x2)',
    1057: 'Terrain avalancheux (ATH)',
    1058: 'Terrain avalancheux (CAT)',
    1059: 'Risque de chute',
    1060: 'Isolement',
    1061: 'Espaces protégés',
    1062: 'Terrain avalancheux (ATHM)',
    1063: 'Classes de pente',
    1064: 'Risque de chute',
    1065: 'Orthophoto (IGN)',
    1066: 'Orthophoto (Austria)',
    1067: 'OTM/Swisstopo/BEV/LDBV/IGN',  
    1068: 'Exolabs Différence de neige (-48h)',
    1069: 'Exolabs Différence de neige (+48h)',    
    1070: 'Pistes de ski',
    1071: 'Randonnées',
    1072: 'Corridor',
    1073: 'Itinéraires Skitourenguru',
    1074: 'Google Earth',
    1075: 'Orthophoto (Bavière)',
    1076: 'Orthophoto (Tyrol du Sud)',
    1077: 'Terrain raid (50°-90°)'       
  },
  bulletin: {
    title: "Bulletin d'avalanches",
    dangerLevels: {
      1: 'Faible',
      2: 'Limité',
      3: 'Marqué',
      4: 'Fort',
      5: 'Très fort',
    },
    dayCourseWarning: 'Attention: Aggravation du degré de danger au cours de la journée. Les estimations ne sont valables que durant les meilleures heures du matin (généralement avant midi).',
    invalidWarning: "Attention: Ce bulletin d'avalanches n'est pas à jour. Cela signifie que les indicateurs de risque ne sont valables non plus!",
    skitourenguruWarning: "Ci-dessous, le degré de danger et les 'endroits dangereux' sont indiqués tels qu'ils sont fournit à l'algorithme de Skitourenguru. Le degré de danger et les 'endroits dangereux' sont extraits de 'bulletins d'avalanches' structurellement différents. Dans certains cas particuliers, cela peut entraîner des différences par rapport au bulletin d'avalanches original.",
    originalWarning: "Le contenu le plus complet possible du bulletin d'avalanches original est présenté ci-après sous une forme harmonisée. Étant donné que les bulletins d'avalanches de l'espace alpin se distinguent par leur structure, cela peut entraîner, dans des cas particuliers, des divergences par rapport au bulletin d'avalanches original.",
    coreZoneInfo: "Skitourenguru part du principe qu'en dehors des 'endroits dangereux', le degré de danger est inférieur de un.",
    dangerLevelWarning3: "Attention : Cet itinéraire se trouve dans une zone avec un degré de danger 'Marqué'. Le modèle de Skitourenguru se concentre sur les avalanches déclenchées par des personnes (95% des accidents mortels). Mais en cas de danger 'Marqué', des avalanches spontanées sont également possibles. Intégrezles dans votre planification au plus tard à partir du degré de danger 'Marqué (3+)' !",
    dangerLevelWarning4: "Attention : Cet itinéraire se trouve dans une région avec un degré de danger 'Fort'. Le modèle de Skitourenguru se concentre sur les avalanches déclenchées par des personnes (95% des accidents mortels). Mais en cas de danger 'Fort', des avalanches spontanées sont également probables, intégrezles dans votre planification !",
    table: {
      edition: 'Édition',
      validTill: "Valable jusqu'à",
      dangerLevel: 'Degrés de danger',
    },
    periode: {
      day_periode: 'Toute la journée',
      cold_periode: 'Matin',
      hot_periode: 'Après-midi'
    },
    moisture: {
      overview: "Vue d'ensemble",
      dry: 'Avalanches sèches',
      wet: 'Avalanches mouillées'
    },    
    panelHeaders: {
      texts: 'Description textuelle',
      feedback: 'Feedback',
      algorithm: 'Base pour Skitourenguru'
    },    
    problems: {
      NEW_SNOW: "Neige fraîche",
      WIND_SLAB: "Neige soufflée",
      WET_SNOW: "Avalanches mouillées",
      PERSISTENT_WEAK_LAYERS: "Neige ancienne",
      GLIDING_SNOW: "Avalanches de glissement",
      FAVOURABLE_SITUATION: "Situation favorable",
      NO_DISTICT_AVALANCHE_PROBLEM: "Pas de problème d'avalanche prononcé"
    },
    problemLink: 'https://www.slf.ch/fr/bulletin-davalanches-et-situation-nivologique/en-savoir-plus-sur-le-bulletin-davalanches/situations-avalancheuses-typiques/',
    publisher: {
      ch: {
        title: '©\u2006SLF',
        link: 'https://whiterisk.ch/fr/conditions',
      },
      au: {
        title: 'en fonction de la région',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      fr: {
        title: '©\u2006Meteo France',
        link: 'http://www.meteofrance.com/previsions-meteo-montagne/bulletin-avalanches',
      },
      ie: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      iw: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      title: "Lien vers l'original",
    },
    feedback: {
      text: "Signalez vos observations au SLF et contribuez à améliorer la qualité du bulletin d'avalanche : ",
      link1: 'https://www.slf.ch/fr/bulletin-davalanches-et-situation-nivologique.html#observations',
      link2: 'https://pro.slf.ch/reply/public/?lang=fr',
      link_text: 'Au formulaire...',
    },
    poll: {  
      text1_ch: 'Le SLF cherche',
      text1_au: "Les services d'avalanches du Tyrol, du Alto Adige et du Trentino cherchent",          
      text2: " des adeptes de sports de neige prêts à participer à des enquêtes sur le bulletin d'avalanches pendant plusieurs hivers. ",
      text3: "Rejoins-nous et participe au développement du bulletin d'avalanches ! ",
      link: 'Vers le sondage...',
      link_ch: 'https://eurosignup.avalancheresearch.ca/slf_fr/',
      link_au: 'https://eurosignup.avalancheresearch.ca/euregio_en/'         
    }
  },
  route: {
    title: "Description de l'itinéraire",
    diffWarning:
      'Attention : à partir de la cotation ZS/3.2 (assez difficiles) les itinéraires requièrent un niveau technique et d’entraînement élevé!',
    stop: 'Sommet',
    start: 'Départ',
    startElevation: 'Altitude du départ',
    stopElevation: 'Altitude du sommet',
    travelDistance: 'Approche de la résidence',
    elevationDifference: 'Dénivelé',
    length: 'Longueur',
    time: 'Temps de montée',
    snowAmount: 'Neige',
    snowFilter: 'Enneigement au départ',
    difficulty: 'Degré de difficulté',
    type: ['Vallée au sommet', 'Vallée au refuge', 'Refuge au sommet'],
    beta: ' Bêta!',
    riskIndicator: "Risque d'avalanches",
    rising: 'croissant!',
    elevationGain: 'm',
    all: 'tous',
    links: {
      gps: {
        title: 'Trace GPS',
        link: 'Fichier GPX',
      },
      gipfelbuch: {
        title: 'Info itinéraire',
        link: 'Gipfelbuch',
      },
      sac: {
        title: 'Info itinéraire',
        link: 'Portail des courses CAS',
      },
      weather: {
        title: 'Météo',
        link: 'MeteoBlue',
      },
      literature: {
        title: 'Literature',
        link: {
          panico: 'Guide de Panico'
        }
      },
      wild: {
        title: 'Sites de prot. de la faune',
        crosses: 'Oui',
        nearby: 'Dans une distance de xxx m',
        uri: 'https://natur-freizeit.ch/sports-de-neige-et-respect',
      },
      transportSchedule: {
        title: 'Voyage',
        link: 'Google Map',
      },
    },
    resetFilters: 'réinitialiser filtre',
    diff: {
      manual: '(manuelle)',
      automatic: '(automatique)',
    },
  },
  ratings: {
    title: 'Évaluations typiques', 
    intro: 'Que se passerait-il si... Risque d\'avalanche pour 10 bulletins d\'avalanche typiques:',    
    displayMore: 'Voir plus...',
    displayLess: 'Voir moins...',    
    bulletins: {
      0: 'Faible | toutes | toutes',
      1: 'Limité | 2200 m | NO-N-NE',
      2: 'Limité | 1800 m | O-N-E',
      3: 'Limité | toutes | toutes',
      4: 'Marqué | 2200 m | NO-N-NE',
      5: 'Marqué | 1800 m | O-N-E',
      6: 'Marqué | 1600 m | toutes',
      7: 'Marqué | toutes | toutes',
      8: 'Fort | 1800 m | toutes',
      9: 'Fort | toutes | toutes',
    },
    experimentButton: 'Experimentez avec itineraire'
  },
  info: {
    title: 'Informations complémentaires',
  },
  protectedAreas: {
    title: 'Espaces protégés',
    intro: "Cet espace protégé est pertinent pour les sports d'hiver.",
    info: 'Information',
    org: 'Responsable',
    states: {
      S10: 'Accès interdit en dehors des chemins et itinéraires autorisés (rouge)',
      S20: 'Accès déconseillé en dehors des chemins et itinéraires autorisés (jaune).'
    }
  },  
  accidents: {
    title: "Accident d'avalanche",
    content: {
      0: 'Degré de danger:',
      1: 'Date:',
      fatality: {
        1: 'Accident mortel',
        0: 'Accident non mortel',
      },
      activity: {
        1: 'Accident dans la zone de randonnée',
        2: 'Accident dans la zone de ski hors-piste'
      },
      sourceName: {
        1: '© SLF',
        2: '© LAWIS',
        3: '© Data-Avalanche',
        4: '© AINEVA',        
      }, 
      sourceUrl: {
        1: 'https://www.slf.ch/de/lawinen/unfaelle-und-schadenlawinen.html',
        2: 'https://www.lawis.at/incident?#${id}',
        3: 'http://www.data-avalanche.org/avalanche/${id}',
        4: 'https://aineva.it/incidenti/',        
      },  
    },
    link: '',
  },
  help: {
    cruxes: {
      title: 'Passages clés',
      content: {
        0: 'Skitourenguru indique les passages clés par des anneaux gris.',
        1: 'Un anneau gris: terrain avalancheux',
        2: 'Deux anneaux gris: terrain avalancheux typique',
        3: 'Trois anneaux gris: terrain avalancheux très typique',
        4: "L’indication est effectué indépendamment des conditions du moment en matière de neige et d'avalanches et exclusivement en fonction du terrain. Pendant la randonnée à ski, vous devrez faire avant chaque point clé une évaluation individuelle du risque de la pente.",
      },
      link: getInfoServerLink() + '/index.php/cruxes-fr',
    },
    difficulty: {
      title: 'Cotations',
      content: {
        0: "Selon la région, les cotations sont exprimées à l'aide de 'l'échelle CAS' ou de 'l'échelle Toponeige':",
        1: 'F/2: Facile',
        2: 'PD/3: Peu difficile',
        3: 'AD/4: Assez difficile',
        4: 'D/5: Difficile',
        5: 'TD/6: Très difficile',
        6: 'Avec +/\u2013 (échelle du CAS) ou un chiffre ultérieur (échelle Toponeige), la cotation peut être affinée.',
        7: 'Recommendation: Choisissez des randonnées à ski que vous pouvez gérer. Comparez la cotation avec les itinéraires que vous connaissez.',
        8: "Selon l'itinéraire et la région, les cotations sont attribués manuellement et/ou automatiquement :",
        9: 'Attribution manuelle: ',
        10: "La cotation est basée sur un jugement d'experts. Les principaux critères sont l'inclinaison, l'exposition et les passages étroits. L'évaluation porte principalement sur la partie skiable de l'itinéraire.",
        11: 'Attribution automatique: ',
        12: "La cotation a été automatiquement calculée par un algorithme. Les critères utilisés sont l'inclinaison et le danger de chute. L'évaluation porte principalement sur la partie skiable de l'itinéraire.",
        13: "Attention: La cotation réelle dépend des conditions actuelles et de votre état!",
      },
      link: getInfoServerLink() + '/index.php/difficulty-fr',
    },
    skiDepots: {
      title: 'Dépôt des skis',
      content: {
        0: "Skitourenguru calcule un emplacement probable pour le dépôt des skis en fonction des caractéristiques du terrain (inclinaison et courbure).",
        1: "En règle générale, il est possible de monter jusqu’au dépôt des skis avec ses skis. Du dépôt de ski jusqu'au sommet, il faut généralement évoluer à pied.",
        2: "La cotation tient en compte en premier lieu de la section entre le point de départ et le dépôt des ski, mais aussi, en second lieu, de la section entre le dépôt des skis et le point d'arrivée.",
      },
      link: '',
    },  
    homes: {
      title: 'Résidence',
      content: {
        0: "À quelle distance de votre résidence cherchez-vous des itinéraires? Recherchez le lieu à l'aide de la liste ou tapez simplement le nom d'une ville. Vous pouvez également cliquer sur l'une des icônes grises sur la carte.",
        1: "À l'étape suivante, vous pouvez réduire le rayon de cette approche.",
      },
      link: '',
    },
    travelDistance: {
      title: 'Approche',
      content: {
        0: "À quelle distance de votre résidence cherchez-vous des itinéraires? La distance routière est indiquée en 'kilomètres par route'.",
      },
      link: '',
    },
    startElevation: {
      title: 'Altitude du départ',
      content: {
        0: 'À quelle altitude au-dessus du niveau de la mer souhaitez vous commencer la randonnée à skis?',
      },
      link: '',
    },
    elevationGain: {
      title: 'Dénivelé',
      content: {
        0: 'Combien de dénivelé positif vous voulez faire ou acceptez-vous?',
      },
      link: '',
    },
    routeType: {
      title: "Type d'itinéraire",
      content: {
        0: "Quel type d'itinéraire vous intéresse ?",
      },
      link: '',
    },      
    riskIndicator: {
      title: "Risque d'avalanches",
      content: {
        0: 'Que signifient les trois catégories de risques?',
        1: 'Risque modéré (Valeurs 0\u200B\u2013\u200B1): ',
        2: 'Risque accru (Valeurs 1\u200B\u2013\u200B2): ',
        3: 'Risque élevé (Valeurs 2\u200B\u2013\u200B3): ',
        4: "Relativement sûr, en l'absence d'autres indications de danger. Les randonnées vertes présentent toutefois un risque résiduel.",
        5: "Uniquement pour les randonneurs expérimentés. Si vous souhaitez planifier une telle randonnée, vous devez d'abord analyser la situation en profondeur.",
        6: "Il est préférable d'éviter les randonnées à ski présentant un risque élevé d'avalanche.",
        7: "La valeur comprise entre 0 et 3 indique la proximité d'un itinéraire par rapport à la limite de la catégorie suivante. Le risque augmente de manière exponentielle avec la valeur spécifiée.",
      },
      link: getInfoServerLink() + '/index.php/symbolik-fr',
    },
    ratings: {
      title: 'Évaluations typiques',
      content: {
        0: 'Skitourenguru a évalué cet itinéraire pour 10 bulletins d\'avalanche typiques. Deux exemples:',
        1: '\'Marqué | 1800 m | O-N-E\': Degrés de danger \'Marqué\' au-dessus de 1800 m dans les expositions ouest à est en passant par le nord.',
        2: '\'Limité | toutes | toutes\': Degrés de danger \'Limité\' à toutes les altitudes et à toutes les expositions.',
      },
    },    
    status: {
      title: 'État de la région',
      content: {
        0: "Au cours des prochaines années, Skitourenguru élargira progressivement la gamme de services à l'ensemble de la région alpine. En attendant, les régions couvertes se trouvent à différents stades de développement:",
      },
      link: '',
    },
    edition: {
      title: 'Édition',
      content: {
        0: "C'est le moment idéal pour consulter Skitourenguru. À cette heure, le bulletin du soir est généralement disponible et Skitourenguru a mis à jour les estimations.",
      },
      link: getInfoServerLink() + '/index.php/ostalpen',
    },
    ascentTime: {
      title: 'Temps de montée',
      content: {
        0: 'Le temps de montée est calculé à partir de la distance (horizontale) et du dénivelé (vertical) à effectuer à partir:',
        1: 'Vitesse horizontale: 4400 mètre/heure',
        2: 'Vitesse verticale: 440 mètre/heure',
        3: "Selon la difficulté de l'itinéraire, le temps sera légèrement prolongé ou raccourci. Les pauses ne sont pas comprises.",
      },
      link: '',
    },
    dangerLevel: {
      title: 'Degrés de danger',
      content: {
        0: 'Le danger d’avalanches est décrit avec l’échelle européenne à cinq degrés:',
        1: 'Faible (Degrés 1): Situation avalancheuse favorable en général',
        2: 'Limité (Degrés 2): Prédominance d’une situation avalancheuse favorable',
        3: 'Marqué (Degrés 3): Situation avalancheuse critique',
        4: 'Fort (Degrés 4): Situation avalancheuse très critique',
        5: 'Très fort (Degrés 5): Situation avalancheuse exceptionnelle',
        6: 'Le degré de danger dépend de différents paramètres, notamment la probabilité de déclenchement des avalanches, l’extension des endroits dangereux et la taille des avalanches. Un degré de danger est toujours valable pour une région, et non pour un versant déterminé. ',
        7: "Certains services de prévision d'avalanche publient directement ou indirectement des niveaux intermédiaires. Celles-ci indiquent si le danger est estimé plutôt en partie basse (-), plus ou moins au milieu (=) ou en partie haute (+) du degré de danger."
      },
      link1: 'https://www.slf.ch/fr/bulletin-davalanches-et-situation-nivologique/en-savoir-plus-sur-le-bulletin-davalanches/degres-de-danger.html',
      link2: getInfoServerLink() + '/index.php/imdl-de',        
    },
    coreZone: {
      title: 'Endroits dangereux',
      content: {
        0: "Les 'endroits dangereux' désignent les zones de danger particulièrement critiques.",
        1: "Les expositions critiques: Sur une rose des vents, les expositions critiques sont marquées en 'noir'.",
        2: "Les altitudes critiques: Les altitudes critiques sont indiquées en 'noir' par un symbole de montagne.",
        3: "Des études statistiques montrent que le risque d'avalanche est plusieurs fois plus élevé à l'intérieur des 'endroits dangereux' qu'à l'extérieur.",
        4: "En Europe, plusieurs services d'alerte désignent des 'endroits dangereux' distinctes pour chaque 'situation avalancheuse typique'. Skitourenguru combine ces 'endroits dangereux' en un 'endroit dangereux' et l'intègre dans l'algorithme.",
      },
      link: getInfoServerLink() + '/index.php/corezone-fr',
    },
    snowAmount: {
      title: 'Enneigement',
      content: {
        0: "Cette ligne indique l'épaisseur de la neige sur l'itinéraire:",
        1: "Les deux premiers chiffres indiquent l'épaisseur de la neige au point de départ et d'arrivée de l'itinéraire.",
        2: "Le nombre entre parenthèses indique l'épaisseur moyenne de la neige.",
        3: "Le dernier chiffre indique le pourcentage du trajet couvert d'au moins 10 cm de neige.",
        4: "L'épaisseur de la neige est fournie par Exolabs GmbH. Elle est basée sur les données suivantes:",
        5: 'Epaisseur de neige des stations de mesure automatiques.',
        6: 'Données satellites (Sentinel, Landsat et Modis)',
        7: "Connaissance de l'épaisseur de la neige en fonction des caractéristiques du terrain.",
        8: "Sur la carte, l'épaisseur de neige (en cm) est indiquée avec les couleurs suivantes:",
      },
      link: getInfoServerLink() + '/index.php/exolabs',
    },
    snowFilter: {
      title: 'Enneigement au départ',
      content: {
        0: 'Avec ce curseur, vous pouvez filtrer les itinéraires qui ont une certaine épaisseur de neige minimale au départ.',
      },
    },
    about: {
      title: 'About',
      subTitle: 'Mentions légales',
      content: {
        0: 'Skitourenguru se considère comme un projet communautaire. En termes juridiques, cependant, Skitourenguru est constitué en société à responsabilité limitée:',
        1: 'Vous voulez en savoir plus sur Skitourenguru?',
        2: "Avant d'écrire un courriel, veuillez d'abord consulter la page web :",
        3: {
          0: {
            content: 'Médias',
            link: getInfoServerLink() + '/index.php/mymedia',
          },
          1: {
            content: "Vidéos de formation",
            link: getInfoServerLink() + '/index.php/services/221-videos',
          },
          2: {
            content: 'Science d"avalanches',
            link: getInfoServerLink() + '/index.php/lawinenkunde',
          },          
          3: {
            content: 'Modèle (Algorithme)',
            link: getInfoServerLink() + '/index.php/model',
          },
          4: {
            content: 'Aide',
            link: getInfoServerLink() + '/index.php/help',
          },
          5: {
            content: 'Manuel',
            link: getInfoServerLink() + '/download/intro/Intro_FR.pdf',
          },  
          6: {
            content: 'Dessiner des itinéraires',
            link: getInfoServerLink() + '/index.php/rudr-de',
          },              
        }
      },
      link: getInfoServerLink() + '/index.php/about',
    },
    wildlife: {
      title: 'Sites de protection de la faune',
      content: {
        0: "Avec le bouton des couches (en bas à gauche sur la carte) dans le nœud 'Objets de cartes', vous pouvez faire apparaître les réserves de faune.",
      },
      link: 'https://nature-loisirs.ch/sports-de-neige-et-respect',
    },
    degreeOfFreedom: {
      title: 'Marge de manœuvre',
      content: {
        0: "Plus la valeur est élevée, plus tu donnes de marge de manœuvre à l'algorithme pour l'ajustement de l'itinéraire. Avec 0 %, il n'y a pas d'ajustement de l'itinéraire, c'est l'itinéraire saisi qui est évalué.",
      }
    },    
    avalancheTerrainWeight: {
      title: 'Terrain avalancheux',
      content: {
        0: "Plus la valeur est élevée, plus le terrain avalancheux est pris en compte dans l'ajustement de l'itinéraire.",
      }
    },    
    movementWeight: {
      title: 'Poids du coût du mouvement',
      content: {
        0: 'Plus la valeur est élevée, plus les coûts du mouvement sont pris en compte par rapport aux coûts de passage.',
        1: 'Le coût du mouvement dépend de la distance verticale et horizontale.',
        2: "Les coûts de passage dépendent des caractéristiques du terrain, par exemple de l'inclinaison."
      }
    },  
    horizontalMovingCost: {
      title: 'Coûts de la distance',
      content: {
        0: 'Plus la valeur est élevée, plus les détours sont évités.',
      }
    },    
    elevationGainCost: {
      title: "Coûts de l'ascensions",
      content: {
        0: 'Plus la valeur est élevée, plus les ascensions sont évitées.',
      }
    },    
    elevationLossCost: {
      title: 'Coûts de la descente',
      content: {
        0: 'Plus la valeur est élevée, plus les descentes sont évitées.',
      }
    }, 
  },     
  donation: {
    title: 'Appel aux dons',
    content:
      'Tout don, petit ou grand, est le bienvenu et sera directement investi dans la maintenance et le développement de la plate-forme. ' +
      "Skitourenguru continuera à être libre et sans publicité. En outre, la plateforme se passe délibérément de logins, trackers et lettres d'information.",
  },
  address: {
    jurisdiction: 'Lieu de juridiction',
    manager: 'Directeur général',
  },
  locationSearch: {
    title: 'Chercher un lieu dans la carte',
    types: {
      peak: 'Sommet',
      hill: 'Colline',
      saddle: 'Col',
      alpine_hut: 'Refuge',
      wilderness_hut: 'Bivouac',
      administrative: 'Domaine',
      municipality: 'Municipalité',
      city: 'Ville',
      town: 'Ville',
      village: 'Village',
      locality: 'Lieu',
      hamlet: 'Hameau',
      isolated_dwelling: 'Nom du champ',
      station: 'Gare',
      bus_stop: 'Arrêt de bus',
      valley: 'Vallée',
      water: 'Lac',
      ridge: 'Arête',
      arete: 'Arête',
      glacier: 'Glacier',
      river: 'Fleuve',
      stream: 'Ruisseau',
      district: 'District',
      province: 'Province',
      region: 'Région',
      shelter: 'Abri',
      viewpoint: 'Point de vue',
      camp_site: 'Camping',
    },
  },
  newsChannel: {
    title: 'Guru News',
  },
  message: {
    type: {
      0: 'Information',
      1: 'Avertissement',
      2: 'Erreur',
    },
    texts: {
      1: 'Le serveur a actuellement un problème de traitement, réessayez plus tard !',
      2: "Cette fonction n'est pas encore implémentée !",
      3: "L'itinéraire ne doit pas dépasser xxx km et rester inférieur à yyy m!",
      4: "L'itinéraire est en dehors de la zone actuellement prise en charge !",
      5: "La demande n'est pas valable !",
      6: "La demande n'est pas autorisée !",
      7: "Le fichier GPX ne contient pas d'objet ligne.",
      8: "Ce lien n'est pas valide.",
      9: 'Cet itinéraire a un niveau de difficulté élevé!',
      10: "Cet itinéraire présente un tel degré de difficulté qu'il est à peine praticable à ski!",
      11: 'Le serveur est actuellement saturé, réessayez plus tard !',
      12: "Entre 17h et 18.15h environ, tous les itinéraires ne sont pas affichés dans les Alpes orientales, car quelques services de prévision d'avalanches actualisent le bulletin a 18h.",
      13: "Depuis peu, tu peux dessiner toi-même un itinéraire. Ensuite, Skitourenguru adapte l'itinéraire et l'évalue : Dans le menu en haut à droite sous 'Dessiner des itinéraires'.",
    }
  },
  rudr: {
    title: "Contexte d'évaluation",
    description: "Dessinez un itinéraire sur la carte ou chargez un fichier GPX. Lorsque vous dessinez, utilisez le double-clic pour terminer l'itinéraire. Choisissez un bulletin d'avalanche. Pour finir, cliquez sur le bouton 'Démarrer l'évaluation' (en bas).",
    forecast: {
      title: "Saisie du bulletin d'avalanche",
      dangerLevel: 'Degré de danger',
      critEle: 'Altitude critique',
      critExpos: 'Expositions critiques : Clique sur la rose !'
    },
    settings: {
      title: "Paramètres d'ajustement d'itinéraire",
      degreeOfFreedom: 'Marge de manœuvre',
      avalancheTerrainWeight: 'Terrain avalancheux',
      horizontalMovingCost: 'Coûts liés à la distance',
      elevationGainCost: "Coûts de l'ascension",
      elevationLossCost: 'Coûts de la descente',
      movementWeight: 'Poids du coût du mouvement',
    },   
    buttons: {
      cutGpx: "Découpe du fichier GPX au 'sommet'",
      loadGpx: "Chargement d'un fichier GPX",
      start: "Démarrer l'évaluation",
      reset: 'Réinitialisation entrées',
      copyLink: "Copier le lien à partager",
      respectProtAreas: 'Attention aux espaces protégés'
    },
    output: {
      name: 'Inconnu'
    }
  },

  /**************************************** */
  //copied from vuetify v-data-table
  dataTable: {
    itemsPerPageText: 'Lignes par page:',
    ariaLabel: {
      sortDescending: ': Tri décroissant. Activer pour supprimer le tri.',
      sortAscending: ': Tri croissant. Activer pour trier par ordre décroissant.',
      sortNone: ': Non trié. Activer pour trier par ordre croissant.',
    },
    sortBy: 'Trier par',
  },
  dataFooter: {
    itemsPerPageText: 'Éléments par page:',
    itemsPerPageAll: 'Tous',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    firstPage: 'Première page',
    lastPage: 'Dernière page',
    pageText: '{0}\u200B\u2013\u200B{1} de {2}',
  },
  noDataText: 'Aucun résultat',
  /**************************************** */
}
