<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.home`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      :fill="foregroundColor"
      d="M23.22,7.19A11.55,11.55,0,0,0,11.71,18.71c0,9.91,10.62,20.72,11.07,21.17a.6.6,0,0,0,.86,0l0,0c.45-.45,11.07-11.32,11.07-21.17A11.52,11.52,0,0,0,23.24,7.19Zm0,17.34a5.82,5.82,0,1,1,5.83-5.82A5.83,5.83,0,0,1,23.22,24.53Z"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconHome',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
