import store from '@/store/store'
import { getRiskCssColor, getRegionCssColor, getPrimaryCssColor } from '../colors'
import { isObjectValid } from '../utilities'

export const utilityMixin = {
  inheritAttrs: false,
  data: () => ({
    DETAIL_POSTFIX: {UNKNOWN: '', MINUS: '-', NEUTRAL: '=', PLUS: '+', APPROXIMATELY: '~'},
    DETAIL_VALUE: {UNKNOWN: 0, MINUS: -1, NEUTRAL: 0, PLUS: +1, APPROXIMATELY: 0}
  }),
  methods: {
    getDifficultyForArea(diffValue, area) {
      var text = this.$vuetify.lang.t(`$vuetify.difficultyScales.${diffValue}`)
      if (area === 'ch' || area === 'au' || area === 'ie') {
        return text.split('/')[0]
      } else {
        return text.split('/')[1]
      }
    },
    pad(num, size) {
      var s = num + ''
      while (s.length < size) s = '0' + s
      return s
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace)
    },
    updateHeader(title, status, risk) {
      store.dispatch('header/setHeader', {
        title: title,
        status: status,
        risk: risk,
      })
    },
    detectLanguage() {
      const DEFAULT_LANG = 'DE'
      var lang = navigator.language || navigator.userLanguage
      if (typeof lang == 'undefined' || lang == null || lang == '') return DEFAULT_LANG
      lang = lang.substring(0, 2)
      if (lang.length != 2) return DEFAULT_LANG
      lang = lang.toLowerCase()
      return lang
    },
    chooseLanguage() {
      var lang = this.detectLanguage()
      const langStored = store.state.localState.lang
      if (typeof langStored != 'undefined' && langStored != null && langStored != '') {
        lang = langStored
      }
      this.$vuetify.lang.current = lang
    },
    getWarningColor() {
      return getRiskCssColor(2.5, 'ICONS')
    },
    getOrangeColor() {
      return getRiskCssColor(1.5, 'HEADER')
    },
    getHeaderColor() {
      return getRegionCssColor(1, 'HEADER')
    },
    getPrimaryColor() {
      return getPrimaryCssColor()
    },
    formatDateTime(date, timeParenthesis) {
      var str =
        date.getDate() + '.\u200D' + (date.getMonth() + 1) + '.\u200D' + date.getFullYear() + '\u200B\u2013\u200B'
      if (timeParenthesis) str += '('
      str += date.getHours() + '.' + this.pad(date.getMinutes(), 2) + '\u2006h'
      if (timeParenthesis) str += ')'

      return str
    },
    formatDate(date) {
      var str = date.getDate() + '.\u200D' + (date.getMonth() + 1) + '.\u200D' + date.getFullYear()
      return str
    },
    getPriorizedTexts(dangerArea) {
      var texts    
      texts = this.getLanguageTexts(dangerArea, 'en')
      if (this.isTextsValid(texts)) return texts
      texts = this.getLanguageTexts(dangerArea, 'de')
      if (this.isTextsValid(texts)) return texts
      texts = this.getLanguageTexts(dangerArea, 'fr')
      if (this.isTextsValid(texts)) return texts
      texts = this.getLanguageTexts(dangerArea, 'it')
      if (this.isTextsValid(texts)) return texts            
      return this.getLanguageTexts(dangerArea, 'de');
    },
    getLanguageTexts(dangerArea, language) {
      const texts = dangerArea.properties.texts.find((item) => item.language === language)
      if (typeof texts == 'undefined' || texts == null) return null
      return texts.blocks
    },
    getTexts(dangerArea) {
      var texts = this.getLanguageTexts(dangerArea, this.$vuetify.lang.current)
      if (this.isTextsValid(texts)) {
        return texts
      }
      return this.getPriorizedTexts(dangerArea)
    },
    isTextsValid(texts) {
      if (typeof texts == 'undefined' || texts == null) return false
      if (texts.length == 0) return false
      return true
    },    
    getDangerLevelText(danger, detail) {
      var text = this.$vuetify.lang.t(`$vuetify.bulletin.dangerLevels.${danger}`)
      text += ' '
      text += this.getDangerLevelDetail(danger, detail)
      return text
    }, 
    // eslint-disable-next-line no-unused-vars
    getDangerLevelDetail(danger, detail) {
      var display = '('
      display += danger.toString()
      display += this.DETAIL_POSTFIX[detail];
      display += ')'
      return display
    },
    isDangerLevelRising(dangerArea1, dangerArea2) {
      if (dangerArea1.properties.dayper == 'DAY_PERIODE') return false
      if (!isObjectValid(dangerArea2)) {
        return (dangerArea1.properties.dayper == 'COLD_PERIODE')
      }
      var danger1 = this.calcDanger(dangerArea1.properties)
      var danger2 = this.calcDanger(dangerArea2.properties)
      return (danger2 > danger1)
    },
    calcDanger(properties) {
      var detail = isObjectValid(properties.drdlin)? properties.drdlin:'UNKNOWN'
      var danger = properties.rdlin*3 + this.DETAIL_VALUE[detail]
      return danger
    },    
    isAvalancheProblemText(text) {
      if (!isObjectValid(text)) return false
      if (this.isDryAvalancheProblem(text.type)) return true
      if (this.isWetAvalancheProblem(text.type)) return true
      return false
    },
    isDryAvalancheProblem(avprob) {
      return (avprob == 'NEW_SNOW' ||  avprob == 'WIND_SLAB' || avprob == 'PERSISTENT_WEAK_LAYERS' || avprob == 'FAVOURABLE_SITUATION' || avprob == 'NO_DISTICT_AVALANCHE_PROBLEM')
    },
    isWetAvalancheProblem(avprob) {
      return (avprob == 'WET_SNOW' || avprob == 'GLIDING_SNOW')
    },  
    selectDryCoreZones(dangerArea) {
      var coreZones = dangerArea.properties.corezns.filter((coreZone) => this.isDryAvalancheProblem(coreZone.avprob))
      if (coreZones.length == 0) return null
      return coreZones
    }, 
    selectWetCoreZones(dangerArea) {
      var coreZones = dangerArea.properties.corezns.filter((coreZone) => this.isWetAvalancheProblem(coreZone.avprob))
      if (coreZones.length == 0) return null
      return coreZones      
    },     
  },
}
