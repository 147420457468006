<template>
  <div>
    <app-header @displayHelp="displayHelp" @dispose="dispose"></app-header>
    <div v-resize="onResize">
      <v-container v-if="!isMobile" fluid>
        <v-row>
          <v-col class="pa-0">
            <div id="map1" class="map"></div>
          </v-col>
          <v-col class="pa-0 info">
            <regions-table @navigateToAreaView="navigateTo" @displayHelp="displayHelp"></regions-table>
          </v-col>
        </v-row>
      </v-container>

      <v-tabs slider-size="3" v-if="isMobile" v-model="tabs" grow>
        <v-tab ripple @change="updateMapTab">{{ $vuetify.lang.t('$vuetify.tabs.map') }}</v-tab>
        <v-tab ripple>{{ $vuetify.lang.t('$vuetify.tabs.regionsList') }}</v-tab>
        <v-tabs-items v-model="tabs" touchless>
          <v-tab-item>
            <div v-resize="onResize" :style="{ height: contentHeight + 'px' }" id="map2" class="map"></div>
          </v-tab-item>
          <v-tab-item>
            <regions-table @navigateToAreaView="navigateTo" @displayHelp="displayHelp"></regions-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <help-dialog ref="help"></help-dialog>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import RegionsTable from '../components/RegionsTable.vue'
import HelpDialog from '../components/HelpDialog.vue'
import { olMapMixin } from '../mixins/olMapMixin'
import { resizeMixin } from '../mixins/resizeMixin'
import NProgress from 'nprogress'
import store from '@/store/store'
import { utilityMixin } from '../mixins/utilityMixin'
import { refreshMixin } from '../mixins/refreshMixin'
import { getIsMobile, addExtentMargin, constDefaultFilters } from '../utilities'
import { regionExtent } from '../variables'
import { transform } from 'ol/proj'

export default {
  name: 'regions-view',
  mixins: [olMapMixin, resizeMixin, utilityMixin, refreshMixin],
  components: {
    AppHeader,
    RegionsTable,
    HelpDialog,
  },
  data: () => ({
    tabs: null,
    extentMargin: 3,
  }),
  methods: {
    onMouseOver(e) {
      var features = e.target.getFeatures()
      if (features.getLength() != 0) {
        var feature = features.item(0)
        var type = feature.get('ltype')
        if (type != this.layerType.REGIONS) return
        const status = feature.get('status')
        const area = feature.get('region').toLowerCase()
        const title = this.$vuetify.lang.t(`$vuetify.regions.${area}`)
        this.updateHeader(title, status, null)
        return
      }
      // when the mouse moves away, we set the default header again
      this.setDefaultHeader()
    },
    setDefaultHeader() {
      const title = getIsMobile() ? this.$vuetify.lang.t(`$vuetify.header`) : ''
      this.updateHeader(title, 1, null)
    },
    onClick(e) {
      if (e.target.getFeatures().getLength() == 0) {
        return
      }
      var feature = e.target.getFeatures().item(0)
      var type = feature.get('ltype')
      if (type != this.layerType.REGIONS) {
        return
      }
      var area = feature.get('region').toLowerCase()
      this.navigateTo(area)

      e.target.getFeatures().clear()
    },
    navigateTo(area) {
      this.dispose()
      this.$router.push({
        name: 'area-view',
        query: { area: area },
      })
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('RegionView: Display help')
      this.$refs.help.open(tag)
    },
    dispose() {  
      this.disposeMap()
    },    
    calcExtent(extentMargin) {
      var extent = [0, 0, 0, 0]

      var minPoint = [regionExtent[0], regionExtent[1]]
      minPoint = transform(minPoint, 'EPSG:4326', 'EPSG:3857')
      extent[0] = minPoint[0]
      extent[1] = minPoint[1]

      var maxPoint = [regionExtent[2], regionExtent[3]]
      maxPoint = transform(maxPoint, 'EPSG:4326', 'EPSG:3857')
      extent[2] = maxPoint[0]
      extent[3] = maxPoint[1]

      extent = addExtentMargin(extent, extentMargin)

      return extent
    },
    clearFilters(area) {
      store.dispatch(`${area}/setFilterPreferences`, {
        distRange: constDefaultFilters.distRange.slice(),
        startHeightRange: constDefaultFilters.startHeightRange.slice(),
        heightDiffRange: constDefaultFilters.heightDiffRange.slice(),
        difficultiesRange: constDefaultFilters.difficultiesRange.slice(),
        snowRange: constDefaultFilters.snowRange.slice(),
        riskRange: constDefaultFilters.riskRange.slice(),
        routeType: constDefaultFilters.routeType.slice(),
        keyword: '',
      })
    },
  },

  created() {
    // eslint-disable-next-line no-console
    console.log(process.env.NODE_ENV)
    this.setDefaultHeader()
    var regionsVectorLayer = this.createVectorLayer(store.state.region.regions, this.layerType.REGIONS)
    var baseMapLayer1 = this.createRasterLayer('ESRI')
    var centerX = (584893 + 1818763) / 2
    var centerY = (5455501 + 6115703) / 2
    this.createMap(
      centerX,
      centerY,
      5.5,
      [regionsVectorLayer],
      [baseMapLayer1],
      null,
      null
    )
    this.registerSingleClickHandler(this.onClick)
    this.registerMouseOverHandler(this.onMouseOver)

    var extent = this.calcExtent(this.extentMargin)
    this.zoomTo(extent, 4, 16)
  },
  mounted() {
    // Detect Device Type
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // Set a timeout...
      setTimeout(function () {
        // Hide the address bar!
        window.scrollTo(0, 1)
      }, 0)
    }
    this.startRefresher()
    
    this.clearFilters('ch')
    this.clearFilters('au')
    this.clearFilters('fr')
    this.clearFilters('ie')
    this.clearFilters('iw')
  },
  unmounted() {
  },  
  beforeRouteEnter(routeTo, routeFrom, next) {
    // inline async function to wait for async dispatch calls
    const getDataAsync = async function () {
      await store.dispatch('region/fetchRegions')
      await store.dispatch('ch/fetchSymbols')
      await store.dispatch('au/fetchSymbols')
      await store.dispatch('fr/fetchSymbols')
      await store.dispatch('ie/fetchSymbols')
      await store.dispatch('iw/fetchSymbols')
      await store.dispatch('news/fetchNews')
      NProgress.done()
    }
    NProgress.start()
    getDataAsync().then(() => {
      NProgress.done()
      next()
    })
  },
}
</script>

<style scoped>
.map {
  height: 100%;
}
.info {
  max-width: 500px;
}
</style>
