<template>
  <v-card tile v-resize="onResize" class="ma-0 overflow-y-auto" :style="{ height: contentHeight + 'px' }">
    <v-container fluid>
      <v-row>
        <v-card elevation="0" width="100%">
          <track-table
            :trackData="trackData"
            :area="area"
            :snowDate="snowDate"
            :dangerArea1="dangerArea1"
            :dangerArea2="dangerArea2"
            :destPoint="destPoint"
            @displayHelp="displayHelp"
            @swisstopoOverlayChanged="swisstopoOverlayChanged"
          ></track-table>
        </v-card>
      </v-row>
      <v-row>
        <v-card elevation="0" class="mt-3" width="100%">
          <avalanche-bulletin
            :dangerArea1="dangerArea1"
            :dangerArea2="dangerArea2"
            :area="area"
            @displayHelp="displayHelp"
          ></avalanche-bulletin>
        </v-card>
      </v-row>
    </v-container>
    <partners></partners>
  </v-card>
</template>

<script>
import TrackTable from './TrackTable.vue'
import AvalancheBulletin from './AvalancheBulletin.vue'
import { resizeMixin } from '../mixins/resizeMixin'
import Partners from './Partners.vue'

export default {
  name: 'track-info',
  mixins: [resizeMixin],
  components: {
    TrackTable,
    AvalancheBulletin,
    Partners,
  },
  props: {
    trackData: {
      type: Object,
      required: true,
    },
    avalancheData: {
      type: Object,
      required: true,
    },
    area: {
      type: String,
      required: true,
    },
    snowDate: {
      type: String,
      required: true,
    },
    destPoint: {
      type: Array,
      required: true,
    },    
  },
  data: () => ({
    dangerArea1: null,
    dangerArea2: null
  }),  
  methods: {
    updateData() {
      this.dangerArea1 = this.avalancheData.features.find((item) => item.properties.areaid === this.trackData.properties.areaid && item.properties.dayper != 'HOT_PERIODE')
      if (typeof this.dangerArea1 == 'undefined' || this.dangerArea1 == null) {
        console.log('updateData(): Could notfind areaid ' +  this.trackData.properties.areaid)
      }
      var areatag = this.dangerArea1.properties.areatag
      this.dangerArea2 = this.avalancheData.features.find((item) => item.properties.areatag === areatag && item.properties.dayper == 'HOT_PERIODE')
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('TrackInfo: Display help')
      this.$emit('displayHelp', tag)
    },
    swisstopoOverlayChanged(overlaySelector) {
      // eslint-disable-next-line no-console
      console.log('TrackInfo: swisstopoOverlayChanged to ' + overlaySelector)
      this.$emit('swisstopoOverlayChanged', overlaySelector)
    },
  },
  watch: {
    trackData: function () {
      this.updateData()
    },
  },
  created() {
    this.updateData()
  },  
}
</script>

<style scoped></style>
