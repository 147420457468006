<template>
  <div class="py-3 px-0">
    <v-card-title class="pt-0 pb-2 px-0">{{ $vuetify.lang.t(`$vuetify.donation.title`) }}</v-card-title>
    <div class="pb-2">{{ $vuetify.lang.t(`$vuetify.donation.content`) }}</div>
    <v-sheet class="pa-2 text--secondary" id="impressumId" flat rounded :color="getOrangeColor()">
      <div class="font-weight-bold text--primary pb-1">IBAN: CH84 0844 0258 3878 4200 1</div>
      <div class="pt-1"></div>
      <div>
        Skitourenguru GmbH<br />
        Markusstrasse 12<br />
        8006 Zürich
      </div>
      <div class="pt-1"></div>
      <div>
        Bank Cler AG<br />
        CH-8001 Zürich<br />
        BIC: BCLRCHBB, Clearing: 8440
      </div>
      <div class="pt-1"></div>
      <v-container class="px-0 pb-4">
        <v-row>
          <v-col class="col-md-4 col-sm-5 col-xs-12 pb-0">
            <div style="width: 120px; height: 30px;">
              <v-img :width="120" :height="30" src="../assets/paypal.svg"></v-img>
            </div>
            <div class="pt-2"></div>
            <v-img width="120" src="../assets/paypal_scan_code.png"></v-img>
            <div class="pt-2 px-2">
              <a href="https://www.paypal.com/donate?hosted_button_id=XSSVDTHQQ28SG" target="_blank">
                <v-img width="104" src="../assets/Donate.svg"></v-img>
              </a>
            </div>
          </v-col>
          <v-col class="col-md-4 col-sm-5 col-xs-12 pb-0">
            <div style="width: 120px; height: 30px;">
              <v-img :width="100" :height="30" class="mx-auto" src="../assets/twint.svg"></v-img>
            </div>
            <div class="pt-2"></div>
            <v-img width="120" src="../assets/twint_scan_code.png"></v-img>
          </v-col>
          <v-col class="col-md-4 col-sm-5 col-xs-12 pb-0">
            <div style="width: 120px; height: 30px;">
              <v-img :width="100" :height="30" class="mx-auto" src="../assets/Logo_IBAN.svg"></v-img>
            </div>
            <div class="pt-2"></div>
            <v-img width="120" src="../assets/iban_scan_code_plain.svg"></v-img>
          </v-col>          
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
export default {
  name: 'donation',
  mixins: [utilityMixin],
  methods: {},
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#impressumId {
  border: 1.2px solid lightgray;
}
</style>
