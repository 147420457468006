<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{ $vuetify.lang.t(`$vuetify.accidents.title`) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pl-2 py-1 textDense">
        <div v-if="date != null">{{ $vuetify.lang.t(`$vuetify.accidents.content.1`) + ' ' + formatDate(date, false)}}</div>
        <div v-if="rdl != null">{{ $vuetify.lang.t(`$vuetify.accidents.content.0`) + ' ' + rdl }}</div>
        <div v-if="fatality != null">{{ $vuetify.lang.t(`$vuetify.accidents.content.fatality.${fatality}`) }}</div>
        <div v-if="activity != null">{{ $vuetify.lang.t(`$vuetify.accidents.content.activity.${activity}`) }}</div>
        <div v-if="source != null" class="pt-2">
          <a target="_blank" :href="getLink()">{{ $vuetify.lang.t(`$vuetify.accidents.content.sourceName.${source}`) }}</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'accident-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    feature: null,
    date: null,
    rdl: null,
    fatality: null,
    activity: null,
    source: null,
  }),
  methods: {
    open(feature) {
      this.date = new Date(feature.get('dt'))

      var rdl = feature.get('rdl')
      this.rdl = (rdl == 'undefined')? null: rdl

      var fatality = feature.get('fat')
      this.fatality = (fatality == 'undefined')? null: fatality 

      var activity = feature.get('act')
      this.activity = (activity == 'undefined')? null: activity  

      var source = feature.get('src')
      this.source = (source == 'undefined')? null: source      
      
      var id = feature.get('id')
      this.id = (id == 'undefined')? null: id     

      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    getLink() {
      var link = this.$vuetify.lang.t(`$vuetify.accidents.content.sourceUrl.${this.source}`)
      link = link.replace('${id}', this.id)
      return link
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
