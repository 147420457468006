<template>
  <v-card tile v-resize="onResize" class="ma-0 overflow-y-auto" :style="{ height: contentHeight + 'px' }">
    <v-container v-if="isSummer()">
      <div class="text-h6 ml-1 mr-1 mt-3 mb-1 justify-left" :style="{ color: getWarningColor() }">
        {{ $vuetify.lang.t(`$vuetify.intro.summer.title`) }}
      </div>
      <div class="text ml-1 mr-1 textDense" :style="{ color: getWarningColor() }">
        {{ $vuetify.lang.t(`$vuetify.intro.summer.content`) }}
      </div>
    </v-container>
    <v-container>
      <div class="text-h6 ml-1 mr-1 mt-3 mb-1 justify-left">{{ $vuetify.lang.t(`$vuetify.intro.guru.title`) }}</div>
      <div class="text ml-1 mr-1 mb-4 textDense">{{ $vuetify.lang.t(`$vuetify.intro.guru.content`) }}</div>
      <v-simple-table class="mb-4" dense>
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="60%" />
        </colgroup>
        <thead></thead>
        <thead>
          <tr>
            <th class="text-left body-1 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.guru.table.header.symbol`) }}</th>
            <th class="text-left body-1 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.guru.table.header.values`) }}</th>
            <th class="text-left body-1 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.guru.table.header.risks`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="fingerPointer" @click="displayHelp('riskIndicator')">
            <td class="text-left">
              <div class="pt-1" ref="riskSymbol0"></div>
            </td>
            <td class="text-left">{{ $vuetify.lang.t(`$vuetify.intro.guru.table.content.values.0`) }}</td>
            <td class="text-left">
              <a>{{ $vuetify.lang.t(`$vuetify.intro.guru.table.content.risks.0`) }}</a>
            </td>
          </tr>
          <tr class="fingerPointer" @click="displayHelp('riskIndicator')">
            <td class="text-left">
              <div class="pt-1" ref="riskSymbol1"></div>
            </td>
            <td class="text-left">{{ $vuetify.lang.t(`$vuetify.intro.guru.table.content.values.1`) }}</td>
            <td class="text-left">
              <a>{{ $vuetify.lang.t(`$vuetify.intro.guru.table.content.risks.1`) }}</a>
            </td>
          </tr>
          <tr class="fingerPointer" @click="displayHelp('riskIndicator')">
            <td class="text-left">
              <div class="pt-1" ref="riskSymbol2"></div>
            </td>
            <td class="text-left">{{ $vuetify.lang.t(`$vuetify.intro.guru.table.content.values.2`) }}</td>
            <td class="text-left">
              <a>{{ $vuetify.lang.t(`$vuetify.intro.guru.table.content.risks.2`) }}</a>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="text ml-1 mr-1 mb-2 textDense">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.content`) }}</div>
      <v-simple-table dense>
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="60%" />
        </colgroup>
        <thead></thead>
        <thead>
          <tr>
            <th class="text-left body-1 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.header.symbol`) }}</th>
            <th class="text-left body-1 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.header.values`) }}</th>
            <th class="text-left body-1 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.header.risks`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="fingerPointer" @click="displayHelp('cruxes')">
            <td class="text-left">
              <div class="pt-1" ref="cruxSymbol0"></div>
            </td>
            <td class="text-left">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.content.values.0`) }}</td>
            <td class="text-left">
              <a>{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.content.classes.0`) }}</a>
            </td>
          </tr>
          <tr class="fingerPointer" @click="displayHelp('cruxes')">
            <td class="text-left">
              <div class="pt-1" ref="cruxSymbol1"></div>
            </td>
            <td class="text-left">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.content.values.1`) }}</td>
            <td class="text-left">
              <a>{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.content.classes.1`) }}</a>
            </td>
          </tr>
          <tr class="fingerPointer" @click="displayHelp('cruxes')">
            <td class="text-left">
              <div class="pt-1" ref="cruxSymbol2"></div>
            </td>
            <td class="text-left">{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.content.values.2`) }}</td>
            <td class="text-left">
              <a>{{ $vuetify.lang.t(`$vuetify.intro.cruxes.table.content.classes.2`) }}</a>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text ml-1 mr-1 mt-2 mb-0 textDense">
        <span>{{ $vuetify.lang.t(`$vuetify.intro.limits.1`) }}</span>
        <span>{{ $vuetify.lang.t(`$vuetify.intro.limits.2`) }}</span>
        <a :href="$vuetify.lang.t(`$vuetify.intro.limits.3.link`)" target="_blank" class="font-weight-bold">{{ $vuetify.lang.t(`$vuetify.intro.limits.3.text`) }}</a>
        <span>{{ $vuetify.lang.t(`$vuetify.intro.limits.4`) }}</span>
        <span>{{ $vuetify.lang.t(`$vuetify.intro.limits.5`) }}</span>   
      </div>
    </v-container>
    <v-container>
      <div class="text-h6 ml-1 mr-1 mt-0 mb-2 justify-left">{{ $vuetify.lang.t(`$vuetify.intro.choose.title`) }}</div>
      <v-simple-table dense>
        <thead></thead>
        <thead>
          <tr>
            <th class="text-left body-1 font-weight-bold px-0">
              {{ $vuetify.lang.t(`$vuetify.intro.choose.table.header.region`) }}
            </th>
            <th class="text-left body-1 font-weight-bold px-0">
              {{ $vuetify.lang.t(`$vuetify.intro.choose.table.header.status`) }}
            </th>
            <th class="text-left body-1 font-weight-bold px-0">
              {{ $vuetify.lang.t(`$vuetify.intro.choose.table.header.edition`) }}
            </th>
            <th class="text-left body-1 font-weight-bold px-0">{{ $vuetify.lang.t(`$vuetify.intro.choose.table.header.valid`) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in region.regions.features" :key="item.properties.region">
            <td class="text-left px-0">
              <a
                :href="`area-view?area=${item.properties.region.toLowerCase()}`"
                @click.prevent="navigateTo(item.properties.region.toLowerCase())"
                >{{ $vuetify.lang.t(`$vuetify.regions.${item.properties.region.toLowerCase()}`) }}</a
              >
            </td>
            <td class="px-0">
              <v-chip
                @click="displayHelp('status')"
                class="text-center fingerPointer"
                :color="getRegionStatusColor(item.properties.status)"
                light
                >{{ getRegionStatusText(item.properties.status) }}</v-chip
              >
            </td>
            <td @click="displayHelp('edition')" class="text-left fingerPointer px-0">
              <a>{{ item.properties.time.replace('h', '\u2006h') }}</a>
            </td>
            <td :style="{ color: getValidityColor(item.properties.valid) }" class="px-0">
              {{ formatDateTime(new Date(item.properties.expirationTime), false) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
    <partners></partners>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { resizeMixin } from '../mixins/resizeMixin'
import { olMapMixin } from '../mixins/olMapMixin'
import { utilityMixin } from '../mixins/utilityMixin'
import { getRegionCssColor } from '../colors'
import Partners from './Partners.vue'
import { ICONS, getImageUrl, getScaledImage } from '../icons'

export default {
  name: 'regions-table',
  mixins: [resizeMixin, olMapMixin, utilityMixin],
  components: {
    Partners,
  },
  computed: {
    ...mapState(['region']),
  },
  mounted() {
    this.$refs.riskSymbol0.insertAdjacentHTML('afterbegin', getScaledImage(ICONS.SYMBOLS[0], 15))
    this.$refs.riskSymbol1.insertAdjacentHTML('afterbegin', getScaledImage(ICONS.SYMBOLS[1], 15))
    this.$refs.riskSymbol2.insertAdjacentHTML('afterbegin', getScaledImage(ICONS.SYMBOLS[2], 15))

    this.$refs.cruxSymbol0.insertAdjacentHTML('afterbegin', getScaledImage(ICONS.CRUXES[0], 15))
    this.$refs.cruxSymbol1.insertAdjacentHTML('afterbegin', getScaledImage(ICONS.CRUXES[1], 15))
    this.$refs.cruxSymbol2.insertAdjacentHTML('afterbegin', getScaledImage(ICONS.CRUXES[2], 15))
  },
  methods: {
    navigateTo: function (area) {
      this.$emit('navigateToAreaView', area)
    },
    getRegionStatusText(status) {
      return this.$vuetify.lang.t(`$vuetify.regionStatus.${status}.short`)
    },
    getRegionStatusColor(status) {
      return getRegionCssColor(status, 'EYES')
    },
    getSymbolImage(risk) {
      return getImageUrl(ICONS.SYMBOLS[risk])
    },
    getCruxImage(level) {
      return getImageUrl(ICONS.CRUXES[level])
    },
    isSummer() {
      var d = new Date()
      var m = d.getMonth()
      return m >= 4 && m <= 9
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('RegionsTable: Display help')
      this.$emit('displayHelp', tag)
    },
    getValidityColor(valid) {
      if (!valid) {
        return this.getWarningColor()
      }
      return 'black'
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-chip {
  margin-top: 1px;
  margin-bottom: 1px;
}
.v-data-table >>> th,
.v-data-table >>> td {
  padding-left: 8px !important;
}
</style>
