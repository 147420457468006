<template>
  <v-container>
    <div class="text-h6 ml-1 mr-1 mt-0 mb-2 justify-left">{{ $vuetify.lang.t(`$vuetify.rudr.title`) }}</div>  
      <v-row class="text ml-1 mr-1 my-0 textDense" :style="{ color: getWarningColor() }">
        <p>
          <span class="font-weight-bold"> {{ $vuetify.lang.t(`$vuetify.attention`) }}</span>
          <span>: </span>
          <span> {{ $vuetify.lang.t(`$vuetify.disclaimer.text.0`) }}</span>
          <!-- <span> {{ $vuetify.lang.t(`$vuetify.disclaimer.text.6`) }}</span> -->
        </p>
      </v-row>
      <v-row class="text ml-1 mr-1 mt-0 mb-4 textDense">{{ $vuetify.lang.t(`$vuetify.rudr.description`) }}</v-row>
      <v-row class="text ml-1 mr-1 mt-0 mb-4 font-weight-medium"><a target="_blank" :href="getInfoLink()">{{ $vuetify.lang.t(`$vuetify.info.title`) }}</a></v-row>
      <v-expansion-panels flat accordion v-model="openedBulletinPanelIndex">
        <v-expansion-panel>
          <v-expansion-panel-header
            style="min-height: unset;"
            :color="panelColor"
            class="subtitle-1 py-1 px-2"
            expand-icon="mdi-menu-down"
            >{{ $vuetify.lang.t(`$vuetify.rudr.forecast.title`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content :color="panelColor" class="pt-5">

            <v-row class="center mx-1">
              <v-col @click="displayHelp('dangerLevel')" class="col-2 my-auto fingerPointer">
                <!-- https://icon-sets.iconify.design/mdi/ -->
                <v-icon size="36px">mdi-hazard-lights</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.forecast.dangerLevel`) }}: {{ displayDangerLevel()}}
                  </div>
                </v-row>
                <v-row id="dangerSlider" class="center">
                  <v-slider
                    min=1
                    max=4
                    step=0.333333333333333333333333333
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="drdl"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row>          

            <v-row class="center mx-1">
              <v-col @click="displayHelp('coreZone')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">$iconElevationGain</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">
                    {{ $vuetify.lang.t(`$vuetify.rudr.forecast.critEle`) }}:
                    {{ getSliderText(critElevations, [0, 5000], 'm') }}              
                  </div>
                </v-row>
                <v-row class="center">
                  <v-range-slider
                    min=0
                    max=5000
                    step=200
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="critElevations"
                  >
                  </v-range-slider>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="center mx-1">
              <v-col @click="displayHelp('coreZone')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-compass</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">
                     {{ $vuetify.lang.t(`$vuetify.rudr.forecast.critExpos`) }}      
                  </div>
                </v-row>
                <v-row class="center">
                  <v-col class="col-12 py-0 pl-0">
                    <critical-aspects
                      :aspects="critAspects"
                      :isDynamic="true"
                      width="120px"
                      height="120px"                      
                      @aspectChanged="aspectChanged"
                    >
                    </critical-aspects>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row class="center my-5">
        <v-divider></v-divider>
      </v-row>

      <v-expansion-panels flat accordion v-model="openedSettingsPanelIndex">
        <v-expansion-panel>
          <v-expansion-panel-header
            style="min-height: unset;"
            :color="panelColor"
            class="subtitle-1 py-1 px-2"
            expand-icon="mdi-menu-down"
            >{{ $vuetify.lang.t(`$vuetify.rudr.settings.title`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content :color="panelColor" class="pt-5">

            <v-row class="center mx-1">
              <v-col @click="displayHelp('degreeOfFreedom')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-information</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.settings.degreeOfFreedom`) }}: {{ degreeOfFreedom }} %
                  </div>
                </v-row>
                <v-row class="center">
                  <v-slider
                    min=0
                    max=100

                    hide-details="false"
                    v-model="degreeOfFreedom"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row>  

            <v-row class="center mx-1">
              <v-col @click="displayHelp('avalancheTerrainWeight')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-information</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.settings.avalancheTerrainWeight`) }}: {{ avalancheTerrainWeight }} %
                  </div>
                </v-row>
                <v-row class="center">
                  <v-slider
                    min=0
                    max=100
                    step=5
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="avalancheTerrainWeight"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row> 

            <v-row class="center mx-1">
              <v-col @click="displayHelp('movementWeight')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-information</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.settings.movementWeight`) }}: {{ movementWeight }} %
                  </div>
                </v-row>
                <v-row class="center">
                  <v-slider
                    min=0
                    max=100
                    step=5
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="movementWeight"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row>      

            <v-row class="center mx-1">
              <v-col @click="displayHelp('horizontalMovingCost')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-information</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.settings.horizontalMovingCost`) }}: {{ horizontalMovingCost }} %
                  </div>
                </v-row>
                <v-row class="center">
                  <v-slider
                    min=0
                    max=100
                    step=5
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="horizontalMovingCost"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row> 

            <v-row class="center mx-1">
              <v-col @click="displayHelp('elevationGainCost')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-information</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.settings.elevationGainCost`) }}: {{ elevationGainCost }} %
                  </div>
                </v-row>
                <v-row class="center">
                  <v-slider
                    min=0
                    max=100
                    step=5
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="elevationGainCost"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row> 

            <v-row class="center mx-1">
              <v-col @click="displayHelp('elevationLossCost')" class="col-2 my-auto fingerPointer">
                <v-icon size="36px">mdi-information</v-icon>
              </v-col>
              <v-col class="col-10 py-0 pl-1">
                <v-row class="center mt-0">
                  <div class="body-2 font-weight-regular text--secondary ml-2">{{ $vuetify.lang.t(`$vuetify.rudr.settings.elevationLossCost`) }}: {{ elevationLossCost }} %
                  </div>
                </v-row>
                <v-row class="center">
                  <v-slider
                    min=0
                    max=100
                    step=5
                    ticks="always"
                    tickSize=5
                    hide-details="false"
                    v-model="elevationLossCost"
                  >
                  </v-slider>
                </v-row>
              </v-col>
            </v-row>                        

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row class="center mt-5">
        <v-divider></v-divider>
      </v-row>

      <v-row class="left mx-0 mt-6">
        <v-file-input
          accept="application/gpx+xml"
          :label="$vuetify.lang.t(`$vuetify.rudr.buttons.loadGpx`)"    
          v-model="chosenFile"
          @change="loadGpxFile()"
        ></v-file-input>
      </v-row>

       <v-row class="left mx-0 mt-0 mb-0">
        <v-checkbox
          hide-details        
          :label="this.$vuetify.lang.t(`$vuetify.rudr.buttons.cutGpx`)"
          v-model="cutGpx"
          @click="loadGpxFile()"
          class="mt-0"
        >
        </v-checkbox>
      </v-row>    
      <v-row class="center my-4">
        <v-divider></v-divider>
      </v-row>      
       <v-row class="left mx-0 mt-0 mb-0">
        <v-checkbox
          hide-details        
          :label="this.$vuetify.lang.t(`$vuetify.rudr.buttons.respectProtAreas`)"
          v-model="respectProtAreas"
          class="mt-0"
        >
        </v-checkbox>
      </v-row>         
      <v-row class="center mt-4">
        <v-divider></v-divider>
      </v-row>


<!--       <v-row class="left mx-0">
        <v-col class="pt-3 pb-0 pr-0">
          <v-btn 
            class="text-uppercase" 
            color="primary" 
            text 
            :disabled="false"
            @click="loadGpxFile()">{{ $vuetify.lang.t(`$vuetify.rudr.buttons.loadGpx`) }}</v-btn>
        </v-col>
      </v-row>   -->

      <v-row class="left mx-0">
        <v-col class="pt-3 pb-0 pr-0">
          <v-btn 
            class="text-uppercase" 
            color="primary" 
            text 
            :disabled="processButtonDisabled"
            @click="process()">{{ $vuetify.lang.t(`$vuetify.rudr.buttons.start`) }}</v-btn>
        </v-col>
      </v-row>  

      <v-row class="left mx-0">
        <v-col class="pt-3 pb-0 pr-0">
          <v-btn 
            class="text-uppercase" 
            color="primary" 
            text 
            :disabled="processButtonDisabled"                     
            @click="copyLink()">{{ $vuetify.lang.t(`$vuetify.rudr.buttons.copyLink`) }}</v-btn>
        </v-col>
      </v-row>                

      <v-row class="left mx-0">
        <v-col class="pt-3 pb-0 pr-0">
          <v-btn 
            class="text-uppercase" 
            color="primary" 
            text   
            @click="resetInput()">{{ $vuetify.lang.t(`$vuetify.rudr.buttons.reset`) }}</v-btn>
        </v-col>
      </v-row>          

      <v-row class="center mt-5">
        <v-divider></v-divider>
      </v-row>

  </v-container>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import DataService from '@/services/DataService'
import { createGuid, log, getInfoServerLink, constDefaultRudrInput } from '../utilities'
import NProgress from 'nprogress'
import CriticalAspects from '../components/CriticalAspects.vue'
import {containsExtent} from 'ol/extent';
import store from '@/store/store'

export default {
  name: 'rating-table',
  mixins: [utilityMixin],
  components: {
    CriticalAspects,
  },
  props: {
    userRoute: {
      type: Object,
      default: null
    },
    timestamp: {
      type: Number,
      default: 0,
    },       
  },  
  data: function () {
    return {
      drdl: constDefaultRudrInput.drdl,
      critElevations: constDefaultRudrInput.critElevations,
      critAspects: constDefaultRudrInput.critAspects,

      degreeOfFreedom: constDefaultRudrInput.degreeOfFreedom,
      avalancheTerrainWeight: constDefaultRudrInput.avalancheTerrainWeight,
      movementWeight: constDefaultRudrInput.movementWeight,
      horizontalMovingCost: constDefaultRudrInput.horizontalMovingCost,
      elevationGainCost: constDefaultRudrInput.elevationGainCost,
      elevationLossCost: constDefaultRudrInput.elevationLossCost,
      respectProtAreas: constDefaultRudrInput.respectProtAreas,

      processButtonDisabled: true,
      panelColor: 'rgba(0, 0, 0, 0)',
      openedSettingsPanelIndex: 0,
      openedBulletinPanelIndex: 0,

      cutGpx: true,
      chosenFile: null,

      // On the backend look for consts MIN_ROUTE_LENGTH, MAX_ROUTE_LENGTH 
      maxRouteLen: 30000,
      minRouteLen: 100,   
      
      // If a route is loaded with getRetrieveRouteRating we want to make sure degreeOfFreedom is not adapted
      adaptDegreeOfFreedom: true
    }
  },
  watch: {
    userRoute: function () {
      this.userRouteChanged()
    },
  },  
  methods: {
    displayHelp(tag) {
      this.$emit('displayHelp', tag)
    },   
    displayMessage(type, message) {
      this.$emit('displayMessage', type, message)
    },      
    getSliderText(range, defaultRange, unit) {
      if (range[1] == defaultRange[1] && range[0] == defaultRange[0]) {
        return this.$vuetify.lang.t(`$vuetify.route.all`)
      }
      var text = `${range[0]}`
      text += '\u200B\u2013\u200B'
      text += `${range[1]}\u2006${unit}`
      return text
    },
    process() {
      const len = this.userRoute.getLength()

      if ((len > this.maxRouteLen) || (len < this.minRouteLen)) {
        var text = this.$vuetify.lang.t(`$vuetify.message.texts.3`)
        text = text.replace('xxx', this.maxRouteLen/1000)
        text = text.replace('yyy', this.minRouteLen)
        this.displayMessage(1, text)
        return
      }

      if (!this.isUserRouteInExtent()) {
        this.displayMessage(1, this.$vuetify.lang.t(`$vuetify.message.texts.4`))
        return
      }

      var coordinates = this.userRoute.getCoordinates()
      coordinates = this.roundCoordinates(coordinates)
      NProgress.start()
      this.$emit('routeRated', null, true)

      var request = {
        settings: {
          avalancheTerrainWeight: this.avalancheTerrainWeight,
          degreeOfFreedom: this.degreeOfFreedom,
          horizontalMovingCost: this.horizontalMovingCost,
          elevationGainCost: this.elevationGainCost,
          elevationLossCost: this.elevationLossCost,
          movementWeight: this.movementWeight,
          epsg: 3857,
          respectProtAreas: this.respectProtAreas,
        },
        userRoute: {
          epsg: 3857,
          gpx: (this.chosenFile != null),
          timestamp: (this.chosenFile == null || this.timestamp == null)? 0: this.timestamp,
          coordinates: coordinates
        },
        user: {
          userGuid : this.getGuid(),
          language: this.$vuetify.lang.current
        },
        avalancheBulletinInfo: {
          edition: "0001-01-01T00:00:00",
          jsonLink: null,
          webLink: null,
          type: 0,
          detailedDangerlevel: this.drdl,
          minElevation: this.critElevations[0],
          maxElevation: this.critElevations[1],
          aspects: this.critAspects          
        },
        token: this.calcToken(),
        key: this.getKey()
      }

      // https://github.com/axios/axios#note-commonjs-usage
      // https://medium.com/@masnun/handling-timeout-in-axios-479269d83c68
      var that = this
      DataService.postCalcRouteRating(request).then((response) => {
        NProgress.done()
        that.onResponseReceived(response.data, true)
      }).catch(error => {
        NProgress.done()
        log("process(): Error = " + error.message)
        that.onResponseReceived(null, true)
      })
    },
    resetInput() {
      this.drdl = constDefaultRudrInput.drdl;
      this.critElevations = constDefaultRudrInput.critElevations;
      this.critAspects = constDefaultRudrInput.critAspects;

      this.degreeOfFreedom = constDefaultRudrInput.degreeOfFreedom;
      this.avalancheTerrainWeight = constDefaultRudrInput.avalancheTerrainWeight;
      this.movementWeight = constDefaultRudrInput.movementWeight;
      this.horizontalMovingCost = constDefaultRudrInput.horizontalMovingCost;
      this.elevationGainCost = constDefaultRudrInput.elevationGainCost;
      this.elevationLossCost = constDefaultRudrInput.elevationLossCost;
      this.respectProtAreas = constDefaultRudrInput.respectProtAreas;
    },
    copyLink() {
      navigator.clipboard.writeText(window.location.href);
    },
    roundCoordinates(coordinates){
      for (let i = 0; i < coordinates.length; i++) {
        coordinates[i] = this.roundCoordinate(coordinates[i])
      }
      return coordinates
    },
    roundCoordinate(coordinate) {

      var roundedCoordinate = new Array(coordinate.length);
      for (let i = 0; i < coordinate.length; i++) {
        roundedCoordinate[i] = this.roundNumber(coordinate[i])
      }
      // If there are 4 coordinates, the last one is a timestamp in Unix: Don't round
      if (coordinate.length == 4) {
        roundedCoordinate[3] = coordinate[3];
      }

      return roundedCoordinate
    },
    roundNumber(number) {
      var roundedNumber = Math.trunc(number)
      let text = roundedNumber.toString();
      let sum = 0
      for (var i = 0; i < text.length; i++) {
        sum += 13 * Number(text.charAt(i))
      }
      sum = sum % 100
      sum = sum / 100
      return roundedNumber + sum
    },
    isUserRouteInExtent() {
      var maxExtent = [558000, 5375000, 1774000, 6139000]
      return containsExtent(maxExtent, this.userRoute.getExtent())
    },
    onResponseReceived(response, isNew) {
      if (response == null) {
        this.$emit('routeRated', null, isNew)
        this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.1`))
        return
      }

      log("onResponseReceived(): Status = " + response.status.statusCode)

      if (response.status.statusCode == 200) {
        this.$emit('routeRated', response, isNew)

        if (typeof response.avalancheBulletinInfo != 'undefined' && response.avalancheBulletinInfo != null) {
          var bulletinInfo = response.avalancheBulletinInfo
          this.drdl = bulletinInfo.detailedDangerlevel
          this.critElevations = [bulletinInfo.minElevation,  bulletinInfo.maxElevation]
          this.critAspects = bulletinInfo.aspects
        }

        if (typeof response.settings != 'undefined' && response.settings != null) {
          var settings = response.settings
          this.degreeOfFreedom = settings.degreeOfFreedom
          this.avalancheTerrainWeight = settings.avalancheTerrainWeight
          this.movementWeight = settings.movementWeight
          this.horizontalMovingCost = settings.horizontalMovingCost
          this.elevationGainCost = settings.elevationGainCost
          this.elevationLossCost = settings.elevationLossCost
          this.respectProtAreas = settings.respectProtAreas;
        }

        if (!isNew) {
          this.adaptDegreeOfFreedom = false
        }
        return
      }

      this.$emit('routeRated', null, isNew)
      
      if (response.status.statusCode == 400) {
        this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.5`))
        return
      }
      if (response.status.statusCode == 401) {
        this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.6`))
        return
      } 
      if (response.status.statusCode == 404) {
        this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.8`))
        return
      }    
      if (response.status.statusCode == 503) {
        this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.11`))
        return
      }          
      this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.1`))    
    },
    userRouteChanged() {
      this.processButtonDisabled = (this.userRoute == null)
      if (this.userRoute == null) {
        this.chosenFile = null
        this.degreeOfFreedom = 50
        return
      }

      // https://www.geogebra.org
      let len = this.userRoute.getCoordinates().length
      let minValue = 5
      let maxValue = 100
      if (this.adaptDegreeOfFreedom) {
        this.degreeOfFreedom = minValue + 2*(maxValue-minValue)/len
      }
      this.adaptDegreeOfFreedom = true
    },
    displayDangerLevel() {
      const rdl = Math.round(this.drdl);
      var text = this.$vuetify.lang.t(`$vuetify.bulletin.dangerLevels.${rdl}`)
      text += ' (' + rdl;
      
      var delta = Math.round(3*(this.drdl-rdl))
      if (delta == -1) {
        text += '-)'
      }
      if (delta == 0) {
        text += '=)'
      }
      if (delta == 1) {
        text += '+)'
      }            
      return text;
    },
    aspectChanged(aspects) {
      this.critAspects = aspects
    },
    loadGpxFile() {
      if (this.chosenFile == null) return
      this.$emit('fileLoaded', this.chosenFile, this.cutGpx)     
    },
    calcToken() {
      var time = Math.round(Date.now() / 60000)
      // Encode with key
      time = time ^ 62075914
      return time.toString()
    },
    getKey() {
      let key = this.$route.query.key
      return key
    },
    getGuid() {
      var guid = store.state.localState.guid
      if (guid == 'undefined' || guid == null) {
        guid = createGuid()
        store.dispatch('localState/setGuid', {
          guid: guid,
        })
      }
      return guid
    },
    getInfoLink() {
      return getInfoServerLink() + '/index.php/rudr-de';
    }

  },
  mounted() {
    let guid = this.$route.query.guid 
    if (typeof guid != 'undefined' && guid != null) {
      let timestamp = this.$route.query.timestamp
      NProgress.start()
      this.$emit('routeRated', null, false)
      var that = this
      DataService.getRetrieveRouteRating(guid, timestamp).then((response) => {
        NProgress.done()
        that.onResponseReceived(response.data, false)
      }).catch(error => {
        NProgress.done()
        log("process(): Error = " + error.message)
        that.onResponseReceived(null, false)
      })   
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-expansion-panels {
    border: 1px solid lightgray;
  }

  .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {

    padding-left: 0px;
    padding-right: 18px;
  }

  /* The avalanche risk slider */
#dangerSlider >>> .v-slider {
  background-image: linear-gradient(
    to right,
    rgba(170, 255, 127, 1),
    rgba(255, 255, 0, 1),
    rgba(255, 170, 0, 0.8),
    rgba(255, 0, 0, 0.5)
  );
  background-size: 100% 10%;
  background-position: 50% 50%;
}

#dangerSlider >>> .v-slider__track-fill {
  display: none !important;
}

#dangerSlider >>> .v-slider__track-background {
  display: none !important;
}

</style>
