<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.route.snowAmount`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      :stroke="foregroundColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-width="1.33px"
      d="M10.74,9.51H3.8"
    />
    <path
      :stroke="foregroundColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-width="1.33px"
      d="M10.74,36.23H3.8"
    />
    <line
      :stroke="foregroundColor"
      stroke-miterlimit="10"
      stroke-width="2.33px"
      x1="7.3"
      y1="9.51"
      x2="7.3"
      y2="36.81"
    />
    <path
      :fill="foregroundColor"
      stroke-miterlimit="10"
      stroke-width="0"
      d="M37.09,21.93H31.93l4.1-4.1a1.17,1.17,0,1,0-1.66-1.66h0L28.6,21.93H26.5v-2.1l5.76-5.76a1.17,1.17,0,0,0-1.66-1.66l-4.1,4.1V11.34a1.18,1.18,0,0,0-2.35,0v5.17l-4.1-4.1a1.18,1.18,0,1,0-1.67,1.66h0l5.77,5.76v2.1h-2.1l-5.77-5.76a1.17,1.17,0,1,0-1.66,1.66h0l4.1,4.1H13.56a1.18,1.18,0,0,0,0,2.36h5.16l-4.1,4.1a1.17,1.17,0,0,0,1.66,1.66h0l5.77-5.76h2.1v2.1l-5.77,5.76a1.17,1.17,0,0,0,0,1.66,1.18,1.18,0,0,0,1.67,0h0l4.1-4.1v5.17a1.18,1.18,0,0,0,2.35,0V29.71l4.1,4.1a1.17,1.17,0,1,0,1.66-1.66L26.5,26.39v-2.1h2.1l5.76,5.76a1.18,1.18,0,0,0,1.67,0,1.17,1.17,0,0,0,0-1.66l-4.1-4.1h5.16a1.18,1.18,0,0,0,0-2.36Z"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconSnowAmount',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
