<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" :width="width" :height="height">
    <title></title>
    <g stroke-width="1" stroke="gray" fill="black" class="fingerPointer">      
      <!-- Start at the peak then counter clock -->
      <path d="M60 10 L35 60 L85 60 Z" stroke-width="none" :fill-opacity="highOpacity"/>
      <path d="M85 60 L35 60 L10 110 L110 110 Z" stroke-width="none" :fill-opacity="lowOpacity"/>
      <path d="M60 10 L10 110 L110 110 Z" fill="none" stroke-width="5"/>
      <line x1="10" y1="60" x2="200" y2="60" stroke-width="5"  />
    </g>
    <text x="100" y="50" text-anchor="left">{{ elevationText}}</text>

  </svg>
</template>

<script>

import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'critical-elevations',
  props: {
    loele: {
      type: Number,
      default: 0,
    },
    hiele: {
      type: Number,
      default: 10000,      
    },   
    minloele: {
      type: Number,
      default: 1400,         
    },     
    width: {
      type: [Number, String],
      default: 120,
    },
    height: {
      type: [Number, String],
      default: 120,
    },    
  },
  mixins: [utilityMixin],
  data: () => ({ 
    highOpacity: 1,
    lowOpacity: 0,
    elevationText: '1000 m'
  }),
  watch: {
    loele: function () {
      this.updateView()     
    },
    hiele: function () {
      this.updateView()     
    },    
  },  
  methods: {
    updateView() {
      this.highOpacity = (this.hiele == 10000)?1:0 
      this.lowOpacity = (this.loele == 0)?1:0

      var elevation = (this.hiele == 10000)?this.loele:this.hiele
      if (elevation == 0)
      {
        // From bottom up
        if (this.minloele == 0)
        {
          this.elevationText = this.$vuetify.lang.t(`$vuetify.route.all`)
        }
        else
        {
          elevation = this.minloele
          this.elevationText = `${elevation} m`
        }
      }
      else
      {
        this.elevationText = `${elevation} m`
      }
    }
  },
  created() {
    this.updateView()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

text {
  font-size: 28px;
  font-family: Roboto-Medium, Roboto; 
  font-weight: 700;
}

</style>
