<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.route.stop`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      :fill="foregroundColor"
      d="M32.22,19.83a1.61,1.61,0,0,1,2.21-.6,1.67,1.67,0,0,1,.61.6L45.23,37.66a1.63,1.63,0,0,1-.6,2.22,1.67,1.67,0,0,1-.81.22H23.44a1.63,1.63,0,0,1-1.63-1.62,1.58,1.58,0,0,1,.22-.82Z"
    />
    <path
      :fill="foregroundColor"
      d="M24.67,14.14a1.62,1.62,0,0,1,2.22-.6,1.57,1.57,0,0,1,.6.6L37.68,32a1.63,1.63,0,0,1-.59,2.22,1.67,1.67,0,0,1-.82.21H15.89a1.63,1.63,0,0,1-1.63-1.62,1.67,1.67,0,0,1,.22-.81Z"
    />
    <path
      :fill="foregroundColor"
      :stroke="backgroundColor"
      stroke-miterlimit="10"
      stroke-width="1.67px"
      d="M16.3,12.47a1.62,1.62,0,0,1,2.2-.64,1.64,1.64,0,0,1,.64.64L33.42,38.34a1.61,1.61,0,0,1-.63,2.2,1.66,1.66,0,0,1-.79.2H3.43A1.61,1.61,0,0,1,1.8,39.13a1.66,1.66,0,0,1,.2-.79Z"
    />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconSummit',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
