<template>
  <div>
    <app-header @displayHelp="displayHelp" @dispose="dispose"></app-header>
    <div v-resize="onResize">
      <v-container v-if="!isMobile" fluid>
        <v-row>
          <v-col class="pa-0">
            <div id="map1" class="map"></div>
          </v-col>
          <v-col class="pa-0 info">
            <track-info
              :trackData="trackData"
              :avalancheData="avalancheData"
              :area="area"
              :snowDate="symbols.properties.snow"
              :destPoint="destPoint"
              @displayHelp="displayHelp"
              @swisstopoOverlayChanged="swisstopoOverlayChanged"
            >
            </track-info>
          </v-col>
        </v-row>
      </v-container>

      <v-tabs slider-size="3" v-if="isMobile" v-model="tabs" grow>
        <v-tab ripple @change="updateMapTab">{{ $vuetify.lang.t('$vuetify.tabs.map') }}</v-tab>
        <v-tab ripple>
          {{ $vuetify.lang.t('$vuetify.tabs.trackDetails') }}
        </v-tab>
        <v-tabs-items v-model="tabs" touchless>
          <v-tab-item eager>
            <div v-resize="onResize" :style="{ height: contentHeight + 'px' }" id="map2" class="map"></div>
          </v-tab-item>
          <v-tab-item>
            <track-info
              :trackData="trackData"
              :avalancheData="avalancheData"
              :area="area"
              :snowDate="symbols.properties.snow"
              :destPoint="destPoint"
              @displayHelp="displayHelp"
              @swisstopoOverlayChanged="swisstopoOverlayChanged"
            >
            </track-info>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <div
      v-if="status != 1 && agreed && warningEnabled"
      class="font-weight-bold fingerPointer"
      id="statusWarningId"
      :style="{ color: getWarningColor() }"
      @click="disableWarning()"
    >
      <span>{{ $vuetify.lang.t(`$vuetify.regionStatus.${status}.medium`) }} </span>
      <span>: </span>
      <span>{{ $vuetify.lang.t(`$vuetify.regionStatus.${status}.validity`) }} </span>
    </div>
    <disclaimer-dialog ref="disclaimer" style="z-index=200"></disclaimer-dialog>
    <webcam-dialog ref="webcam" style="z-index=200"></webcam-dialog>
    <accident-dialog ref="accident" style="z-index=200"></accident-dialog>
    <protected-area-dialog ref="protectedArea" style="z-index=200"></protected-area-dialog>
    <help-dialog ref="help" style="z-index=200"></help-dialog>
    <message-dialog ref="message" style="z-index=200"></message-dialog>
    <overlay-dialog ref="overlay" style="z-index=200"></overlay-dialog>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import TrackInfo from '../components/TrackInfo.vue'
import DisclaimerDialog from '../components/DisclaimerDialog.vue'
import WebcamDialog from '../components/WebcamDialog.vue'
import AccidentDialog from '../components/AccidentDialog.vue'
import ProtectedAreaDialog from '../components/ProtectedAreaDialog.vue'
import HelpDialog from '../components/HelpDialog.vue'
import MessageDialog from '../components/MessageDialog.vue'
import OverlayDialog from '../components/OverlayDialog.vue'
import { olMapMixin } from '../mixins/olMapMixin'
import { resizeMixin } from '../mixins/resizeMixin'
import { refreshMixin } from '../mixins/refreshMixin'
import store from '@/store/store'
import NProgress from 'nprogress'
import { utilityMixin } from '../mixins/utilityMixin'
import { createGuid, loggingStart, loggingMark, loggingStop, addExtentMargin, getMapServerLink } from '../utilities'
import DataService from '@/services/DataService'
import { collections } from '../variables'

export default {
  name: 'track-view',
  mixins: [olMapMixin, resizeMixin, utilityMixin, refreshMixin],
  components: {
    AppHeader,
    TrackInfo,
    DisclaimerDialog,
    HelpDialog,
    MessageDialog,
    OverlayDialog,
    WebcamDialog,
    AccidentDialog,
    ProtectedAreaDialog
  },
  data: () => ({
    area: '',
    id: 0,
    tabs: null,
    trackData: null,
    avalancheData: null,
    destPoint: [0, 0],

    regions: null,
    symbols: null,
    segments: null,
    webCams: null,
    accidents: null,

    extentMargin: 5,
    overlaySelector: 1,

    status: null,
    agreed: false,
    warningEnabled: true,

    singleClick: null
  }),
  watch: {
    trackData: function () {
      var risk = Number(this.trackData.properties.risk)
      const stopEle = this.trackData.properties.stopEle
      const stop = this.trackData.properties.stop
      this.setHeader(stop, stopEle, risk)
    },
  },
  methods: {
    rejectDisclaimer() {
      this.dispose()
      this.$router.push({
        name: 'regions-view',
      })
    },
    acceptDisclaimer() {
      this.agreed = true
    },
    filterCurrentRoute(feature) {
      return this.id == feature.get('id')
    },
    filterCurrentRouteWithType(feature) {
      return this.id == feature.get('id') && 2 == feature.get('type')
    },
    onRouteChanged(symbolFeature) {
      this.id = symbolFeature.properties.id
      this.trackData = symbolFeature
      this.updateStatistics()

      // this.$router.replace would reload the whole page, which is not needed here!
      history.replaceState(null, '', `track-view?area=${this.area}&id=${this.id}`)
    },
    onWebcamClicked(webcamId) {
      var feature = store.state[this.area].webCams.features.find((item) => item.properties.id === webcamId)
      this.$refs.webcam.open(webcamId, feature.properties.name, feature.geometry.coordinates[2])
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('TrackView: Display help')
      this.$refs.help.open(tag)
    },
    displayMessage(type, message) {
      // eslint-disable-next-line no-console
      console.log('TrackView: Display help')
      this.$refs.message.open(type, message)
    }, 
    dispose() {  
      this.singleClick = null
      this.disposeMap()
    },  
    swisstopoOverlayChanged(overlaySelector) {
      // eslint-disable-next-line no-console
      console.log('TrackView: swisstopoOverlayChanged to ' + overlaySelector)
      this.overlaySelector = overlaySelector
      store.dispatch(`${this.area}/setOverlays`, {
        selector: overlaySelector,
      })
    },
    onLayerSwitcher() {
      this.$refs.overlay.open(this.map.getLayers())
    },
    onClick(e) {
      
      var features = e.target.getFeatures()
      var length = features.getLength()
      if (length == 0) return
      var feature = features.item(0)
      var type = feature.get('ltype')
      if (typeof type == 'undefined' || type == null) {     
        let layer = this.singleClick.getLayer(feature);
        type = layer.get('ltype')
      }

      if (type == this.layerType.SYMBOLCLUSTER) {
        const clusteredFeatures = feature.get('features')
        if (clusteredFeatures.length != 1) {
          extent = this.calcFeaturesExtent(clusteredFeatures, 5)
          this.zoomTo(extent, 8, 16)
          return
        }
        feature = clusteredFeatures[0]
      }
      if (type == this.layerType.ROUTES || type == this.layerType.SEGMENTS || type == this.layerType.SYMBOLCLUSTER) {
        features.clear()
        var id = feature.get('id')

        var that = this
        DataService.getSingleSegment(collections[this.area], id).then((response) => {
          that.segmentDataReceived(response.data)
        })
        DataService.getSingleCruxes(collections[this.area], id).then((response) => {
          that.cruxesDataReceived(response.data)
        })      
        DataService.getSingleSkiDepot(collections[this.area], id).then((response) => {
          that.skiDepotDataReceived(response.data)
        })          

        var symbolFeature = store.state[this.area].symbols.features.find((item) => item.properties.id === id)
        if (symbolFeature == 'undefined' || symbolFeature == null) {
          return
        }

        this.id = id

        var extent = this.calcSymbolExtent(symbolFeature)

        this.zoomTo(extent, 8, 16)
        this.onRouteChanged(symbolFeature)
        return
      }
      if (type == this.layerType.WEBCAMS) {
        var webcamId = feature.get('id')
        var geometry = feature.get('geometry')
        var coordinates = geometry.getCoordinates()
        var name = feature.get('name')
        this.$refs.webcam.open(webcamId, name, coordinates[2])
        features.clear()
        return
      }
      if (type == this.layerType.CRUXES) {
        this.displayHelp('cruxes')
        features.clear()
        return
      }
      if (type == this.layerType.SKIDEPOTS) {
        this.displayHelp('skiDepots')
        features.clear()
        return
      }
      if (type == this.layerType.ACCIDENTS) {        
        this.$refs.accident.open(feature)
        features.clear()
        return
      }   
      if (type == this.layerType.PROTECTEDAREAS) {
        this.$refs.protectedArea.open(feature)
        features.clear()
        return
      }         
    },
    onMouseOver(e) {
      var features = e.target.getFeatures()
      var risk
      var stopEle
      var stop
      if (features.getLength() == 0) {
        risk = Number(this.trackData.properties.risk)
        stopEle = this.trackData.properties.stopEle
        stop = this.trackData.properties.stop
        this.setHeader(stop, stopEle, risk)
        return
      }

      var feature = features.item(0)
      var type = feature.get('ltype')

      if (type == this.layerType.ROUTES) {
        this.routeMouseOver(type, feature)
        return
      }
      
      if (type == this.layerType.SYMBOLCLUSTER) {
        const clusteredFeatures = feature.get('features')
        if (clusteredFeatures.length == 1) {   
          this.routeMouseOver(this.layerType.SYMBOLCLUSTER, clusteredFeatures[0])
        }
        return
      }  
    },
    routeMouseOver(type, feature) {
        var risk
        var stopEle
        var stop
        if (type == this.layerType.SYMBOLCLUSTER) {
          risk = feature.get('risk')
          stopEle = feature.get('stopEle')
          stop = feature.get('stop')
        } else {
          var properties = this.symbols.features.find((item) => item.properties.id === feature.get('id')).properties
          risk = Number(properties.risk)
          stopEle = properties.stopEle
          stop = properties.stop
        }

        var title = `${stop} (${stopEle} m)`
        this.updateHeader(title, this.status, risk)      
    },    
    setHeader(stop, stopEle, risk) {
      var title = `${stop} (${stopEle} m)`
      this.updateHeader(title, this.status, risk)
    },
    getStringRisk() {
      var risk = this.trackData.properties.risk
      if (risk < 0.0) {
        risk = 0.0
      }
      if (risk >= 3.0) {
        risk = 2.9
      }
      risk = Math.floor(risk * 10) // 00..29
      var riskString = this.pad(risk, 2)
      return riskString[0] + '-' + riskString[1]
    },
    updateStatistics() {
      var guid = store.state.localState.guid
      if (guid == 'undefined' || guid == null) {
        guid = createGuid()
        store.dispatch('localState/setGuid', {
          guid: guid,
        })
      }

      var url = 'index.php/riskhit'
      url += this.getStringRisk()
      url += '?' + 'GUID' + '=' + guid
      url += '&' + 'COLLECTION' + '=' + collections[this.area]
      url += '&' + 'ID' + '=' + this.id
      url += '&' + 'RISKIND' + '=' + this.trackData.properties.risk.toString()
      url += '&' + 'GURUVER' + '=' + '2.2'
      url += '&' + 'LANG' + '=' + this.$vuetify.lang.current

      DataService.postStatistics(url).then((response) => {
        // eslint-disable-next-line no-console
        console.log('updateStatistics(): Status=' + response.status)
      })
    },
    disableWarning() {
      this.warningEnabled = false
      this.displayHelp('status')
    },
    segmentDataReceived(segments) {
      var segmentsVectorLayer = this.createVectorLayer(segments, this.layerType.SEGMENTS)
      this.removeVectorLayer(this.layerType.SEGMENTS)
      this.addVectorLayer(segmentsVectorLayer, this.layerType.ROUTES)

      // Store the start point of the route
      this.destPoint = segments.features[0].geometry.coordinates[0][0] 
    },
    cruxesDataReceived(cruxes) {
      var cruxesVectorLayer = this.createVectorLayer(cruxes, this.layerType.CRUXES)
      this.removeVectorLayer(this.layerType.CRUXES)
      this.addVectorLayer(cruxesVectorLayer, this.layerType.ACCIDENTS)
    },   
    skiDepotDataReceived(skiDepot) {        
      this.removeVectorLayer(this.layerType.SKIDEPOTS)
      var sdtype = skiDepot.features[0].properties.type
      if (sdtype == 2) {
        var skiDepotVectorLayer = this.createVectorLayer(skiDepot, this.layerType.SKIDEPOTS)
        this.addVectorLayer(skiDepotVectorLayer, this.layerType.ACCIDENTS)
      }
    },  
    // Display onlie routes, that are contained in symbols
    filterValidRoutes(feature) {
      var id = feature.get('id')
      var symbolFeature = this.symbols.features.find((item) => item.properties.id === id)
      return (symbolFeature != null)
    },        
    calcSymbolExtent(symbolFeature) {
      var xCenter = symbolFeature.geometry.coordinates[0]
      var yCenter = symbolFeature.geometry.coordinates[1]

      var deltaExtent = symbolFeature.properties.extent
      var extent = [deltaExtent[0] + xCenter, deltaExtent[1] + yCenter, deltaExtent[2] + xCenter, deltaExtent[3] + yCenter]

      extent = addExtentMargin(extent, this.extentMargin)
      return extent
    },   
  },
  created() {
    loggingMark('TrackView.created(): Enter')
    this.area = this.$route.query.area

    this.regions = store.state.region.regions
    this.symbols = store.state[this.area].symbols

    this.status = this.regions.features.find(
      (region) => region.properties.region.toLocaleLowerCase() === this.area
    ).properties.status

    this.id = Number(this.$route.query.id)

    var that = this
    DataService.getSingleSegment(collections[this.area], this.id).then((response) => {
      that.segmentDataReceived(response.data)
    })
    DataService.getSingleCruxes(collections[this.area], this.id).then((response) => {
      that.cruxesDataReceived(response.data)
    })    
    DataService.getSingleSkiDepot(collections[this.area], this.id).then((response) => {
      that.skiDepotDataReceived(response.data)
    })     

    this.trackData = this.symbols.features.find((item) => item.properties.id === this.id)
    this.updateStatistics()
    this.avalancheData = store.state[this.area].bulletin

    this.webCams = store.state[this.area].webCams
    this.accidents = store.state[this.area].accidents
    loggingMark('TrackView.created(): Data ready')

    var symbolsVectorLayer = this.createClusteredSymbolLayer(this.adjustSymbolGeometry(this.symbols), this.layerType.SYMBOLCLUSTER)

    var collection = collections[this.area]
    var server = getMapServerLink();
    var url = `${server}/${collection}_Routes.tms?x={x}&y={y}&z={z}` 
    var routesVectorLayer = this.createVectorTilesLayer(url, this.layerType.ROUTES, this.filterValidRoutes)

    url = `${server}/AP_SG_PA_MVT.tms?x={x}&y={y}&z={z}`
    var protectedAreasLayer = this.createVectorTilesLayer(url, this.layerType.PROTECTEDAREAS, null, false)

    var webCamsVectorLayer = this.createVectorLayer(this.webCams, this.layerType.WEBCAMS)
    var accidentsVectorLayer = this.createVectorLayer(this.accidents, this.layerType.ACCIDENTS)
    loggingMark('TrackView.created(): Vector layers created')

    var extent = this.calcSymbolExtent(this.trackData)

    loggingMark('TrackView.created(): Vector layers filtered')

    var center = this.trackData.geometry.coordinates[0]

    var rasterBaseLayerList = this.createRasterLayers(this.area, this.trackViewBaseLayers)
    var rasterOverlayLayerList = this.createRasterLayers(this.area, this.defaultOverlayLayers)
    loggingMark('TrackView.created(): Raster layers created')

    var vectorLayers = [   
      protectedAreasLayer,
      routesVectorLayer,
      webCamsVectorLayer,
      accidentsVectorLayer,
      symbolsVectorLayer,
    ]

    this.createMap(center[0], center[1], 14, vectorLayers, rasterBaseLayerList, rasterOverlayLayerList, this.onLayerSwitcher)
    this.singleClick = this.registerSingleClickHandler(this.onClick)
    this.registerMouseOverHandler(this.onMouseOver)
    loggingMark('TrackView.created(): Map created')

    this.zoomTo(extent, 8, 16)
    loggingMark('TrackView.created(): Exit')
  },
  mounted() {
    loggingMark('TrackView.mounted(): Enter')
    this.overlaySelector = this.$store.state[this.area].overlays.selector || 1
    this.agreed = this.$refs.disclaimer.open(this.area, this.status, false, this.rejectDisclaimer, this.acceptDisclaimer)
    this.startRefresher()
    loggingStop('TrackView.mounted(): Exit')
  },
  unmounted() {
  }, 
  beforeRouteEnter(routeTo, routeFrom, next) {
    loggingStart('TrackView.beforeRouteEnter(): Enter')
    const area = routeTo.query.area ? routeTo.query.area : 'ch'

    // inline async function to wait for async dispatch calls
    const getDataAsync = async function (area) {
      await store.dispatch('region/fetchRegions')
      await store.dispatch(`${area}/fetchSymbols`)
      await store.dispatch(`${area}/fetchWebCams`)
      await store.dispatch(`${area}/fetchAccidents`)
      // await store.dispatch(`${area}/fetchRoutes`)
      await store.dispatch(`${area}/fetchBulletin`)

      await store.dispatch('news/fetchNews')
    }

    NProgress.start()
    getDataAsync(area).then(() => {
      NProgress.done()
      next()
      loggingMark('TrackView.beforeRouteEnter(): Progress done')
    })
    loggingMark('TrackView.beforeRouteEnter(): Exit')
  },
}
</script>

<style scoped>
.map {
  height: 100%;
}

.info {
  max-width: 500px;
}
</style>
