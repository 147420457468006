<template>
  <v-container v-if="routeInfo != null" fluid class="py-0">
    <div class="text-h6 ml-1 mr-1 mt-3 mb-2 justify-left">{{ this.$vuetify.lang.t(`$vuetify.route.title`) }}</div>
    <div v-if="routeInfo.ad >= 7" class="ml-1 mr-1 mb-2 textDense" :style="{ color: getWarningColor() }">
      {{ $vuetify.lang.t(`$vuetify.route.diffWarning`) }}
    </div>
    <v-simple-table dense>
      <colgroup>
        <col width="50%" />
        <col width="50%" />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.stop') }}</td>
          <td>{{ `${stop} (${routeInfo.stopEle}\u2006m)` }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.start') }}</td>
          <td>{{ `${start} (${routeInfo.startEle}\u2006m)` }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.elevationDifference') }}</td>
          <td>{{ `${routeInfo.ele}\u2006` + this.$vuetify.lang.t(`$vuetify.route.elevationGain`) }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.length') }}</td>
          <td>{{ `${routeInfo.len}\u2006m` }}</td>
        </tr>
        <tr @click="displayHelp('ascentTime')" class="fingerPointer">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.time') }}</td>
          <td>
            <a>{{ getTimeString(routeInfo.time) }}</a>
          </td>
        </tr>        
        <tr v-if="gpx != null">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.links.gps.title') }}</td>
          <td>
            <a :href="getGpxLink()" :download="getGpxFileName()">{{
              $vuetify.lang.t('$vuetify.route.links.gps.link')
            }}</a>
          </td>
        </tr>  
        <tr v-if="routeInfo.ad >= 1" @click="displayHelp('difficulty')" class="fingerPointer">
          <td class="text-left">{{ $vuetify.lang.t('$vuetify.route.difficulty') }}</td>
          <td>
            <span>
              <a>{{ getDifficultyText(true) }}</a>
            </span>
          </td>
        </tr>                
        <tr @click="displayHelp('riskIndicator')" class="fingerPointer">
          <td class="text-left font-weight-bold">{{ $vuetify.lang.t('$vuetify.route.riskIndicator') }}</td>
          <td>
            <v-chip class="px-1 fingerPointer font-weight-bold" small :color="getColor(routeInfo.risk)">{{
              routeInfo.risk.toFixed(2)
            }}</v-chip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>


  </v-container>
</template>

<script>
//import store from '@/store/store'
import { getRiskCssColor } from '../colors.js'
import { utilityMixin } from '../mixins/utilityMixin'
import { log } from '../utilities'
import DataService from '@/services/DataService'
import { transform } from 'ol/proj'

export default {
  name: 'rating-output',
  data: () => ({
    start: '',
    stop: '',
    startPoint: null,
    stopPoint: null
  }),
  mixins: [utilityMixin],
  watch: {
    userRoute: function () {
      this.userRouteChanged()
    },    
    routeInfo: function () {
      this.routeInfoChanged()
    }     
  },  
  props: {
    userRoute: {
      type: Object,
      default: null
    },    
    routeInfo: {
      type: Object,
      default: null
    },
    gpx: {
      type: String,
      default: null
    }       
  }, 
  methods: {
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('TrackTable: Display help')
      this.$emit('displayHelp', tag)
    },
    getColor(risk) {
      return getRiskCssColor(risk, 'EYES')
    },
    getTimeString(time) {
      const totalMinutes = 10 * Math.round((60 * time) / 10)
      var minutes = Math.floor(totalMinutes % 60)
      var hours = Math.floor(totalMinutes / 60)
      return hours + 'h\u2006' + (minutes == 0 ? '' : minutes + "'")
    },
    userRouteChanged() {
      if (this.userRoute == null) return
      var coordinates = this.userRoute.getCoordinates()
      let len = coordinates.length
      log('userRouteChanged(): Number of points = ' + len)      
      this.startPoint = coordinates[0]
      this.stopPoint = coordinates[len-1]
      if (this.routeInfo != null) {
        this.routeInfoChanged()
      }
    },
    routeInfoChanged() {
      if (this.routeInfo == null) return

      if (this.routeInfo.ad >= 9) {
        if (this.routeInfo.ad >= 12) {
          this.displayMessage(2, this.$vuetify.lang.t(`$vuetify.message.texts.10`))
        } else { 
          this.displayMessage(1, this.$vuetify.lang.t(`$vuetify.message.texts.9`))
        }
      } 
     

      this.start = this.$vuetify.lang.t(`$vuetify.rudr.output.name`)
      this.stop = this.$vuetify.lang.t(`$vuetify.rudr.output.name`)      
      if (this.startPoint == null || this.stopPoint == null) {
        return
      }

      log('routeInfoChanged()')
      var that = this
      var template

      var lowerPoint = this.startPoint
      var higherPoint = this.stopPoint
      if (this.routeInfo.stopEle < this.routeInfo.startEle) {
        lowerPoint = this.stopPoint
        higherPoint = this.startPoint
      }

      template=this.getTemplate('place', 'locality|isolated_dwelling|hamlet|village|town', 1000, lowerPoint)
      DataService.getOverpassApi(template).then((response) => {
        that.loadResponseTable(response.data)
      })   
      template=this.getTemplate('natural', 'peak|saddle|hill', 300, higherPoint)
      DataService.getOverpassApi(template).then((response) => {
        that.loadResponseTable(response.data)
      }) 
    },
    getTemplate(key, val, dist, point) {
      var transPoint = transform(point, 'EPSG:3857', 'EPSG:4326')
      var template=`data=[out:json];(node(around:${dist},${transPoint[1]},${transPoint[0]})[${key}~"^(${val})$"][name];>;);out;`
      return template
    },

    loadResponseTable(data) {
      if (data.elements.length == 0) return     
      if (typeof data.elements[0].tags == 'undefined' || data.elements[0].tags == null) return
      var tags = data.elements[0].tags
      log(`loadResponseTable(): Name = ${tags}`)

      if (tags.natural == 'peak' || tags.natural == 'hill' || tags.natural == 'saddle') {
        // Its a peak
        if (this.routeInfo.stopEle >= this.routeInfo.startEle) {
          this.stop = tags.name
        }
        else {
          this.start = tags.name
        }
      } else {
        // Its a location
        if (this.routeInfo.stopEle >= this.routeInfo.startEle) {
          this.start = tags.name
        }
        else {
          this.stop = tags.name       
        }
      }
      this.setHeaderTitle()
    },    
    setHeaderTitle() {
      var title = `${this.stop} (${this.routeInfo.stopEle} m)`
      if (this.routeInfo.stopEle < this.routeInfo.startEle) {
        title = `${this.start} (${this.routeInfo.startEle} m)`
      }    
      this.updateHeader(title, 1,this. routeInfo.risk)   
    },
    getGpxLink() {
      return DataService.getGpxServiceHost() + this.gpx
    },
    getGpxFileName() {
      var date = new Date();
      var year = this.pad(date.getFullYear(), 4)
      var month = this.pad(date.getMonth()+1, 2)
      var day = this.pad(date.getDate(), 2)      
      var hours = this.pad(date.getHours(), 2); 
      var minutes = this.pad(date.getMinutes(), 2);

      var start = this.replaceAll(this.start, ' ', '-')
      var stop = this.replaceAll(this.stop, ' ', '-')
      return `${start}-${stop}-${year}.${month}.${day}-${hours}.${minutes}h.gpx`
    },
    getDifficultyText(automatic) {
      var text = this.getDifficultyForArea(this.routeInfo.ad, 'ch') + ' '
      if (automatic) {
        text += this.$vuetify.lang.t('$vuetify.route.diff.automatic')
      } else {
        text += this.$vuetify.lang.t('$vuetify.route.diff.manual')
      }
      return text
    },
    displayMessage(type, message) {
      this.$emit('displayMessage', type, message)
    },  
  },
  mounted() {
    this.start = this.$vuetify.lang.t(`$vuetify.rudr.output.name`)
    this.stop = this.$vuetify.lang.t(`$vuetify.rudr.output.name`)       
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-chip {
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: unset;
}
.table {
  width: 100%;
}
.v-data-table >>> td {
  padding-left: 4px !important;
}
</style>
