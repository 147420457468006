<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{
          $vuetify.lang.t(`$vuetify.locationSearch.title`)
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="close-button" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-4 text font-weight-normal textDense">
        <v-text-field
          autofocus
          spellcheck="false"
          prepend-inner-icon="mdi-magnify"
          :value="keyword"
          @input="updateSearchKeyword"
          clear-icon="mdi-close-circle"
          clearable
          hide-details="true"
        >
        </v-text-field>
        <v-row class="center my-4">
          <!-- <v-divider></v-divider> -->
        </v-row>
        <v-row class="mx-0">
          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="features"
            hide-default-footer
            hide-default-header
            :items-per-page="itemsPerPage"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100, -1],
            }"
            dense
          >
            <template v-slot:[`item.properties.display_name`]="{ item }">
              <a style="display: block;" @click="gotoHit(item)">{{ item.properties.display_name }}</a>
            </template>

            <template v-slot:[`item.properties.type`]="{ item }">
              {{ $vuetify.lang.t(`$vuetify.locationSearch.types.${item.properties.type}`) }}
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import DataService from '@/services/DataService'
import _ from 'lodash'

export default {
  name: 'layer-switcher-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    keyword: '',
    headers: [
      {
        text: 'Treffer',
        align: 'start',
        sortable: false,
        value: 'properties.display_name',
      },
      {
        text: 'Typ',
        align: 'start',
        sortable: false,
        value: 'properties.type',
      },
    ],
    features: [],
    extent: null,
    itemsPerPage: -1,
    onLocationSearchHit: null,
    categoryPrios: Object.freeze([
      'NATURAL$PEAK',
      'NATURAL$HILL',
      'NATURAL$SADDLE',
      'TOURISM$ALPINE_HUT',
      'TOURISM$WILDERNESS_HUT',

      'BOUNDARY$ADMINISTRATIVE',

      'PLACE$MUNICIPALITY',
      'PLACE$CITY',
      'PLACE$TOWN',
      'PLACE$VILLAGE',
      'PLACE$LOCALITY',
      'PLACE$HAMLET',
      'PLACE$ISOLATED_DWELLING',

      'RAILWAY$STATION',
      'HIGHWAY$BUS_STOP',

      'NATURAL$VALLEY',
      'NATURAL$WATER',
      'NATURAL$RIDGE',
      'NATURAL$ARETE',
      'NATURAL$GLACIER',

      'WATERWAY$RIVER',
      'WATERWAY$STREAM',

      'PLACE$DISTRICT',
      'PLACE$PROVINCE',
      'PLACE$REGION',

      'AMENITY$SHELTER',
      'TOURISM$VIEWPOINT',
      'TOURISM$CAMP_SITE',
    ]),
  }),
  methods: {
    // eslint-disable-next-line no-unused-vars
    open(extent, onLocationSearchHit) {
      this.onLocationSearchHit = onLocationSearchHit
      this.extent = extent
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    updateSearchKeyword(keyword) {
      this.keyword = keyword
      this.debouncedLocationSearch()
    },
    loadResponseTable(data) {
      var featureCount = data == null ? 0 : data.features.length

      // eslint-disable-next-line no-console
      console.log('loadResponseTable: Nr of features found=' + featureCount)

      if (data == null) {
        this.features = []
        return
      }
      this.features = this.sortFeatures(data.features)
    },
    sortFeatures(features) {
      var filteredFeatures = []
      for (let i = 0; i < features.length; i++) {
        var tag = `${features[i].properties.category}$${features[i].properties.type}`
        tag = tag.toUpperCase()
        var prio = this.categoryPrios.indexOf(tag)
        if (prio >= 0) {
          features[i].properties.prio = prio
          filteredFeatures.push(features[i])
        }
      }

      var sortedFeatures = filteredFeatures.sort((f1, f2) => {
        return f1.properties.prio - f2.properties.prio
      })
      return sortedFeatures
    },
    locationSearch() {
      // eslint-disable-next-line no-console
      console.log('LocationSearchDialog: Keyword=' + this.keyword)
      if (this.keyword == null) {
        this.keyword = ''
      }

      if (this.keyword == '') {
        this.loadResponseTable(null)
        return
      }

      var that = this
      const limit = this.itemsPerPage == -1 ? 100 : this.itemsPerPage
      DataService.getNominatim(this.keyword, this.extent, this.$vuetify.lang.current, limit).then((response) => {
        that.loadResponseTable(response.data)
      })
    },
    gotoHit(item) {
      this.dialog = false
      this.onLocationSearchHit(item.geometry.coordinates)
    },
  },
  created: function () {
    this.debouncedLocationSearch = _.debounce(this.locationSearch, 500)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.close-button {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-right: -16px !important;
}

.v-data-table >>> td,
.v-data-table >>> th {
  padding: 6px 8px !important;
}
</style>
