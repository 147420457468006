import { render, staticRenderFns } from "./DangerAndCoreZone.vue?vue&type=template&id=23d67936&scoped=true&"
import script from "./DangerAndCoreZone.vue?vue&type=script&lang=js&"
export * from "./DangerAndCoreZone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d67936",
  null
  
)

export default component.exports