<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 43" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.route.riskIndicator`) }}</title>
    <rect width="100%" height="100%" :fill="backgroundColor" />
    <path
      :fill="foregroundColor"
      d="M22.45,39.75V38.09h-.61a4.32,4.32,0,0,1-3.25-1.32,3.74,3.74,0,0,1-1.16-2.54c-.05-1.21,0-2.42,0-3.64a.37.37,0,0,0-.17-.33,9.05,9.05,0,0,1-2.58-3c-.22-.41-.4-.83-.61-1.28h3.34c0-.07,0-.12,0-.17V24.43a.28.28,0,0,0-.12-.25A9.34,9.34,0,0,1,15,21.74,8,8,0,0,1,14.12,20a.47.47,0,0,1,0-.14h3.3c0-.05,0-.09,0-.14V18.29a.3.3,0,0,0-.1-.19A9.2,9.2,0,0,1,14.16,14l-.06-.17v-.06h3.29v-.2a15.18,15.18,0,0,1,.07-1.65,4,4,0,0,1,2.08-3,4.41,4.41,0,0,1,2.32-.59c1.31,0,2.61,0,3.91,0a4.41,4.41,0,0,1,2.78.88,4,4,0,0,1,1.63,2.68,14.71,14.71,0,0,1,.07,1.63v.2h3.33c-.11.26-.19.49-.3.72a9.22,9.22,0,0,1-2.48,3.26c-.15.13-.31.24-.47.37a.17.17,0,0,0-.07.14v1.6h3.33c-.14.32-.25.63-.39.92a9.23,9.23,0,0,1-2.81,3.39.32.32,0,0,0-.14.27c0,.45,0,.91,0,1.37V26h3.34c-.14.32-.25.62-.39.91a8.94,8.94,0,0,1-2.59,3.2.83.83,0,0,0-.36.74c0,1,0,1.94,0,2.91a4.13,4.13,0,0,1-.94,2.74A4.21,4.21,0,0,1,26.61,38c-.46.07-.92.07-1.41.1v1.63Zm4.56-8a3.2,3.2,0,1,0-3.31,3.07h.05a3.15,3.15,0,0,0,3.27-3v-.08Zm-3.19-5.45a3.09,3.09,0,1,0-3.2-3.09,3.15,3.15,0,0,0,3.2,3.09ZM27,14.72a3.19,3.19,0,1,0-3.31,3.07h0A3.11,3.11,0,0,0,27,14.85a.57.57,0,0,0,0-.13Z"
    />
    <path
      :fill="foregroundColor"
      d="M22.27,38.09h-.43a4.32,4.32,0,0,1-3.25-1.32,3.74,3.74,0,0,1-1.16-2.54c-.05-1.21,0-2.42,0-3.64a.37.37,0,0,0-.17-.33,9.05,9.05,0,0,1-2.58-3c-.22-.41-.4-.83-.61-1.28h3.34c0-.07,0-.12,0-.17V24.43a.28.28,0,0,0-.12-.25A9.34,9.34,0,0,1,15,21.74,8,8,0,0,1,14.12,20a.47.47,0,0,1,0-.14h3.3c0-.05,0-.09,0-.14V18.29a.3.3,0,0,0-.1-.19A9.2,9.2,0,0,1,14.16,14l-.06-.17v-.06h3.29v-.2a15.18,15.18,0,0,1,.07-1.65,4,4,0,0,1,2.08-3,4.41,4.41,0,0,1,2.32-.59c1.31,0,2.61,0,3.91,0a4.41,4.41,0,0,1,2.78.88,4,4,0,0,1,1.63,2.68,14.71,14.71,0,0,1,.07,1.63v.2h3.33c-.11.26-.19.49-.3.72a9.22,9.22,0,0,1-2.48,3.26c-.15.13-.31.24-.47.37a.17.17,0,0,0-.07.14v1.6h3.33c-.14.32-.25.63-.39.92a9.23,9.23,0,0,1-2.81,3.39.32.32,0,0,0-.14.27c0,.45,0,.91,0,1.37V26h3.34c-.14.32-.25.62-.39.91a8.94,8.94,0,0,1-2.59,3.2.83.83,0,0,0-.36.74c0,1,0,1.94,0,2.91a4.13,4.13,0,0,1-.94,2.74A4.21,4.21,0,0,1,26.61,38c-.46.07-.86.07-1.34.1ZM27,31.77a3.2,3.2,0,1,0-3.31,3.07h.05a3.15,3.15,0,0,0,3.27-3v-.08Zm-3.19-5.45a3.09,3.09,0,1,0-3.2-3.09,3.15,3.15,0,0,0,3.2,3.09ZM27,14.72a3.19,3.19,0,1,0-3.31,3.07h0A3.11,3.11,0,0,0,27,14.85a.57.57,0,0,0,0-.13Z"
    />
    <rect :fill="foregroundColor" x="21.27" y="37.19" width="5" height="2" />
  </svg>
</template>

<script>
import { getNavigationCssColor } from '../colors'

export default {
  name: 'IconTrafficLight',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 43,
    },
    foregroundColor: {
      type: String,
      default: getNavigationCssColor(),
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
