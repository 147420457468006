<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.68 279.1" :width="width" :height="height">
    <title>{{ $vuetify.lang.t(`$vuetify.news`) }}</title>
    <rect :fill="backgroundColor" height="100%" width="100%" />
    <path
      :fill="foregroundColor"
      d="M160.81,20.77h-34A103.71,103.71,0,0,0,62.15,205.62v52.84h0a3.3,3.3,0,0,0,.08.77l.1.36.12.37a3.44,3.44,0,0,0,.37.68h0l0,.07.43.51.29.25.3.23.34.19.33.17.37.11.35.11.65.06.11,0h0a3.92,3.92,0,0,0,.76-.08l.46-.14.27-.08a4.19,4.19,0,0,0,.69-.38l49.54-33.53c3,.24,6,.39,9.06.38h34a103.88,103.88,0,0,0,0-207.76Z"
    />
    <path :fill="digitBackgroundColor" d="M306.5,103.52a77.17,77.17,0,1,0-77.15,77.2,77.17,77.17,0,0,0,77.15-77.2" />
    <text
      transform="translate(194.31 148.58) rotate(-0.02)"
      style="
        opacity: 0.87;
        isolation: isolate;
        font-size: 123.27px;
        fill: #000000;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
      "
    >
      {{ nrNews.toString() }}
    </text>
  </svg>
</template>

<script>
import { getRiskCssColor } from '../colors'

export default {
  name: 'IconNews',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    foregroundColor: {
      type: String,
      default: 'rgba(77, 77, 77, 1)',
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
    nrNews: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    digitBackgroundColor: function () {
      // The RiskColor for 2.5 will return the correct 'red' color
      return this.nrNews == 0 ? this.foregroundColor : getRiskCssColor(2.5, 'ICONS')
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Unsets the default width of 24px from v-icon */
svg {
  width: unset;
}
</style>
